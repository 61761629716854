import { Route, Routes } from "react-router-dom";
import { PROTECTED_ROUTES } from "../../../appConfig/routes/RouteConstants";
import ProtectedRoute from "../../../appConfig/routes/ProtectedRoute";

const Content = () => {
  return (
    <div>
      <ProtectedRoute>
        <Routes>
          {PROTECTED_ROUTES.map((route) => (
            <Route
              key={route?.key}
              exact={route?.exact}
              path={route?.path}
              element={<route.component />}
            />
          ))}
        </Routes>
      </ProtectedRoute>
    </div>
  );
};
export default Content;
