import { Button, Row } from "antd";
import "./styles.scss";
import { IconList } from "../../../constants/imageList";
const Heading = () => {
  return (
    <Row justify={"space-between"} className="request-heading" align={"middle"}>
      <h3>All Users</h3>
    </Row>
  );
};
export default Heading;
