import { createSlice } from "@reduxjs/toolkit";
import {
  postConfirmOtp,
  postForgotPassword1,
  postForgotPassword2,
  postLogin,
  postSignup,
} from "../reducer/auth.reducer";
import { toaster } from "../../../utils/toast";

const initialState = {
  isLoading: false,
  isError: false,
  userData: {},
  signUpData: {},
  loginData: {},
  forgotPasswordData: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserData: (state, { payload }) => {
      state.userData = payload;
    },
    logout: (state) => {
      state = {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    //-------------------SIGNUP-----------------
    builder.addCase(postSignup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postSignup.rejected, (state, action) => {
      state.isError = true;

      state.isLoading = false;
    });
    builder.addCase(postSignup.fulfilled, (state, { payload }) => {
      state.isLoading = false;

      if (payload.status === false) {
        toaster("error", payload.message);
      } else {
        const { data } = payload;

        toaster("success", data.message);
        state.signUpData = data;
      }
    });
    //-------------------CONFIRM OTP-----------------

    builder.addCase(postConfirmOtp.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postConfirmOtp.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(postConfirmOtp.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload.status === false) {
        toaster("error", payload.message);
      } else {
        toaster("success", "Regitration complete. Wait for admin to approve");
      }
    });
    //-------------------LOGIN-----------------
    builder.addCase(postLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postLogin.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(postLogin.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload.status === false) {
        toaster("error", payload.message);
      } else {
        const { data } = payload;

        toaster("success", data.message);
        state.loginData = data;
        const userData = {
          userType: data?.result?.userType,
          token: data?.result?.token,
        };
        state.userData = userData;
        localStorage.setItem("userData", JSON.stringify(userData));
      }
    });
    //-------------------FORGOT PASSWORD 1-----------------
    builder.addCase(postForgotPassword1.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postForgotPassword1.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(postForgotPassword1.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload.status === false) {
        toaster("error", payload.message);
      } else {
        const { data } = payload;
        state.forgotPasswordData = data;

        toaster("success", data.message);
      }
    });
    //-------------------FORGOT PASSWORD 2-----------------
    builder.addCase(postForgotPassword2.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postForgotPassword2.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(postForgotPassword2.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload.status === false) {
        toaster("error", payload.message);
      } else {
        const { data } = payload;

        toaster("success", data.message);
      }
    });
  },
});
export const authActions = authSlice.actions;
export default authSlice.reducer;
