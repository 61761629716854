import { Form } from "antd";
import "./styles.scss";
import React from "react";

import Heading from "./Heading";
import SelectedStep from "./SelectedStep";
import Page1 from "./Page1/index";
import { NEW_PROJECT_STEPS } from "../../constants/enum";
import Page2 from "./Page2";
import Page3 from "./Page3";
import { useSelector } from "react-redux";

const EditProject = () => {
  const [form] = Form.useForm();
  const step = useSelector((state) => state.project.step);
  return (
    <div className="new-project-container">
      <Heading form={form} />
      <SelectedStep />
      {step == NEW_PROJECT_STEPS.step1 ? (
        <Page1 form={form} />
      ) : step === NEW_PROJECT_STEPS.step2 ? (
        <Page2 form={form} />
      ) : (
        <Page3 form={form} />
      )}
    </div>
  );
};

export default EditProject;
