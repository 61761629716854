import { DeleteOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Row, Switch, Upload } from "antd";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getMyCards,
  updateCard,
} from "../../../appConfig/redux/reducer/card.reducer";
import { toaster } from "../../../utils/toast";
const EditCard = ({ isEdit, setIsEdit }) => {
  const { cardDetail } = useSelector((state) => state.card);
  const [cardQues, setCardQues] = useState(cardDetail?.cardTitle);
  const [cardDesc, setCardDesc] = useState(cardDetail?.cardDescription);
  const [cardIcon, setCardIcon] = useState(
    cardDetail?.cardIcon?.includes("undefined") ? "" : cardDetail?.cardIcon
  );
  const [isRequired, setIsRequired] = useState(cardDetail?.isRequired);

  const dispatch = useDispatch();
  useEffect(() => {
    setCardQues(cardDetail?.cardTitle);
    setCardDesc(cardDetail?.cardDescription);
    setCardIcon(
      cardDetail?.cardIcon?.includes("undefined") ? "" : cardDetail?.cardIcon
    );
    setIsRequired(cardDetail?.isRequired);
  }, [cardDetail, isEdit]);
  const onUpdate = () => {
    dispatch(
      updateCard({
        values: {
          cardId: cardDetail?.id,
          cardTitle: cardQues,
          cardDescription: cardDesc,
          cardIcon,
          isRequired,
        },
        callback: () => {
          setIsEdit(false);
          toaster("success", "Card Updated Successfully");
          dispatch(getMyCards({ params: {} }));
        },
      })
    );
  };
  return (
    <Modal
      open={isEdit}
      onCancel={() => setIsEdit(false)}
      title="Edit card"
      className="edit-card-container"
      footer={[
        <Button onClick={() => setIsEdit(false)}>Cancel</Button>,
        <Button type="primary" className="primary" onClick={onUpdate}>
          Update
        </Button>,
      ]}
    >
      <h4>Question</h4>
      <Input
        placeholder="Enter question here"
        value={cardQues}
        onChange={(e) => setCardQues(e.target.value)}
      />
      <h4>Description</h4>
      <Input
        placeholder="Enter question here"
        value={cardDesc}
        onChange={(e) => setCardDesc(e.target.value)}
      />
      <h4>Icon</h4>
      {cardIcon === "" ? (
        <Upload
          accept=".jpg,.jpeg,.png,.svg,.mp4,.avi"
          action={(value) => {
            if (value.size < 5000000) {
              let reader = new FileReader();
              reader.readAsDataURL(value);
              reader.onload = () => {
                setCardIcon(reader.result);
              };
            } else {
              toaster("error", "File too large");
            }
          }}
          showUploadList={false}
        >
          <div className="upload">Upload Icon</div>
        </Upload>
      ) : (
        <div className="icon-container">
          <img src={cardIcon} className="icon" />
          <DeleteOutlined
            className="delete"
            style={{ color: "white" }}
            onClick={() => {
              setCardIcon("");
            }}
          />
        </div>
      )}
      <h4 />
      <h4 />
      <Row align={"start"} justify={"start"}>
        Required
        <Switch
          className="switch"
          checked={isRequired}
          onChange={(checked) => setIsRequired(checked)}
        />
      </Row>
    </Modal>
  );
};
export default EditCard;
