import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#662d91",
    padding: 15,
    justifyContent: "space-between",
  },
  headerImage: { height: 40 },
  headerHeading: { margin: 0, color: "white" },
  heading: {
    marginTop: 15,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  h1Heading: {
    fontSize: 28,
    textAlign: "right",
    marginRight: 25,
    width: 300,
    color: "#662d91",
  },
  headingImage: { height: 100, width: 100, marginRight: 30 },
  projectInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    textAlign: "center",
  },
  projectItem: { color: "#662d91" },
  projectHeading: { margin: 0 },
  projectAnswer: { color: "#333333", margin: 0 },
  projectPara: { color: "#333333", margin: 0 },
  neighborsAgree: {
    display: "flex",
    marginHorizontal: 22,
    marginVertical: 10,
    flexDirection: "row",
    marginTop: 10,
    height: 70,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#eeeeef",
    borderRadius: 8,
  },
  neighborsPercent: {
    color: "#00a79d",
    fontSize: 30,
    margin: 0,
  },
  neighborsText: { fontSize: 30, color: "#555555", margin: 0 },
  yimbyType: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginHorizontal: 20,
    marginVertical: 5,
  },
  map: { width: 360, backgroundColor: "#eeeeef", borderRadius: 8, height: 375 },
  type: {
    backgroundColor: "#eeeeef",
    marginLeft: 10,
    borderRadius: 8,
    padding: 10,
    display: "flex",
    flexDirection: "row",
    marginBottom: 6,
    alignItems: "center",
    justifyContent: "center",
  },
  type2: {
    backgroundColor: "#eeeeef",
    marginLeft: 10,
    textAlign: "center",
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    padding: 10,
    display: "flex",
    flexDirection: "row",

    marginBottom: 4,
    justifyContent: "center",
  },
  type3: {
    backgroundColor: "#eeeeef",
    marginLeft: 10,
    textAlign: "center",
    padding: 5,
    display: "flex",
    flexDirection: "row",

    marginBottom: 4,
    justifyContent: "center",
  },
  type4: {
    backgroundColor: "#eeeeef",
    marginLeft: 10,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    textAlign: "center",
    padding: 7,
    display: "flex",
    flexDirection: "row",

    marginBottom: 10,
    justifyContent: "center",
  },
  percent: {
    color: "#1aa79c",
    textAlign: "center",
    margin: 0,
    fontSize: 20,
    fontWeight: "700",
  },
  h1: { margin: 0 },
  typeImage: { height: 40, width: 40, marginRight: 10 },
  yimbyNumber: {
    color: "#00a79d",
    marginRight: 10,
    fontSize: 30,
    fontWeight: "bold",
  },
  yimby: { color: "#00a79d", fontWeight: "700" },
  mimbyNumber: {
    color: "#65b4be",
    marginRight: 10,
    fontSize: 30,
    fontWeight: "bold",
  },
  mimby: { color: "#65b4be", fontWeight: "700" },
  nimbyNumber: {
    color: "#0a6c64",
    marginRight: 10,
    fontSize: 30,
    fontWeight: "bold",
  },
  nimby: { color: "#0a6c64", fontWeight: "700" },
  page: { height: 100 },
  table: {
    marginTop: 20,
    borderCollapse: "collapse",
    width: 100,
  },
  th: {
    padding: 0,
    textAlign: "center",
  },
  td: {
    padding: 0,
    fontSize: 10,
    textAlign: "center",
    /*width: 25%,*/ borderWidth: 1,
  },
  cardDetail: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    flexDirection: "row",
  },
  card: {
    borderWidth: 1,
    margin: 10,
    width: 140,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 20,
    paddingHorizontal: 10,
    flexDirection: "column",
  },
  cardQuestion: {
    marginTop: 0,
    fontWeight: 600,
    textTransform: "capitalize",
    fontSize: 10,
  },
  cardAgree: { color: "#1aa79c", fontSize: 10 },
  cardDisagree: { color: "#0a6c64", fontSize: 10 },
  cardNoAnswer: { color: "#65b4be", fontSize: 10 },
  cardImg: { height: 50, width: 50, marginBottom: 10 },
  commentDetail: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  comment: {
    margin: 10,
    position: "relative",
    backgroundColor: "#1AA79C",
    borderRadius: "100%",
    height: 120,
    width: 120,
    paddingTop: 20,
    textAlign: "center",
  },
  commentName: {
    color: "#ddd",
    fontSize: 12,
    margin: 0,
    fontWeight: "bold",
    marginBottom: 5,
    marginTop: 5,
  },
  commentComment: {
    color: "white",
    paddingHorizontal: 10,
    fontSize: 12,
    paddingHorizontal: 15,
  },
});
