import { Button, Col, Empty, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getCardWithComments,
  getProjectWithComments,
  replyBeHeardComments,
  showBeHeardComments,
} from "../../appConfig/redux/reducer/notification.reducer";
import moment from "moment";
const Message = () => {
  const dispatch = useDispatch();
  const [isReplying, setIsReplying] = useState();
  const [index, setIndex] = useState();
  const [comment, setComment] = useState();
  const [projectId, setProjectId] = useState();
  const [cardId, setCardId] = useState();
  const [isDefault, setIsDefault] = useState();
  const { projectWithComments, cardWithComments, comments, replies } =
    useSelector((state) => state.notification);
  useEffect(() => {
    dispatch(getProjectWithComments());
  }, []);
  return (
    <Row className="message-container">
      {projectWithComments?.length > 0 ? (
        <Col md={6} className="left-side">
          <div className="heading">
            <h3>Projects</h3>
          </div>

          <div style={{ marginTop: 10, paddingTop: 35 }}>
            {projectWithComments?.map((item, key) => (
              <div key={key}>
                <div
                  className={
                    projectId === item?.projectId
                      ? "project selected"
                      : "project"
                  }
                  onClick={() => {
                    setProjectId(item?.projectId);
                    dispatch(
                      getCardWithComments({
                        params: {
                          projectId: item?.projectId,
                        },
                      })
                    );
                  }}
                >
                  <p>{item?.projectName}</p>
                  <p className="date">
                    {moment(item?.createdAt).format("DD MMM'YY hh:mm a")}
                  </p>
                </div>
                <hr />
              </div>
            ))}
          </div>
        </Col>
      ) : (
        <Col md={24}>
          <Empty
            description={<span>No Messages</span>}
            style={{ marginTop: 20 }}
          />
        </Col>
      )}
      {cardWithComments?.length > 0 && (
        <Col md={6} className="left-side">
          <div className="heading">
            <h3>Cards</h3>
          </div>

          <div style={{ marginTop: 10, paddingTop: 35 }}>
            {cardWithComments?.map((item, key) => (
              <div key={key}>
                <div
                  className={
                    cardId === item?.id || cardId === item?.staticCardId
                      ? "project selected"
                      : "project"
                  }
                  onClick={() => {
                    setCardId(item?.id || item?.staticCardId);
                    setIsDefault(item?.id ? false : true);
                    dispatch(
                      showBeHeardComments({
                        value: {
                          projectId,
                          Id: item?.id || item?.staticCardId,
                          isDefault: item?.id ? false : true,
                        },
                      })
                    );
                  }}
                >
                  <p>{item?.cardTitle}</p>
                  <p className="date">
                    {moment(new Date(item?.createdOn).toLocaleString()).format(
                      "DD MMM'YY hh:mm a"
                    )}
                  </p>
                </div>
                <hr />
              </div>
            ))}
          </div>
        </Col>
      )}
      {comments?.length > 0 && (
        <Col md={12} className="comment-container">
          <div className="heading">
            <h3>Comments</h3>
          </div>

          <div style={{ marginTop: 10, paddingTop: 35 }}>
            {comments?.map((item, key) => (
              <Row key={key} align={"middle"} justify={"space-between"}>
                <div className="comments">
                  <div className="arrow" />

                  <p className="date">
                    {item?.firstName} {item?.surName}
                  </p>

                  <p>{item?.comment}</p>
                  <p className="date">
                    {moment(new Date(item?.createdOn).toLocaleString()).format(
                      "DD MMM'YY hh:mm a"
                    )}
                  </p>
                </div>

                {replies.filter(
                  (reply) => reply.beHeardCommentId === item.commentId
                ).length > 0 ? (
                  <>
                    <div key={key} className="reply">
                      <div className="arrow" />
                      <p className="date">You</p>
                      <p>
                        {
                          replies.filter(
                            (reply) => reply.beHeardCommentId === item.commentId
                          )[0]?.comment
                        }
                      </p>
                      <p className="date">
                        {moment(
                          new Date(
                            replies.filter(
                              (reply) =>
                                reply.beHeardCommentId === item.commentId
                            )[0]?.createdOn
                          ).toLocaleString()
                        ).format("DD MMM'YY hh:mm a")}
                      </p>
                    </div>
                    {}
                  </>
                ) : (
                  <>
                    {isReplying && index === key ? (
                      <>
                        <div
                          key={key}
                          className="reply"
                          onClick={() => {
                            console.log("sdds");
                          }}
                        >
                          <div className="arrow" />
                          <Input
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                          />
                          <Button
                            onClick={() => {
                              dispatch(
                                replyBeHeardComments({
                                  value: {
                                    projectId,
                                    cardId,
                                    isDefault,
                                    beHeardCommentId: item.commentId,
                                    neighbourId: item.userId,
                                    comment,
                                  },
                                  callback: () => {
                                    setIsReplying(false);
                                    setComment("");
                                    dispatch(
                                      showBeHeardComments({
                                        value: {
                                          projectId,
                                          Id: cardId,
                                          isDefault,
                                        },
                                      })
                                    );
                                  },
                                })
                              );
                            }}
                          >
                            Send
                          </Button>
                        </div>
                      </>
                    ) : (
                      <Button
                        onClick={() => {
                          setIsReplying(true);
                          setIndex(key);
                        }}
                        style={{ marginRight: 10 }}
                      >
                        Reply
                      </Button>
                    )}
                  </>
                )}
              </Row>
            ))}
          </div>
        </Col>
      )}
    </Row>
  );
};
export default Message;
