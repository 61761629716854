import React, { useEffect, useState } from "react";
import "./styles.scss";
import img from "../../../assets/icons/app_icon.png";
import { Button, Row } from "antd";
import card from "../../../assets/icons/card.svg";
import dashboard from "../../../assets/icons/dashboard.svg";
import message from "../../../assets/icons/message.svg";
import project from "../../../assets/icons/project.svg";
import report from "../../../assets/icons/report.svg";
import setting from "../../../assets/icons/setting.svg";
import cardSelected from "../../../assets/icons/cardSelected.svg";
import dashboardSelected from "../../../assets/icons/dashboardSelected.svg";
import messageSelected from "../../../assets/icons/messageSelected.svg";
import projectSelected from "../../../assets/icons/projectSelected.svg";
import reportSelected from "../../../assets/icons/reportSelected.svg";
import settingSelected from "../../../assets/icons/settingSelected.svg";
import { SCREEN_PATHS } from "../../../constants";
import { useNavigate } from "react-router-dom";
import { IconList, ImageList } from "../../../constants/imageList";
import { LogoutOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import store from "../../../appConfig/redux/store";
import { dashboardActions } from "../../../appConfig/redux/slice/dashboard.slice";

const SideNav = () => {
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const developerOptions = [
    {
      name: "Dashboard",
      img: dashboard,
      selectedImg: dashboardSelected,
      path: SCREEN_PATHS.dashboard,
    },
    {
      name: "Projects",
      img: project,
      selectedImg: projectSelected,
      path: SCREEN_PATHS.project,
    },
    {
      name: "Cards",
      img: card,
      selectedImg: cardSelected,
      path: SCREEN_PATHS.card,
    },
    {
      name: "Messages",
      img: message,
      selectedImg: messageSelected,
      path: SCREEN_PATHS.message,
    },
    {
      name: "Settings",
      img: setting,
      selectedImg: settingSelected,
      path: SCREEN_PATHS.setting,
    },
    {
      name: "Reports",
      img: report,
      selectedImg: reportSelected,
      path: SCREEN_PATHS.report,
    },
  ];
  const adminOptions = [
    {
      name: "Dashboard",
      img: dashboard,
      selectedImg: dashboardSelected,
      path: SCREEN_PATHS.dashboard,
    },
    {
      name: "Projects",
      img: project,
      selectedImg: projectSelected,
      path: SCREEN_PATHS.project,
    },
    {
      name: "Cards",
      img: card,
      selectedImg: cardSelected,
      path: SCREEN_PATHS.card,
    },
    {
      name: "Settings",
      img: setting,
      selectedImg: settingSelected,
      path: SCREEN_PATHS.setting,
    },
    {
      name: "Reports",
      img: report,
      selectedImg: reportSelected,
      path: SCREEN_PATHS.report,
    },
    {
      name: "Requests",
      img: IconList.request,
      selectedImg: IconList.requestSelected,
      path: SCREEN_PATHS.requestList,
    },
    {
      name: "Users",
      img: IconList.users,
      selectedImg: IconList.usersSelected,
      path: SCREEN_PATHS.userManagement,
      children: [
        { name: "Neighbours", path: SCREEN_PATHS.neighbours },
        { name: "Developers", path: SCREEN_PATHS.developers },
      ],
    },
  ];

  useEffect(() => {
    const { userType } = JSON.parse(localStorage.getItem("userData") ?? "{}");
    if (userType === "re_developer") {
      setOptions(developerOptions);
    } else {
      setOptions(adminOptions);
    }
  }, []);
  return (
    <div className="side-nav-container header-sticky">
      <Row align={"middle"} className="heading">
        <img src={img} />
        <h1>Yimby</h1>
      </Row>
      <h4 />
      {options.map((option, index) => (
        <div key={index}>
          <Row
            key={index}
            className={`option-container ${
              window.location.pathname.match(option.path.replace("/", ""))
                ? "selected"
                : ""
            }`}
            justify={"space-between"}
            onClick={() => {
              navigate(option.path);
            }}
          >
            <Row>
              <img
                src={
                  window.location.pathname.match(option.path.replace("/", ""))
                    ? option.selectedImg
                    : option.img
                }
              />
              <p>{option.name}</p>
            </Row>
          </Row>
          {window.location.pathname.match(option.path.replace("/", "")) &&
            option?.children && (
              <div onClick={() => {}}>
                {option?.children?.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => navigate(item.path)}
                    className={
                      window.location.pathname.match(item.path.replace("/", ""))
                        ? "selected-child cursor"
                        : "cursor"
                    }
                  >
                    <p>{item.name}</p>
                  </div>
                ))}
              </div>
            )}
        </div>
      ))}
      <Row
        className="option-container"
        onClick={() => {
          localStorage.clear();
          dispatch(dashboardActions.logout());
          navigate(SCREEN_PATHS.welcome);
        }}
      >
        <LogoutOutlined style={{ fontSize: 20, marginTop: -8 }} />
        <p>Logout</p>
      </Row>
    </div>
  );
};
export default SideNav;
