import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_END_POINT, privateApi } from "../../../api/apiConfig";
import { toaster } from "../../../utils/toast";
export const getUserList = createAsyncThunk(
  "get-user-list",
  async ({ params }) => {
    try {
      const response = await privateApi.get(API_END_POINT.GET_USERS, {
        params,
      });
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getUserDetail = createAsyncThunk(
  "get-user-detail",
  async ({ params }) => {
    try {
      const response = await privateApi.get(API_END_POINT.GET_USER_DETAIL, {
        params,
      });
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const suspendUser = createAsyncThunk(
  "suspend-user",
  async ({ value, callback }) => {
    try {
      const response = await privateApi.post(API_END_POINT.SUSPEND_USER, value);
      callback();
      toaster("success", "User suspended successfully");
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const unSuspendUser = createAsyncThunk(
  "unsuspend-user",
  async ({ value, callback }) => {
    try {
      const response = await privateApi.post(
        API_END_POINT.UNSUSPEND_USER,
        value
      );
      callback();
      if (response?.data?.status) {
        toaster("success", "User unsuspended successfully");
      }
      return response;
    } catch (err) {
      return err;
    }
  }
);
