import { Button, Col, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import { IconList, ImageList, PDFList } from "../../constants/imageList";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { getProjectCards } from "../../appConfig/redux/reducer/card.reducer";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
const libraries = ["places"];
const ProjectDetail = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_LOCATION_SERVICE,
    libraries,
  });
  const projectDetail = useSelector((state) => state.project.projectDetail);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { projectCards } = useSelector((state) => state.card);
  useEffect(() => {
    dispatch(getProjectCards(projectDetail?.projectId));
  }, []);
  const [currentIndex, setCurrentIndex] = useState(0);
  return (
    <div className="project-detail-container">
      <Heading />
      {projectDetail?.images?.length > 0 && (
        <Row md>
          <Col md={16}>
            <img src={projectDetail?.images[0]} className="main-image" />
          </Col>
          <Col md={7} className="secondary-images">
            {projectDetail?.images?.length > 1 && (
              <img src={projectDetail?.images[1]} className="other-image" />
            )}
            {projectDetail?.images?.length - 2 > 0 && (
              <div className="extra-image" onClick={() => setIsOpen(true)}>
                <img src={projectDetail?.images[2]} className="other-image-2" />
                <p>
                  +
                  {projectDetail?.images?.length - 2 <= 0
                    ? 0
                    : projectDetail?.images?.length - 2}
                </p>
              </div>
            )}
          </Col>
        </Row>
      )}
      <div className="detail">
        <h3>{projectDetail?.projectName}</h3>
        <p>
          {projectDetail?.projectAddress} - {projectDetail?.zipCode}
        </p>
        <Row>
          <span>
            <img src={IconList?.projectDetailType} />
            <p>{projectDetail?.projectType}</p>
          </span>
          <span>
            <img src={IconList?.projectDetailArea} />
            <p>{projectDetail?.propertySize}sqf</p>
          </span>
          {/* <span>
            <img src={IconList?.projectDetailParking} />
            <p>Parking Area</p>
          </span> */}
          <span>
            <img src={IconList?.projectDetailPopulation} />
            <p>{projectDetail?.maximumCapacity}</p>
          </span>
        </Row>
        <p>{projectDetail?.projectDescription}</p>
        <h3>Location</h3>
        <Row>
          <div className="location">
            Longitude: {Number(projectDetail?.longitude)}
          </div>
          <div className="location">
            Latitude: {Number(projectDetail?.latitude)}
          </div>
        </Row>

        <div className="map">
          {isLoaded && (
            <GoogleMap
              center={{
                lat: Number(projectDetail?.latitude),
                lng: Number(projectDetail?.longitude),
              }}
              zoom={15}
              mapContainerStyle={{ width: "100%", height: "100%" }}
            >
              {isLoaded &&
                Number(projectDetail.latitude) &&
                Number(projectDetail.longitude) && (
                  <Marker
                    position={{
                      lat: Number(projectDetail.latitude),
                      lng: Number(projectDetail.longitude),
                    }}
                    icon={PDFList.PDFLogoSvg}
                  />
                )}
            </GoogleMap>
          )}
        </div>

        <h3>Cards</h3>
        <Row wrap="wrap" className="card-container">
          {(projectDetail?.cardDetails || projectDetail?.defaultCardDetails) &&
          (projectDetail?.cardDetails?.length > 0 ||
            projectDetail?.defaultCardDetails?.length > 0) ? (
            <>
              {projectDetail?.cardDetails?.map((card, index) => (
                <Col md={6} key={index}>
                  <div className="card">
                    <p>{card.createdOn}</p>
                    <h3>{card.cardTitle}</h3>
                  </div>
                </Col>
              ))}
              {projectDetail?.defaultCardDetails?.map((card, index) => (
                <Col md={6} key={index}>
                  <div className="card">
                    <p>{card.createdOn}</p>
                    <h3>{card.cardTitle}</h3>
                  </div>
                </Col>
              ))}
            </>
          ) : (
            <>
              {projectCards?.map((card, index) => (
                <Col md={6} key={index}>
                  <div className="card">
                    <p>{card.createdOn}</p>
                    <h3>{card.cardTitle}</h3>
                  </div>
                </Col>
              ))}
            </>
          )}
        </Row>
      </div>

      <Modal open={isOpen} footer={[]} onCancel={() => setIsOpen(false)}>
        <Row justify={"center"} align={"middle"}>
          <img
            src={IconList.chevronLeft}
            onClick={() => {
              setCurrentIndex(
                currentIndex === 0
                  ? projectDetail?.images.length - 1
                  : currentIndex - 1
              );
            }}
          />
          <img
            src={projectDetail?.images[currentIndex]}
            style={{ maxWidth: 400, maxHeight: 400 }}
          />
          <img
            className="revert"
            src={IconList.chevronLeft}
            alt=""
            onClick={() => {
              setCurrentIndex(
                currentIndex + 1 === projectDetail?.images.length
                  ? 0
                  : currentIndex + 1
              );
            }}
          />
        </Row>
      </Modal>
    </div>
  );
};
export default ProjectDetail;
