import { Button, Modal, Row } from "antd";
import "./styles.scss";
import { useSelector } from "react-redux";
const ViewCard = ({ isView, setIsView }) => {
  const { cardDetail } = useSelector((state) => state.card);
  return (
    <Modal
      open={isView}
      onCancel={() => setIsView(false)}
      title="Card details"
      className="view-card-container"
      footer={[
        <Button
          type="primary"
          className="primary"
          onClick={() => setIsView(false)}
        >
          Close
        </Button>,
      ]}
    >
      <h4>Question</h4>
      <p>{cardDetail?.cardTitle}</p>
      <h4>Description</h4>
      <p>{cardDetail?.cardDescription}</p>
      <h4>Icon</h4>
      {cardDetail?.cardIcon !== "" &&
        !cardDetail?.cardIcon?.includes("undefined") && (
          <img src={cardDetail?.cardIcon} />
        )}
      <h4>Card type</h4>
      <p>{cardDetail?.isRequired === true ? "Mandatory" : "Optional"}</p>
    </Modal>
  );
};
export default ViewCard;
