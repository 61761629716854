import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_END_POINT, privateApi } from "../../../api/apiConfig";
import { toaster } from "../../../utils/toast";

export const getMyProject = createAsyncThunk(
  "get-my-project",
  async ({ params }) => {
    try {
      const response = await privateApi.get(API_END_POINT.GET_MY_PROJECT, {
        params,
      });

      return response;
    } catch (err) {
      return err;
    }
  }
);

export const deleteProject = createAsyncThunk(
  "delete-project",
  async ({ values, callback }) => {
    try {
      const response = await privateApi.post(
        API_END_POINT.DELETE_PROJECT,
        values
      );

      if (response?.data?.status) {
        callback();
      } else {
        toaster("error", response?.data?.message);
        toaster("error", response?.message);
      }
      return response;
    } catch (err) {
      console.log(err);
      return err;
    }
  }
);

export const createProject = createAsyncThunk(
  "create-project",
  async ({ values, callback }) => {
    try {
      const response = await privateApi.post(
        API_END_POINT.CREATE_PROJECT,
        values
      );

      if (response?.data?.status) {
        callback();
      } else {
        toaster("error", response?.data?.message);
        toaster("error", response?.message);
      }
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const updateProject = createAsyncThunk(
  "update-project",
  async ({ values, callback }) => {
    try {
      const response = await privateApi.post(
        API_END_POINT.UPDATE_PROJECT,
        values
      );

      if (response?.data?.status) {
        callback();
      } else {
        toaster("error", response?.data?.message);
        toaster("error", response?.message);
      }
      return response;
    } catch (err) {
      return err;
    }
  }
);
