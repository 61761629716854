import { Col, Row } from "antd";
import SideNav from "../screens/Common/SideNav";
import PostLoginHeader from "../screens/Common/Headers/PostLoginHeader";
import Content from "../screens/Common/Content";
import { useSelector } from "react-redux";

const DefaultLayout = () => {
  const { isSideNavHidden } = useSelector((state) => state.setting);

  return (
    <Row md className="default-layout">
      {isSideNavHidden && (
        <Col md={4}>
          <SideNav />
        </Col>
      )}
      <Col md={isSideNavHidden ? 20 : 24} className="overflow-scroll">
        <PostLoginHeader />
        <Content />
      </Col>
    </Row>
  );
};
export default DefaultLayout;
