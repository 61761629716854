import { createSlice } from "@reduxjs/toolkit";
import {
  getCities,
  getState,
  getUserProfile,
} from "../reducer/setting.reducer";
const initialState = {
  selectedOption: "Profile",
  loading: false,
  userProfile: {},
  isDesktopNotification: false,
  isEmailNotification: false,
  isNewDeveloperAdded: false,
  isProjectUpdated: false,
  isSideNavHidden: true,
  stateList: [],
  cityList: [],
  address: "",
  location: {},
};

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setSelectedOption: (state, { payload }) => {
      state.selectedOption = payload;
    },
    setIsSideNavHidden: (state, { payload }) => {
      state.isSideNavHidden = payload;
    },
    setIsDesktopNotification: (state, { payload }) => {
      state.isDesktopNotification = payload;
    },
    setIsEmailNotification: (state, { payload }) => {
      state.isEmailNotification = payload;
    },
    setIsNewDeveloperAdded: (state, { payload }) => {
      state.isNewDeveloperAdded = payload;
    },
    setIsProjectUpdated: (state, { payload }) => {
      state.isProjectUpdated = payload;
    },
    setAddress: (state, { payload }) => {
      state.address = payload;
    },
    setLocation: (state, { payload }) => {
      state.location = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserProfile.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getUserProfile.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload?.data?.status) {
        state.userProfile = payload?.data?.userDetail;
        state.isNewDeveloperAdded =
          payload?.data?.userDetail?.isNewDeveloperAdded;
        state.isDesktopNotification =
          payload?.data?.userDetail?.isDesktopNotification;
        state.isEmailNotification =
          payload?.data?.userDetail?.isEmailNotification;
        state.isProjectUpdated = payload?.data?.userDetail?.isProjectUpdated;
      }
    });

    builder.addCase(getState.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getState.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getState.fulfilled, (state, { payload }) => {
      state.loading = false;

      state.stateList = payload?.data?.data;
    });

    builder.addCase(getCities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCities.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getCities.fulfilled, (state, { payload }) => {
      state.loading = false;

      state.cityList = payload?.data?.data;
    });
  },
});
export const settingActions = settingSlice.actions;
export default settingSlice.reducer;
