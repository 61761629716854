import { useEffect, useState } from "react";
import "./styles.scss";
import Heading from "./Heading";
import { EyeOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetail } from "../../../appConfig/redux/reducer/userManagement.reducer";
import { Col, Row, Table, Tooltip } from "antd";
import { ImageList } from "../../../constants/imageList";
import { projectActions } from "../../../appConfig/redux/slice/project.slice";
import { SCREEN_PATHS } from "../../../constants";
import { useNavigate } from "react-router-dom";

const UserDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const projectColumns = [
    {
      title: "Project Name",
      dataIndex: "projectName",
      key: "projectName",
      render: (text) => {
        return <p>{text}</p>;
      },
    },

    {
      title: "Project Type",
      dataIndex: "projectType",
      key: "projectType",
      render: (text) => {
        return <p>{text}</p>;
      },
    },
    {
      title: "Project Address",
      dataIndex: "projectAddress",
      key: "projectAddress",
      render: (text) => {
        return (
          <Tooltip title={text}>
            <p className="address">
              {text.length > 30 ? `${text.slice(0, 30)}...` : text}
            </p>
          </Tooltip>
        );
      },
    },
    {
      title: "Project Status",
      dataIndex: "projectStatus",
      key: "projectStatus",
      render: (text) => {
        return (
          <p
            className={
              text == "draft"
                ? "draft"
                : text == "active"
                ? "active"
                : "completed"
            }
          >
            {text}
          </p>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "projectId",
      key: "projectId",
      render: (text) => {
        return (
          <Row justify={"space-around"}>
            <EyeOutlined
              style={{ fontSize: 20 }}
              onClick={() => {
                const project = otherDetail.find(
                  (item) => item.projectId == text
                );
                navigate(SCREEN_PATHS.projectDetail);
                dispatch(projectActions.setProjectDetail(project));
              }}
            />
          </Row>
        );
      },
    },
  ];
  const reviewColumns = [
    {
      title: "Project Name",
      dataIndex: "projectName",
      key: "projectName",
      render: (text) => {
        return <p>{text}</p>;
      },
    },
    {
      title: "Supporter",
      dataIndex: "isSupporter",
      key: "isSupporter",
      render: (text) => {
        return <p className="address">{text === 0 ? "No" : "Yes"}</p>;
      },
    },
    {
      title: "Volunteer",
      dataIndex: "isVolunteer",
      key: "isVolunteer",
      render: (text) => {
        return <p>{text == 1 ? "Yes" : "No"}</p>;
      },
    },
    {
      title: "Review Status",
      dataIndex: "reviewStatus",
      key: "reviewStatus",
      render: (text) => {
        return <p>{text}</p>;
      },
    },
    {
      title: "Project Type",
      dataIndex: "projectType",
      key: "projectType",
      render: (text) => {
        return <p>{text}</p>;
      },
    },
  ];
  const { selectedUser, userDetail, otherDetail } = useSelector(
    (state) => state.userManagement
  );
  const [isInfo, setIsInfo] = useState(true);
  const info = [
    { title: "First Name", field: "firstName" },
    { title: "Last Name", field: "surName" },
    { title: "Email Address", field: "email" },
    { title: "Mobile Number", field: "phoneNumber" },
    { title: "Country", field: "country" },
    { title: "State", field: "state" },
    { title: "City", field: "city" },
    { title: "Zip Code", field: "zipCode" },
    { title: "Organization Name", field: "organisation" },
  ];
  useEffect(() => {
    if (selectedUser?.userType) {
      dispatch(
        getUserDetail({
          params: { type: selectedUser?.userType, id: selectedUser?.userId },
        })
      );
    } else {
      navigate(-1);
    }
  }, []);
  return (
    <div className="userdetail-container">
      <Heading />
      <Row align={"middle"} style={{ marginTop: 10 }}>
        {userDetail?.profilePhoto ? (
          <img
            className="profile"
            src={
              userDetail?.profilePhoto === null
                ? ImageList.defaultUser
                : userDetail?.profilePhoto.includes("undefined")
                ? ImageList.defaultUser
                : userDetail?.profilePhoto
            }
          />
        ) : (
          <img className="profile" src={ImageList.defaultUser} />
        )}
        <div className="name">
          <h3>{`${userDetail?.firstName} ${userDetail?.surName}`}</h3>
          <p>{`${selectedUser?.userType}`}</p>
        </div>
      </Row>
      <Row className="options-bar">
        <p className={isInfo ? "selected" : ""} onClick={() => setIsInfo(true)}>
          Info
        </p>
        <p
          className={!isInfo ? "selected" : ""}
          onClick={() => setIsInfo(false)}
        >
          {selectedUser?.userType === "Developer"
            ? "Project List"
            : "Review List"}
        </p>
      </Row>
      {isInfo ? (
        <div>
          <Row>
            {info.map((item, key) => (
              <>
                {userDetail && userDetail[`${item.field}`] && (
                  <Col key={key} className="info-container">
                    <p className="heading">{item.title}</p>
                    <p className="detail">
                      {userDetail && userDetail[`${item.field}`]}
                    </p>
                  </Col>
                )}
              </>
            ))}
          </Row>
        </div>
      ) : (
        <div className="table">
          <Table
            size="middle"
            columns={
              selectedUser?.userType === "Developer"
                ? projectColumns
                : reviewColumns
            }
            dataSource={otherDetail ?? []}
          />
        </div>
      )}
    </div>
  );
};
export default UserDetail;
