import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_END_POINT, privateApi } from "../../../api/apiConfig";
import { toaster } from "../../../utils/toast";

export const createCard = createAsyncThunk(
  "create-card",
  async ({ values, callback }) => {
    try {
      const response = await privateApi.post(API_END_POINT.CREATE_CARD, values);

      if (response?.data?.status) {
        callback();
      } else {
        toaster("error", response.data?.message);
        toaster("error", response?.message);
      }
      return response;
    } catch (err) {
      return err;
    }
  }
);
export const updateCard = createAsyncThunk(
  "update-card",
  async ({ values, callback }) => {
    try {
      const response = await privateApi.patch(
        API_END_POINT.UPDATE_CARD,
        values
      );

      if (response?.data?.status) {
        callback();
      } else {
        toaster("error", response.data?.message);
        toaster("error", response?.message);
      }
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getMyCards = createAsyncThunk(
  "get-my-cards",
  async ({ params }) => {
    try {
      const response = await privateApi.get(API_END_POINT.GET_MY_CARD, {
        params,
      });
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getDefaultCards = createAsyncThunk(
  "get-default-cards",
  async ({ params }) => {
    try {
      const response = await privateApi.get(API_END_POINT.GET_DEFAULT_CARD, {
        params,
      });
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getProjectCards = createAsyncThunk(
  "get-project-cards",
  async (id) => {
    try {
      const response = await privateApi.get(
        API_END_POINT.GET_PROJECT_CARD + id
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const deleteCards = createAsyncThunk(
  "delete-cards",
  async ({ values, callback }) => {
    try {
      const response = await privateApi.delete(
        API_END_POINT.DELETE_CARD + values.cardId
      );
      if (response?.data?.status) {
        callback();
      }
      return response;
    } catch (err) {
      return err;
    }
  }
);
