import { Empty, Modal, Row, Table, Tooltip } from "antd";
import Heading from "./Heading";
import "./styles.scss";
import { EyeOutlined, FilePdfOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  Autocomplete,
  GoogleMap,
  Marker,
  CustomIcon,
  useLoadScript,
} from "@react-google-maps/api";
import {
  getReportForAdmin,
  getReportForDev,
  getReportList,
} from "../../appConfig/redux/reducer/report.reducer";
import { PDFViewer } from "@react-pdf/renderer";
import PDF from "../PDF";
import { ImageList, PDFList } from "../../constants/imageList";
const libraries = ["places", "maps", "marker"];
const Report = () => {
  const { userData } = useSelector((state) => state.auth);
  const { reportList, loading, pdf } = useSelector((state) => state.report);
  const [isViewPDF, setIsViewPDF] = useState(false);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_LOCATION_SERVICE,
    libraries,
  });

  const columns = [
    {
      title: "Project Name",
      dataIndex: "projectName",
      key: "projectName",
      sorter: (a, b) => {
        return a.projectName.localeCompare(b.projectName);
      },
      render: (text) => {
        return <p>{text}</p>;
      },
    },
    {
      title: "Project Type",
      dataIndex: "projectType",
      key: "projectType",
      filters: [
        {
          text: "Residential",
          value: "residential",
        },
        {
          text: "Commercial",
          value: "commercial",
        },
        {
          text: "All",
          value: "all",
        },
      ],
      onFilter: (value, record) => {
        if (value === "all") {
          return true;
        } else {
          return record.projectType === value ? true : false;
        }
      },
      render: (text) => {
        return <p>{text}</p>;
      },
    },
    {
      title: "Project Address",
      dataIndex: "projectAddress",
      key: "projectAddress",
      sorter: (a, b) => {
        return a.projectAddress.localeCompare(b.projectAddress);
      },
      render: (text) => {
        return (
          <Tooltip title={text}>
            <p className="address">
              {text?.length > 50 ? `${text?.slice(0, 50)}...` : text}
            </p>
          </Tooltip>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "projectId",
      key: "projectId",
      render: (text) => {
        return (
          <Row justify={"space-around"}>
            {pdf?.projectDetail?.projectId != text ? (
              <>
                <h4
                  className="generate"
                  onClick={() => {
                    dispatch(
                      userData?.userType === "re_developer"
                        ? getReportForDev({
                            params: { projectId: text },
                          })
                        : getReportForAdmin({
                            params: { projectId: text },
                          })
                    );
                  }}
                >
                  <Tooltip title="Generate PDF Report">
                    <FilePdfOutlined
                      style={{ fontSize: 26, color: "#662d91" }}
                    />
                  </Tooltip>
                </h4>
              </>
            ) : (
              <EyeOutlined
                style={{ fontSize: 26, color: "#662d91" }}
                onClick={() => setIsViewPDF(true)}
              />
            )}
          </Row>
        );
      },
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReportList({ params: { filter: "" } }));
  }, []);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {},
    type: "checkbox",
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  return (
    <div className="report-container">
      {loading ? (
        <Row justify={"center"}>
          <div>
            <img
              src={ImageList.loader}
              alt="Computer man"
              style={{ marginTop: 20 }}
            />
          </div>
        </Row>
      ) : (
        <>
          <Heading />
          <div className="table">
            {reportList?.length === 0 ? (
              <Empty description={<span>No Reports</span>} />
            ) : (
              <Table
                size="middle"
                rowSelection={rowSelection}
                columns={columns}
                dataSource={reportList ?? []}
              />
            )}
          </div>
        </>
      )}
      <Modal
        open={isViewPDF}
        onCancel={() => {
          setIsViewPDF(false);
        }}
        style={{ top: 10 }}
        width={"100%"}
        footer={[]}
        title="Report"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <PDFViewer style={{ width: "100%", height: "26.5rem" }}>
            <PDF pdf={pdf} userData={userData} style={{ width: "100%" }} />
          </PDFViewer>
        </div>
      </Modal>
    </div>
  );
};
export default Report;
