import { Button, Modal } from "antd";

const DeletePopup = ({ open, onClick, onCancel }) => {
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={[
        <Button type="primary" danger onClick={onClick}>
          Delete
        </Button>,
      ]}
    >
      Are you sure you want to delete? This action is not reversable.
    </Modal>
  );
};

export default DeletePopup;
