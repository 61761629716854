import { createSlice } from "@reduxjs/toolkit";
import {
  createCard,
  getDefaultCards,
  getMyCards,
  getProjectCards,
} from "../reducer/card.reducer";
import { toaster } from "../../../utils/toast";
import moment from "moment";

const initialState = {
  isListView: true,
  isFilterOpen: false,
  isDefaultCard: false,
  cardDetail: {},
  selectedCards: [],
  cardData: [],
  projectCards: [],
  defaultCards: [],
};

const cardSlice = createSlice({
  name: "card",
  initialState,
  reducers: {
    setIsListView: (state, { payload }) => {
      state.isListView = payload;
    },
    setIsDefaultCard: (state, { payload }) => {
      state.isDefaultCard = payload;
    },
    setSelectedCards: (state, { payload }) => {
      state.selectedCards = payload;
    },
    setCardDetail: (state, { payload }) => {
      state.cardDetail = payload;
    },
    setIsFilterOpen: (state, { payload }) => {
      state.isFilterOpen = payload;
    },
    logout: (state) => {
      state = {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createCard.pending, (state) => {});
    builder.addCase(createCard.fulfilled, (state, { payload }) => {
      if (payload?.status == false) {
        toaster("error", payload.message);
      } else {
      }
    });
    builder.addCase(createCard.rejected, (state, { payload }) => {});

    builder.addCase(getMyCards.pending, (state) => {});
    builder.addCase(getMyCards.fulfilled, (state, { payload }) => {
      if (payload?.status == false) {
        toaster("error", payload.message);
      } else {
        if (payload?.data?.details?.cardDetails) {
          state.cardData = payload?.data.details.cardDetails.map(
            (item, index) => {
              return {
                ...item,
                key: item.id,
                createdOn: moment(item?.createdOn).format("MMM DD, YYYY HH:mm"),
                modifiedOn: moment(item?.modifiedOn).format(
                  "MMM DD, YYYY HH:mm"
                ),
              };
            }
          );
        } else {
          state.cardData = [];
        }
      }
    });
    builder.addCase(getMyCards.rejected, (state, { payload }) => {});

    builder.addCase(getProjectCards.pending, (state) => {});
    builder.addCase(getProjectCards.fulfilled, (state, { payload }) => {
      if (payload?.status == false) {
        toaster("error", payload.message);
      } else {
        if (payload?.data?.details?.cardDetails) {
          state.projectCards = payload?.data.details.cardDetails.map(
            (item, index) => {
              return {
                ...item,
                key: index,
                createdOn: moment(item?.createdOn).format("MMM DD, YYYY HH:mm"),
                modifiedOn: moment(item?.modifiedOn).format(
                  "MMM DD, YYYY HH:mm"
                ),
              };
            }
          );
        } else {
          state.projectCards = [];
        }
      }
    });
    builder.addCase(getProjectCards.rejected, (state, { payload }) => {});

    builder.addCase(getDefaultCards.pending, (state) => {});
    builder.addCase(getDefaultCards.fulfilled, (state, { payload }) => {
      if (payload?.status == false) {
        toaster("error", payload.message);
      } else {
        if (payload?.data?.cardDetails) {
          state.defaultCards = payload?.data.cardDetails.map((item, index) => {
            return {
              ...item,
              key: item.staticCardId,
              createdOn: moment(item?.createdOn).format("MMM DD, YYYY HH:mm"),
              modifiedOn: moment(item?.modifiedOn).format("MMM DD, YYYY HH:mm"),
            };
          });
        } else {
          state.defaultCards = [];
        }
      }
    });
    builder.addCase(getDefaultCards.rejected, (state, { payload }) => {});
  },
});
export const cardActions = cardSlice.actions;
export default cardSlice.reducer;
