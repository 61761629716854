import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_END_POINT, privateApi } from "../../../api/apiConfig";
import { toaster } from "../../../utils/toast";

export const getDashboard = createAsyncThunk("get-dashboard", async () => {
  try {
    const response = await privateApi.get(API_END_POINT.GET_DASHBOARD);
    return response;
  } catch (err) {
    return err;
  }
});

export const getProjectDashboard = createAsyncThunk(
  "get-project-dashboard",
  async ({ params }) => {
    try {
      const response = await privateApi.get(
        API_END_POINT.GET_DASHBOARD_PROJECT,
        { params }
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getUserDashboard = createAsyncThunk(
  "get-user-dashboard",
  async ({ params }) => {
    try {
      const response = await privateApi.get(API_END_POINT.GET_DASHBOARD_USER, {
        params,
      });
      return response;
    } catch (err) {
      return err;
    }
  }
);
export const getRequestDashboard = createAsyncThunk(
  "get-request-dashboard",
  async ({ params }) => {
    try {
      const response = await privateApi.get(
        API_END_POINT.GET_DASHBOARD_REQUEST,
        { params }
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);
