import { Button, Col, Form, Input, Radio, Row, Select } from "antd";
import "./styles.scss";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { projectActions } from "../../../appConfig/redux/slice/project.slice";
import { NEW_PROJECT_STEPS } from "../../../constants/enum";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SCREEN_PATHS } from "../../../constants";
import { positiveNumberPattern } from "../../../utils/formValidations";
import {
  Autocomplete,
  GoogleMap,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { IconList, PDFList } from "../../../constants/imageList";

const { TextArea } = Input;

const Page1 = ({ form }) => {
  const libraries = ["places", "maps", "marker"];
  const [map, setMap] = useState();
  const [searchResult, setSearchResult] = useState();
  const onLoad = (autocomplete) => {
    setSearchResult(autocomplete);
  };
  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place = searchResult.getPlace();

      const formattedAddress = place.formatted_address;
      const location = JSON.parse(JSON.stringify(place.geometry.location));
      setMap(location);
      setAddress(formattedAddress);
    }
  };
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_LOCATION_SERVICE,
    libraries,
    region: "US",
  });

  const [address, setAddress] = useState("");

  const dispatch = useDispatch();
  const step = useSelector((state) => state.project.step);
  const navigate = useNavigate();

  const [type, setType] = useState();
  const [benefitName, setBenefitName] = useState("");
  const { projectDetail } = useSelector((state) => state.project);
  useEffect(() => {
    setType(projectDetail?.projectType);

    setBenefits(
      typeof projectDetail?.benefit == "string"
        ? JSON.parse(projectDetail?.benefit)
        : projectDetail?.benefit
    );
    setAddress(projectDetail?.projectAddress);
    setMap({
      lat: Number(projectDetail?.latitude),
      lng: Number(projectDetail?.longitude),
    });
  }, [projectDetail]);

  const onFinish = (values) => {
    dispatch(projectActions.setNewProjectStep(NEW_PROJECT_STEPS.step2));
    dispatch(
      projectActions.setStep1Data({
        ...values,
        projectAddress: address,
        longitude: map?.lng,
        latitude: map?.lat,
        benefit: benefits,
      })
    );
  };

  const onFinishFailed = (values) => {};

  const [benefits, setBenefits] = useState([]);
  if (!isLoaded) {
    return <></>;
  }
  return (
    <div className="page-1-container">
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        name="basic"
      >
        <h4>Project Type</h4>
        <Form.Item
          initialValue={projectDetail?.projectType}
          name="projectType"
          rules={[
            { required: true, message: "Please select suitable project type!" },
          ]}
        >
          <Radio.Group value={type}>
            <Radio
              value="residential"
              className={`radio ${type === "residential" && "selected-option"}`}
              onClick={() => {
                setType("residential");
              }}
            >
              Residential
            </Radio>
            {/* <Radio
              value="commercial"
              className={`radio ${type === "commercial" && "selected-option"}`}
              onClick={() => {
                setType("commercial");
              }}
            >
              Commercial
            </Radio> */}
          </Radio.Group>
        </Form.Item>
        <h4>Project name</h4>
        <Form.Item
          initialValue={projectDetail?.projectName}
          name="projectName"
          rules={[{ required: true, message: "Please enter project name!" }]}
        >
          <Input placeholder="Enter project name here..." />
        </Form.Item>
        <h4>Project address</h4>
        <Form.Item
          initialValue={projectDetail?.projectAddress}
          name="projectAddress"
          // rules={[{ required: true, message: "Please enter project address!" }]}
        >
          <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
            <Input
              placeholder="Enter project address here..."
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Autocomplete>
        </Form.Item>
        {isLoaded && (
          <div className="map">
            <GoogleMap
              center={map}
              zoom={15}
              mapContainerStyle={{ height: "100%", width: "100%" }}
              onClick={(e) => {
                setMap(JSON.parse(JSON.stringify(e.latLng)));
              }}
              onLoad={(map) => {}}
            >
              {map && (
                <Marker
                  position={map}
                  onDrag={(e) => {
                    setMap(JSON.parse(JSON.stringify(e.latLng)));
                  }}
                  icon={PDFList.PDFLogoSvg}
                />
              )}
            </GoogleMap>
          </div>
        )}
        <Row md>
          <Col md={11}>
            <h4>Building height</h4>
            <Form.Item
              initialValue={projectDetail?.buidlingHeight}
              name="buidlingHeight"
              rules={[
                {
                  required: true,
                  pattern: positiveNumberPattern.value,
                  message: positiveNumberPattern.message,
                },
              ]}
            >
              <Input placeholder="Enter building height" />
            </Form.Item>
          </Col>
          <Col md={2} />
          <Col md={11}>
            <h4>Property size (sqf)</h4>
            <Form.Item
              initialValue={projectDetail?.propertySize}
              name="propertySize"
              rules={[
                {
                  required: true,
                  pattern: positiveNumberPattern.value,
                  message: positiveNumberPattern.message,
                },
              ]}
            >
              <Input placeholder="Enter property size" />
            </Form.Item>
          </Col>
        </Row>
        <Row md>
          <Col md={11}>
            <h4>Maximum Capacity (Residents)</h4>
            <Form.Item
              name="maximumCapacity"
              initialValue={projectDetail?.maximumCapacity}
              rules={[
                {
                  required: true,
                  pattern: positiveNumberPattern.value,
                  message: positiveNumberPattern.message,
                },
              ]}
            >
              <Input className="width" placeholder="Enter maximum capacity" />
            </Form.Item>
          </Col>
        </Row>
        <Row md>
          <Col md={11}>
            <h4>Residential units</h4>
            <Form.Item
              initialValue={projectDetail?.residentialUnits}
              name="residentialUnits"
              rules={[
                {
                  required: true,
                  pattern: positiveNumberPattern.value,
                  message: positiveNumberPattern.message,
                },
              ]}
            >
              <Input placeholder="Enter residential units" />
            </Form.Item>
          </Col>
          <Col md={2} />
          <Col md={11}>
            <h4>Floor plan (sqf)</h4>
            <Form.Item
              initialValue={projectDetail?.floorPlan}
              name="floorPlan"
              rules={[
                {
                  required: true,
                  pattern: positiveNumberPattern.value,
                  message: positiveNumberPattern.message,
                },
              ]}
            >
              <Input placeholder="Enter maximum floor" />
            </Form.Item>
          </Col>
        </Row>
        <h4>Benefits</h4>
        <Row md>
          <Col md={11}>
            <Input
              placeholder="Enter Benefit"
              value={benefitName}
              onChange={(e) => setBenefitName(e.target.value)}
            />
          </Col>
          <Col md={1}></Col>
          <Button
            type="primary"
            className={benefitName === "" ? "" : "primary"}
            onClick={() => {
              setBenefits([...benefits, benefitName]);
              setBenefitName("");
            }}
            disabled={benefitName === ""}
          >
            Add
          </Button>
        </Row>
        <h4 />
        <div className="benefits">
          {benefits?.map((item, index) => (
            <div className={`benefit selected-benefit`} key={index}>
              {item}{" "}
              {
                <CloseOutlined
                  onClick={() => {
                    setBenefits([
                      ...benefits.filter((item, ind) => index !== ind),
                    ]);
                  }}
                />
              }
            </div>
          ))}
        </div>
        <h4>Project description</h4>
        <Form.Item
          initialValue={projectDetail?.projectDescription}
          name="projectDescription"
          rules={[{ required: true, message: "Please enter description!" }]}
        >
          <TextArea placeholder="Enter description here..." rows={4} />
        </Form.Item>
        <Row>
          <Button
            onClick={() => {
              navigate(SCREEN_PATHS.project);
              dispatch(projectActions.setNewProjectStep());
            }}
          >
            Cancel
          </Button>

          <Form.Item>
            <Button
              type={"primary"}
              className={"primary margin"}
              htmlType="submit"
            >
              {step === NEW_PROJECT_STEPS.step3 ? "Save details" : "Continue"}
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};
export default Page1;
