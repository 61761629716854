import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import { PreLoginHeader } from "../Common/Headers/PreLoginHeader";
import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import { ImageList } from "../../constants/imageList";
import { passwordPattern } from "../../utils/formValidations";

const SetNewPassword = () => {
  const navigate = useNavigate();
  const onFinish = (values) => {};
  const onFinishFailed = (values) => {};

  return (
    <div className="set-new-password-container">
      <PreLoginHeader className="login" />
      <Row md className="main-container">
        <Col md={12} className="left-container">
          <img src={ImageList.setNewPasswordImage} />
        </Col>
        <Col md={12} className="right-container">
          <h1>Welcome back to YIMBY, Jeremy</h1>
          <h5>
            You have been added to YIMBY as an admin for Urban Conversions.
            Before we get started, let’s create a password to secure your
            account
          </h5>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="form-container"
            autoComplete="off"
          >
            <p>Create password</p>
            <Form.Item
              name="password"
              className="Field"
              rules={[
                {
                  required: true,
                  pattern: passwordPattern.value,
                  message: passwordPattern.message,
                },
              ]}
            >
              <Input.Password
                placeholder="Enter password here..."
                type="password"
              />
            </Form.Item>
            <p> Confirm password</p>
            <Form.Item
              name="confirmPassword"
              className="Field"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Enter confirm password here..."
                type="password"
              />
            </Form.Item>
            <Row
              className="remember-me-container"
              justify={"space-between"}
              align={"middle"}
            >
              <Row>
                <Checkbox />
                <p>Remember me</p>
              </Row>
            </Row>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Set new password
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};
export default SetNewPassword;
