import React from "react";
import "./styles.scss";
import { Button } from "antd";
import { IconList } from "../../../constants/imageList";
import { useDispatch, useSelector } from "react-redux";
import { projectActions } from "../../../appConfig/redux/slice/project.slice";
import { useNavigate } from "react-router-dom";
import { SCREEN_PATHS } from "../../../constants";
import { NEW_PROJECT_STEPS } from "../../../constants/enum";

const Heading = () => {
  const { projectDetail } = useSelector((state) => state.project);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="heading-container">
      <div className="heading">
        <img
          src={IconList.chevronLeft}
          onClick={() => {
            navigate(-1);
          }}
        />
        <h3> Projects Detail</h3>
      </div>
      <div className="options-container">
        <Button>
          <span>
            <img src={IconList.deleteIcon} />
            <p>Delete</p>
          </span>
        </Button>
        {projectDetail?.projectStatus === "active" && (
          <Button
            type={"primary"}
            className={"primary"}
            onClick={() => {
              navigate(SCREEN_PATHS.editProject);
              dispatch(
                projectActions.setNewProjectStep(NEW_PROJECT_STEPS.step1)
              );
            }}
          >
            <span>
              <img src={IconList.edit} />
              <p className="white">Edit</p>
            </span>
          </Button>
        )}
      </div>
    </div>
  );
};

export default Heading;
