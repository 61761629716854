import { Empty, Row, Table } from "antd";
import "./styles.scss";
import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import GridView from "./GridView";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SCREEN_PATHS } from "../../constants";
import { getMyProject } from "../../appConfig/redux/reducer/project.reducer";
import {
  getDefaultCards,
  getMyCards,
} from "../../appConfig/redux/reducer/card.reducer";
import ViewCard from "./ViewCard";
import { cardActions } from "../../appConfig/redux/slice/card.slice";
import EditCard from "./EditCard";
import moment from "moment";

const Card = () => {
  const dispatch = useDispatch();
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { isListView, cardData, isDefaultCard, defaultCards } = useSelector(
    (state) => state.card
  );

  useEffect(() => {
    dispatch(getMyCards({ params: {} }));
    dispatch(getDefaultCards({ params: {} }));
  }, []);
  const { userData } = useSelector((state) => state.auth);
  const columns = [
    {
      title: "Card Question",
      dataIndex: "cardTitle",
      sorter: (a, b) => {
        return a.cardTitle.localeCompare(b.cardTitle);
      },
      key: "cardTitle",
      render: (text) => {
        return <p>{text}</p>;
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdOn",
      key: "createdOn",
      sorter: (a, b) => {
        return moment(a.createdOn).unix() - moment(b.createdOn).unix();
      },
      render: (text) => {
        return <p>{text}</p>;
      },
    },
    {
      title: "Modified Date",
      dataIndex: "modifiedOn",
      key: "modifiedOn",
      sorter: (a, b) => {
        return moment(a.modifiedOn).unix() - moment(b.modifiedOn).unix();
      },
      render: (text) => {
        return <p>{text}</p>;
      },
    },
    {
      title: "Actions",
      dataIndex: isDefaultCard ? "staticCardId" : "id",
      key: isDefaultCard ? "staticCardId" : "id",

      render: (text) => {
        return (
          <Row justify={"space-evenly"}>
            <EyeOutlined
              style={{ fontSize: 20 }}
              onClick={() => {
                const card = isDefaultCard
                  ? defaultCards.find((item) => item.staticCardId == text)
                  : cardData.find((item) => item.id == text);
                dispatch(cardActions.setCardDetail(card));
                setIsView(true);
              }}
            />
            {userData.userType === "re_developer" && !isDefaultCard && (
              <EditOutlined
                style={{ fontSize: 20 }}
                onClick={() => {
                  const card = cardData.find((item) => item.id == text);
                  dispatch(cardActions.setCardDetail(card));
                  setIsEdit(true);
                }}
              />
            )}
          </Row>
        );
      },
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      dispatch(cardActions.setSelectedCards(selectedRows));
    },
    type: "checkbox",
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };
  return (
    <div className="card-container">
      <Heading />
      {isListView ? (
        <div className="table">
          {(isDefaultCard && defaultCards.length === 0) ||
          (!isDefaultCard && cardData.length === 0) ? (
            <Empty description={<span>No Cards</span>} />
          ) : (
            <Table
              size="middle"
              rowSelection={rowSelection}
              columns={columns}
              dataSource={isDefaultCard ? defaultCards ?? [] : cardData ?? []}
            />
          )}
        </div>
      ) : (
        <GridView />
      )}
      <ViewCard isView={isView} setIsView={setIsView} />
      <EditCard isEdit={isEdit} setIsEdit={setIsEdit} />
    </div>
  );
};

export default Card;
