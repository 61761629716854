//-------------------------------images---------------------------------------
import loginImage from "../assets/images/login.svg";
import welcomeImage from "../assets/images/welcome.svg";
import signupImage from "../assets/images/signup.svg";
import userImage from "../assets/images/userImage.png";
import setNewPasswordImage from "../assets/images/setNewPassword.svg";
import forgotPasswordImage from "../assets/images/forgotPassword.svg";
import resetPasswordImage from "../assets/images/resetPassword.svg";
import sampleProject from "../assets/images/sampleProject.png";
import sampleMap from "../assets/images/sampleMap.png";
import sampleUpload from "../assets/images/sampleUpload.png";
import themeLight from "../assets/images/themeLight.png";
import themeDark from "../assets/images/themeDark.png";
import sampleProjectFull from "../assets/images/sampleProjectFull.png";
import defaultUser from "../assets/images/defaultUser.png";
import loader from "../assets/images/loader.gif";
//-------------------------------icons----------------------------------------
import checkedIcon from "../assets/icons/checkedIcon.png";
import checkIcon from "../assets/icons/checkIcon.png";
import visible from "../assets/icons/visible.png";
import settings from "../assets/icons/settings.png";
import projectSelected from "../assets/icons/projectSelected.svg";
import business from "../assets/icons/business.png";
import activeSettings from "../assets/icons/activeSettings.png";
import activeProjects from "../assets/icons/activeProjects.png";
import activeBusiness from "../assets/icons/activeBusiness.png";
import arrowDown from "../assets/icons/arrowDown.png";
import arrowUp from "../assets/icons/arrowUp.png";
import notification from "../assets/icons/notification.png";
import copy from "../assets/icons/copy.svg";
import copySelected from "../assets/icons/copySelected.svg";
import create from "../assets/icons/create.svg";
import filter1 from "../assets/icons/filter1.svg";
import filter2 from "../assets/icons/filter2.svg";
import filter1Selected from "../assets/icons/filter1Selected.svg";
import filter2Selected from "../assets/icons/filter2Selected.svg";
import deleteIcon from "../assets/icons/delete.svg";
import deleteSelected from "../assets/icons/deleteSelected.svg";
import listView from "../assets/icons/listView.svg";
import listViewSelected from "../assets/icons/listViewSelected.svg";
import gridView from "../assets/icons/gridView.svg";
import gridViewSelected from "../assets/icons/gridViewSelected.svg";
import chevronDown from "../assets/icons/chevronDown.svg";
import chevronDownSelected from "../assets/icons/chevronDownSelected.svg";
import cross from "../assets/icons/cross.svg";
import chevronLeft from "../assets/icons/chevronLeft.svg";
import edit from "../assets/icons/edit.svg";
import projectDetailType from "../assets/icons/projectDetailType.svg";
import projectDetailParking from "../assets/icons/projectDetailParking.svg";
import projectDetailPopulation from "../assets/icons/projectDetailPopulation.svg";
import projectDetailArea from "../assets/icons/projectDetailArea.svg";
import upload from "../assets/icons/upload.svg";
import request from "../assets/icons/request.svg";
import requestSelected from "../assets/icons/requestSelected.svg";
import users from "../assets/icons/users.svg";
import usersSelected from "../assets/icons/usersSelected.svg";
import thumbsUp from "../assets/icons/thumbsUp.svg";
import thumbsUpColor from "../assets/icons/thumbsUpColor.svg";
import thumbsDown from "../assets/icons/thumbsDown.svg";
import thumbsDownColor from "../assets/icons/thumbsDownColor.svg";
import plus from "../assets/icons/plus.svg";
import download from "../assets/icons/download.svg";

//-------------------------------PDF---------------------------------------
import PDFHeaderLogo from "../assets/pdfs/headerLogo.png";
import PDFyimby from "../assets/pdfs/yimby.png";
import PDFyimbyGreen from "../assets/pdfs/yimbyGreen.png";
import PDFnimbyGreen from "../assets/pdfs/nimbyGreen.png";
import PDFmimbyGreen from "../assets/pdfs/mimbyGreen.png";

import PDFyimbyGreenSvg from "../assets/pdfs/emojiYimby.svg";
import PDFnimbyGreenSvg from "../assets/pdfs/emojiNimby.svg";
import PDFmimbyGreenSvg from "../assets/pdfs/emojiMimby.svg";
import PDFLogoSvg from "../assets/pdfs/yimbyLogo.svg";
import PDFlive from "../assets/pdfs/live.png";
import PDFplay from "../assets/pdfs/play.png";
import PDFwork from "../assets/pdfs/work.png";
import PDFHouse from "../assets/pdfs/House.png";
import PDFShadow from "../assets/pdfs/Shadow.png";
import PDFyimbyGrey from "../assets/pdfs/yimbyGrey.png";
import PDFsupport from "../assets/pdfs/PDFsupport.png";
import PDFcontact from "../assets/pdfs/PDFcontact.png";
import PDFnoContact from "../assets/pdfs/PDFnoContact.png";
import PDFvolunteer from "../assets/pdfs/PDFvolunteer.png";

export const ImageList = {
  loginImage,
  welcomeImage,
  signupImage,
  userImage,
  setNewPasswordImage,
  forgotPasswordImage,
  resetPasswordImage,
  sampleProject,
  sampleMap,
  sampleProjectFull,
  sampleUpload,
  themeDark,
  themeLight,
  defaultUser,
  loader,
};
export const IconList = {
  checkedIcon,
  checkIcon,
  visible,
  cross,
  settings,
  business,
  activeSettings,
  activeProjects,
  activeBusiness,
  arrowDown,
  arrowUp,
  notification,
  copy,
  copySelected,
  deleteIcon,
  deleteSelected,
  create,
  filter1,
  filter2,
  listView,
  gridView,
  gridViewSelected,
  chevronDown,
  chevronDownSelected,
  listViewSelected,
  filter1Selected,
  filter2Selected,
  projectSelected,
  cross,
  chevronLeft,
  edit,
  projectDetailArea,
  projectDetailParking,
  projectDetailPopulation,
  projectDetailType,
  upload,
  request,
  requestSelected,
  usersSelected,
  users,
  thumbsUp,
  thumbsUpColor,
  thumbsDown,
  thumbsDownColor,
  plus,
  download,
};
export const PDFList = {
  PDFHeaderLogo,
  PDFlive,
  PDFmimbyGreen,
  PDFnimbyGreen,
  PDFplay,
  PDFwork,
  PDFyimby,
  PDFyimbyGreen,
  PDFHouse,
  PDFShadow,
  PDFyimbyGrey,
  PDFsupport,
  PDFvolunteer,
  PDFcontact,
  PDFnoContact,
  PDFmimbyGreenSvg,
  PDFnimbyGreenSvg,
  PDFyimbyGreenSvg,
  PDFLogoSvg,
};
