import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_END_POINT, api, privateApi } from "../../../api/apiConfig";
import { decryptResponse } from "../../../utils/encryptionDecryption";
import { toaster } from "../../../utils/toast";

export const postSignup = createAsyncThunk(
  "signup",
  async ({ values, callback }) => {
    try {
      const response = await api.post(API_END_POINT.SIGNUP, values);
      callback();
      return response;
    } catch (err) {
      return decryptResponse(err.response.data.responseObject);
    }
  }
);

export const resendOtp = createAsyncThunk(
  "resend-otp",
  async ({ values, callback }) => {
    try {
      const response = await api.post(API_END_POINT.RESEND_OTP, values);
      toaster("success", "OTP Sent Successfully");
      callback();
      return response;
    } catch (err) {
      toaster("error", "Unable to resent OTP");
      return decryptResponse(err.response.data.responseObject);
    }
  }
);

export const postConfirmOtp = createAsyncThunk(
  "confirm-otp",
  async ({ values, callback }) => {
    try {
      const response = await api.post(API_END_POINT.CONFIRM_OTP, values);
      callback();
      return response;
    } catch (err) {
      return decryptResponse(err.response.data.responseObject);
    }
  }
);

export const postLogin = createAsyncThunk(
  "login",
  async ({ values, callback }) => {
    try {
      const response = await api.post(API_END_POINT.LOGIN, values);
      callback();
      return response;
    } catch (err) {
      return decryptResponse(err.response.data.responseObject);
    }
  }
);

export const setDeviceToken = createAsyncThunk(
  "set-device-token",
  async ({ values }) => {
    try {
      const response = await privateApi.post(
        API_END_POINT.SET_DEVICE_TOKEN,
        values
      );
      return response;
    } catch (err) {
      return decryptResponse(err.response.data.responseObject);
    }
  }
);
export const postForgotPassword1 = createAsyncThunk(
  "forgot-password-1",
  async ({ values, callback }) => {
    try {
      const response = await api.post(API_END_POINT.FORGOT_PASSWORD_1, values);
      if (response?.data?.status) {
        callback();
      }
      return response;
    } catch (err) {
      return decryptResponse(err.response.data.responseObject);
    }
  }
);
export const postForgotPassword2 = createAsyncThunk(
  "forgot-password-2",
  async ({ values, callback }) => {
    try {
      const response = await api.post(API_END_POINT.FORGOT_PASSWORD_2, values);
      if (response?.data?.status) {
        callback();
      }
      return response;
    } catch (err) {
      return decryptResponse(err.response.data.responseObject);
    }
  }
);
