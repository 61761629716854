import { PreLoginHeader } from "../Common/Headers/PreLoginHeader";
import "./styles.scss";
const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <PreLoginHeader />
      <h1>Privacy Policy</h1>
      <div className="policy">
        <p>
          This privacy statement is applicable to Yimby Technologies LLC and all
          its affiliates.
        </p>
        <p>The policy is to respect and protect the privacy of our users.</p>
        <p>
          The Yimby Technologies LLC (hereafter referred to as "Company") does
          not collect personally identifiable information about individuals
          except when such individuals specifically provide such information on
          a voluntary basis. For example, such personally identifiable
          information may be gathered from a contest or sweepstakes
          registration, the registration process for subscription sites or
          services and in connection with content submissions, community
          postings (e.g., chat and message boards), suggestions, voting/polling
          activities and transactional areas.
        </p>

        <p>
          Personally identifiable information on individual users will not be
          sold or transferred to unaffiliated third parties without the user's
          approval at the time of collection. At such points of collection, the
          user will have the opportunity to indicate whether he or she would
          like to "opt out" of receiving promotional and/or marketing
          information about other products, services, and offerings from the
          Company and/or any third parties.
        </p>
        <p>
          While the Company does not anticipate many children accessing our
          website, we are committed to protecting the privacy of children who
          use our sites and applications. Our Company has established practices
          compliant with the U.S. Children’s Online Privacy Protection Act
          (“COPPA”) regarding children’s personal information.
        </p>
        <p>
          Further, notwithstanding any opt out of promotional information by the
          user, the Company reserves the right to contact a subscriber regarding
          account status, changes to the subscriber agreement and other matters
          relevant to the underlying service and/or the information collected.
        </p>
        <p>
          The Company may disclose user information in special cases when we
          have reason to believe that disclosing this information is necessary
          to identify, contact or bring legal action against someone who may be
          causing injury to or interference with (either intentionally or
          unintentionally) the Company’s rights or property, other Company
          users, or anyone else that could be harmed by such activities. The
          Company may disclose user information when we believe in good faith
          that the law requires it.
        </p>
        <p>
          The Company reserve the right to perform statistical analyses of user
          behavior and characteristics to measure interest in and use of the
          various areas of the site and to inform advertisers of such
          information as well as the number of users that have been exposed to
          or clicked on their advertising banners. The Company will provide only
          aggregated data from these analyses to third parties. Also, users
          should be aware that the Company may sometimes permit third parties to
          offer subscription and/or registration-based services through the
          Company’s site. The Company is not responsible for any actions or
          policies of such third parties and users should check the applicable
          privacy policy of such party when providing personally identifiable
          information.
        </p>
        <p>
          Additionally, users should be aware that when they voluntarily
          disclose personally identifiable information (e.g., user name, e-mail
          address) on the bulletin boards or in the chat areas of the Company’s
          sites, that information, along with any substantive information
          disclosed in the user's communication, can be collected and correlated
          and used by third parties and may result in unsolicited messages from
          other posters or third parties. Such activities are beyond the control
          of the Company.
        </p>
        <p>
          Users also should be aware that non-personal information and data may
          be automatically collected through the standard operation of the
          Company’s internet servers or "cookies." Cookies are small text files
          a web site can use to recognize repeat users, facilitate the user's
          ongoing access to and use of the site and allow a site to track usage
          behavior and compile aggregate data that will allow content
          improvements and targeted advertising. Cookies are not programs that
          come onto a user's system and damage files. Cookies work by assigning
          a unique number to the user that has no meaning outside the assigning
          site. Users should be aware that the Company cannot or does not
          control the use of cookies or the resulting information by advertisers
          or third parties hosting data for the Company. If a user does not want
          information collected through the use of cookies, there is a simple
          procedure in most browsers that allows the user to deny or accept the
          cookie feature; however, users should note that cookies may be
          necessary to provide the user with certain features (e.g., customized
          delivery of information) available on the Company’s site.{" "}
        </p>
        <p>
          Upon request, the Company will allow any user to "opt out" of further
          promotional contacts at any time. Additionally, upon request, the
          Company will use reasonable efforts to allow users to update/correct
          personal information previously submitted which the user states are
          erroneous to the extent such activities will not compromise privacy or
          security interests. Also, upon a user's request, the Company will use
          commercially reasonable efforts to functionally delete the user and
          his or her personal information from its database; however, it may be
          impossible to delete a user's entry without some residual information
          because of backups and records of deletions.{" "}
        </p>
        <p>
          The foregoing policies are effective as of 02/02/2024. The Company
          reserves the right to change this policy at any time by notifying
          users of the existence of a new privacy statement. This statement and
          the policies outlined herein are not intended to and do not create any
          contractual or other legal rights in or on behalf of any party.{" "}
        </p>
        <p>
          By using this site, you signify your assent to the Company’s Privacy
          Policy. If you do not agree to this policy, please do not use our
          sites. Your continued use of the Company’s sites following the posting
          of changes to these terms will mean you accept those changes.{" "}
        </p>
        <p>
          If you have questions or concerns regarding this Web site's privacy
          statement, contact the Company. margaret@urbanconversions.com.{" "}
        </p>
        <p>
          As our Company is headquartered in the United States, we adhere most
          closely with applicable federal and state laws. We do, however, value
          our non-US users. The Company has implemented policies to adhere to
          the European Union’s General Data Protection Regulation (“GDPR”) which
          includes strict data protection principles that organizations must
          follow to protect the personal information they collect about their
          clients or people who visit their websites. While many rules and
          actions may be the same in the US and EU, there may be specific
          instances of policy differences. If you are concerned about how your
          personal information is being collected in connection with GDPR,
          please use this contact information and reach out to the Company.
          margaret@urbanconversions.com
        </p>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
