import "./styles.scss";
import { Button, Col, Modal, Row } from "antd";
import { IconList } from "../../../constants/imageList";

const ConfirmationPopup = ({ open, onCancel, onClick, type }) => {
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      width={300}
      footer={[
        <Row justify={"space-evenly"}>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={onClick} type="primary" className={`${type}`}>
            {type === "approve" ? "Approve" : "Reject"}
          </Button>
        </Row>,
      ]}
    >
      <div className="confirmation">
        <img
          src={
            type === "approve"
              ? IconList.thumbsUpColor
              : IconList.thumbsDownColor
          }
        />
        <h4>Are you sure you want to {type} these request</h4>
      </div>
    </Modal>
  );
};
export default ConfirmationPopup;
