import { Button, Col, Input, Row } from "antd";
import "./styles.scss";
import React, { useEffect, useState } from "react";
import {
  BellOutlined,
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../../appConfig/redux/reducer/setting.reducer";
import { ImageList } from "../../../../constants/imageList";
import { useLocation } from "react-router";
import { getMyProject } from "../../../../appConfig/redux/reducer/project.reducer";
import { getMyCards } from "../../../../appConfig/redux/reducer/card.reducer";
import { getReportList } from "../../../../appConfig/redux/reducer/report.reducer";
import { getUserList } from "../../../../appConfig/redux/reducer/userManagement.reducer";

import Notifications from "../../../Notifications";
import { getNotification } from "../../../../appConfig/redux/reducer/notification.reducer";
import { settingActions } from "../../../../appConfig/redux/slice/setting.slice";

const useReactPath = () => {
  const [path, setPath] = React.useState(window.location.pathname);
  const listenToPopstate = () => {
    const winPath = window.location.pathname;
    setPath(winPath);
  };
  React.useEffect(() => {
    window.addEventListener("popstate", listenToPopstate);
    return () => {
      window.removeEventListener("popstate", listenToPopstate);
    };
  }, []);
  return path;
};

const PostLoginHeader = () => {
  const dispatch = useDispatch();
  const [searchField, setSearchField] = useState("Project");
  const [search, setSearch] = useState("");
  const { userProfile, isSideNavHidden } = useSelector(
    (state) => state.setting
  );
  const notificationList = useSelector(
    (state) => state.notification.notification
  );
  const [notViewCount, setNotViewCount] = useState(0);

  const { userData } = useSelector((state) => state.auth);
  const [notification, setNotification] = useState(false);
  const path = useReactPath();
  const location = useLocation();
  const onSearch = () => {
    if (searchField === "Project") {
      dispatch(getMyProject({ params: { filter: search } }));
    } else if (searchField === "Card") {
      dispatch(getMyCards({ params: { filter: search } }));
    } else if (searchField === "Report") {
      dispatch(getReportList({ params: { filter: search } }));
    } else if (searchField === "Message") {
      dispatch(getMyProject({ params: { filter: search } }));
    } else if (searchField === "User") {
      dispatch(getUserList({ params: { filter: search, type: "All" } }));
    } else if (searchField === "Request") {
      dispatch({ params: { filter: search, type: "All" } });
    }

    setSearch("");
  };
  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(getNotification());
  }, []);
  useEffect(() => {
    if (notificationList?.length > 0) {
      let count = 0;
      setNotViewCount(0);
      notificationList.forEach((element) => {
        if (element?.isViewed === 0) {
          count += 1;
        }
      });
      setNotViewCount(count);
    } else {
      setNotViewCount(0);
    }
  }, [notificationList]);
  useEffect(() => {
    console.log(location.pathname);
    if (location.pathname.includes("project")) {
      setSearchField("Project");
    } else if (location.pathname.includes("card")) {
      setSearchField("Card");
    } else if (location.pathname.includes("report")) {
      setSearchField("Report");
    } else if (location.pathname.includes("user")) {
      setSearchField("User");
    } else if (location.pathname.includes("message")) {
      setSearchField("Message");
    } else if (location.pathname.includes("setting")) {
      setSearchField("Setting");
    } else if (location.pathname.includes("request")) {
      setSearchField("Request");
    } else if (location.pathname.includes("dashboard")) {
      setSearchField("Dashboard");
    }
  }, [location.pathname]);
  return (
    <div className="post-login-header-container header-sticky">
      <div className="search-notification-container">
        <Row>
          <Row align={"middle"}>
            <Col style={{ marginRight: 10, fontSize: 22 }}>
              {!isSideNavHidden ? (
                <MenuUnfoldOutlined
                  onClick={() => {
                    dispatch(settingActions.setIsSideNavHidden(true));
                  }}
                />
              ) : (
                <MenuFoldOutlined
                  onClick={() => {
                    dispatch(settingActions.setIsSideNavHidden(false));
                  }}
                />
              )}
            </Col>

            {searchField !== "Setting" && searchField !== "Dashboard" ? (
              <>
                <Col>
                  <Input
                    className="search-bar"
                    placeholder={`Search ${searchField}`}
                    prefix={<SearchOutlined />}
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </Col>
                <Col style={{ marginLeft: 10 }}>
                  <Button onClick={onSearch}>Search</Button>
                </Col>
              </>
            ) : (
              <div />
            )}
          </Row>
        </Row>
        <div style={{ position: "relative" }}>
          <BellOutlined
            style={{ fontSize: 24, marginTop: 3 }}
            onClick={() => {
              setNotification(!notification);
            }}
          />
          {notViewCount > 0 && (
            <p
              style={{
                position: "absolute",
                fontSize: 10,

                backgroundColor: "red",
                borderRadius: 100,
                height: 15,
                width: 15,
                color: "white",
                top: -10,
                right: -5,
              }}
            >
              {notViewCount > 9 ? "9+" : notViewCount}
            </p>
          )}
          <Notifications
            isOpen={notification}
            onClose={() => setNotification(false)}
          />
        </div>
      </div>
      <div className="profile-container">
        <span className="seperator" />
        <img
          src={
            !userProfile?.profilePhoto ||
            userProfile?.profilePhoto === "" ||
            userProfile?.profilePhoto?.includes("undefined")
              ? ImageList.defaultUser
              : userProfile?.profilePhoto
          }
        />
        <div className="name-email-container">
          <p>{`${userProfile?.firstName} ${userProfile?.surName}`}</p>
          <span>{userProfile?.email}</span>
        </div>
      </div>
    </div>
  );
};
export default PostLoginHeader;
