import { ThemeProvider } from "@mui/styles";
import "./App.css";
import { DefaultRoute } from "./appConfig/routes";
import { DEFAULT_THEME } from "./appStyles/defaultTheme";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "./appConfig/redux/slice/auth.slice";
import messaging, { onMessageListner } from "./firebase";
import { getToken, onMessage } from "@firebase/messaging";
import { ConfigProvider } from "antd";
import { getNotification } from "./appConfig/redux/reducer/notification.reducer";
function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userData") ?? "{}");
    dispatch(authActions.setUserData(data));
    onMessage(messaging, (message) => {
      dispatch(getNotification());
      new Notification(message?.notification?.title, {
        body: message?.notification?.body,
      });
    });
  }, []);
  return (
    <ConfigProvider
      theme={{
        token: { colorPrimary: "#662d91" },
        components: {
          Table: {
            headerSortHoverBg: "#662d91",
            headerFilterHoverBg: "#9b9ea3",
            controlItemBgActive: "#9b9ea3",
            headerSortActiveBg: "#662d91",
          },
        },
      }}
    >
      <div className="App">
        <DefaultRoute />
      </div>
    </ConfigProvider>
  );
}

export default App;
