import { Button, Modal } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SCREEN_PATHS } from "../../constants/index";
import "./styles.scss";
import { PreLoginHeader } from "../Common/Headers/PreLoginHeader";
import { Col, Row } from "antd";
import { ImageList } from "../../constants/imageList";
import { useDispatch } from "react-redux";
import { authActions } from "../../appConfig/redux/slice/auth.slice";

const Welcome = () => {
  const navigate = useNavigate();
  const navigateLogin = () => {
    navigate(SCREEN_PATHS.login);
  };

  const navigateSignUp = () => {
    navigate(SCREEN_PATHS.signup);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData && userData?.token) {
      dispatch(authActions.setUserData(userData));
      navigate(SCREEN_PATHS.project);
    }
  }, []);
  return (
    <div className="container">
      <PreLoginHeader />
      <Row md className="main-container">
        <Col md={12} className="left-container">
          <h1>
            Connecting neighbors and real estate developers to enable successful
            projects suited to an area.
          </h1>
          <Row>
            <Col xs={4} className="login">
              <Button onClick={navigateLogin}>Login</Button>
            </Col>
            <Col xs={4} className="signup">
              <Button onClick={navigateSignUp}>Sign Up</Button>
            </Col>
          </Row>
        </Col>
        <Col md={12} className="right-container">
          <img src={ImageList.welcomeImage} />
        </Col>
      </Row>
    </div>
  );
};
export default Welcome;
