import React from "react";
import "./styles.scss";
import { Button, Form } from "antd";
import { IconList } from "../../../constants/imageList";
import { useDispatch, useSelector } from "react-redux";
import { projectActions } from "../../../appConfig/redux/slice/project.slice";
import { useNavigate } from "react-router-dom";
import { SCREEN_PATHS } from "../../../constants";
import { NEW_PROJECT_STEPS } from "../../../constants/enum";

const Heading = ({ form }) => {
  const step = useSelector((state) => state.project.step);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div className="heading-container">
      <div className="heading">
        <img
          src={IconList.chevronLeft}
          onClick={() => {
            navigate(SCREEN_PATHS.project);
            dispatch(projectActions.setNewProjectStep());
          }}
        />
        <h3>Edit project</h3>
      </div>
    </div>
  );
};

export default Heading;
