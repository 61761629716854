import React, { useState } from "react";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProject,
  getMyProject,
} from "../../../appConfig/redux/reducer/project.reducer";

const Heading = () => {
  return (
    <div className="heading-container">
      <h3>Reports</h3>
    </div>
  );
};

export default Heading;
