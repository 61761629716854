import { Button, Row } from "antd";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteAccount } from "../../../appConfig/redux/reducer/setting.reducer";
import { SCREEN_PATHS } from "../../../constants";
import { useState } from "react";
import DeletePopup from "../../Common/DeletePopup";

const DeleteAccount = ({ form }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDelete, setIsDelete] = useState(false);
  const onClick = () => {
    setIsDelete(true);
  };
  const onDelete = () => {
    dispatch(
      deleteAccount({
        callback: () => {
          setIsDelete(false);
          localStorage.clear();
          navigate(SCREEN_PATHS.welcome);
        },
      })
    );
  };
  return (
    <div className="delete-account-container">
      <h2>Delete Account</h2>
      <h4>Delete your account</h4>
      <p className="grey">
        When you delete your account, you lose access to all account services,
        and permanently delete your personal data. you can cancel the deletion
        within 14 days.
      </p>
      <p>Confirm that i want to delete my account.</p>
      <Row>
        <Button danger type="primary" onClick={onClick}>
          Delete Account
        </Button>

        <Button className="cancel">Cancel</Button>
      </Row>
      <DeletePopup
        open={isDelete}
        onCancel={() => setIsDelete(false)}
        onClick={onDelete}
      />
    </div>
  );
};
export default DeleteAccount;
