import { Col, Modal, Row } from "antd";
import "./styles.scss";
import { IconList } from "../../../constants/imageList";
import { useState } from "react";

const ViewRequest = ({ open, onCancel, requestType, data }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const benefits =
    typeof data?.projectDetails?.benefit === "string"
      ? JSON.parse(data?.projectDetails?.benefit)
      : data?.projectDetails?.benefit;
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={[]}
      width={"60%"}
      style={{ top: 20 }}
    >
      {open && (
        <>
          <h3>Request Detail</h3>
          {requestType?.includes("Project") ? (
            <div className="view-request-container">
              <Row>
                <Col md={12}>
                  <Row className="detail">
                    <h4>Project Name :</h4>
                    <p>{data?.projectDetails?.projectName}</p>
                  </Row>
                </Col>
                <Col md={12}>
                  <Row className="detail">
                    <h4>Project Type :</h4>
                    <p>{data?.projectDetails?.projectType}</p>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Row className="detail">
                    <h4>Project Address :</h4>
                    <p>{data?.projectDetails?.projectAddress}</p>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Row className="detail">
                    <h4>Building Height :</h4>
                    <p>{data?.projectDetails?.buidlingHeight} </p>
                  </Row>
                </Col>
                <Col md={12}>
                  <Row className="detail">
                    <h4>Maximum Floors :</h4>
                    <p>{data?.projectDetails?.floorPlan}</p>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Row className="detail">
                    <h4>Maximum Capacity :</h4>
                    <p>{data?.projectDetails?.maximumCapacity}</p>
                  </Row>
                </Col>
                <Col md={12}>
                  <Row className="detail">
                    <h4>Property Size :</h4>
                    <p>{data?.projectDetails?.propertySize}</p>
                  </Row>
                </Col>
              </Row>
              {benefits && (
                <div className="detail">
                  <h4>Benefits :</h4>
                  {benefits?.map((item) => (
                    <p>{item}</p>
                  ))}
                </div>
              )}
              {data?.projectImages.length > 0 && (
                <h4 className="detail">Images</h4>
              )}
              {data?.projectImages.length > 0 && (
                <Row align={"middle"} className="detail">
                  <img
                    src={IconList.chevronLeft}
                    onClick={() => {
                      setCurrentIndex(
                        currentIndex === 0
                          ? data?.projectImages.length - 1
                          : currentIndex - 1
                      );
                    }}
                  />
                  <img
                    src={data?.projectImages[currentIndex]}
                    style={{ maxWidth: 400, maxHeight: 400 }}
                  />
                  <img
                    className="revert"
                    src={IconList.chevronLeft}
                    alt=""
                    onClick={() => {
                      setCurrentIndex(
                        currentIndex + 1 === data?.projectImages.length
                          ? 0
                          : currentIndex + 1
                      );
                    }}
                  />
                </Row>
              )}

              {(data?.cardDetails?.length > 0 ||
                data?.defaultCardDetails?.length > 0) && (
                <h4 className="detail">Cards :</h4>
              )}
              {data?.cardDetails?.length > 0 && (
                <Row wrap="wrap" className="card-container">
                  {data?.cardDetails?.map((card, index) => (
                    <Col md={6} key={index}>
                      <div className="card">
                        <p>{card.createdOn}</p>
                        <h3>{card.cardTitle}</h3>
                      </div>
                    </Col>
                  ))}
                </Row>
              )}
              {data?.defaultCardDetails?.length > 0 && (
                <Row wrap="wrap" className="card-container">
                  {data?.defaultCardDetails?.map((card, index) => (
                    <Col md={6} key={index}>
                      <div className="card">
                        <p>{card.createdOn}</p>
                        <h3>{card.cardTitle}</h3>
                      </div>
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          ) : (
            <div className="view-request-container">
              <Row>
                <Col md={12}>
                  <Row className="detail">
                    <h4>Name :</h4>
                    <p>
                      {data?.firstName} {data?.surName}
                    </p>
                  </Row>
                </Col>
                <Col md={12}>
                  <Row className="detail">
                    <h4>Email :</h4>
                    <p>{data?.email}</p>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Row className="detail">
                    <h4>Mobile Number :</h4>
                    <p>{data?.mobile}</p>
                  </Row>
                </Col>
                <Col md={12}>
                  <Row className="detail">
                    <h4>Organisation Name :</h4>
                    <p>{data?.organizationName}</p>
                  </Row>
                </Col>
              </Row>
            </div>
          )}
        </>
      )}
    </Modal>
  );
};
export default ViewRequest;
