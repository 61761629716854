import { createSlice } from "@reduxjs/toolkit";
import { NEW_PROJECT_STEPS } from "../../../constants/enum";
import {
  createProject,
  getMyProject,
  updateProject,
} from "../reducer/project.reducer";
import { toaster } from "../../../utils/toast";

const initialState = {
  isListView: true,
  loading: false,
  isShowingReview: false,
  step: NEW_PROJECT_STEPS.step1,
  isFilterOpen: false,
  step1Data: {},
  step2Data: {},
  step3Data: {},
  projectList: [],
  projectReview: [],
  selectedProject: [],
  projectDetail: {},
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setIsListView: (state, { payload }) => {
      state.isListView = payload;
    },
    setIsShowingReview: (state, { payload }) => {
      state.isShowingReview = payload;
    },
    setSelectedProject: (state, { payload }) => {
      state.selectedProject = payload;
    },
    setProjectDetail: (state, { payload }) => {
      state.projectDetail = payload;
    },
    setStep1Data: (state, { payload }) => {
      state.step1Data = payload;
    },
    setStep2Data: (state, { payload }) => {
      state.step2Data = payload;
    },
    setStep3Data: (state, { payload }) => {
      state.step3Data = payload;
    },
    setIsFilterOpen: (state, { payload }) => {
      state.isFilterOpen = payload;
    },
    setNewProjectStep: (state, { payload }) => {
      state.step = payload;
    },
    logout: (state) => {
      state = {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMyProject.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMyProject.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload?.status == false) {
        toaster("error", payload.message);
      } else {
        if (payload?.data?.projectList) {
          state.projectList = payload?.data?.projectList?.map((item, index) => {
            return {
              ...item,
              key: index,
              requestType: "Saved Project",
              address: `${item?.projectAddress}`,
            };
          });
        } else {
          state.projectList = [];
        }
        if (payload?.data?.projectReview) {
          state.projectReview = payload?.data?.projectReview.map(
            (item, index) => {
              return {
                projectName: item?.changedData?.projectDetails?.projectName,
                address: `${item?.changedData?.projectDetails?.projectAddress}`,
                projectAddress:
                  item?.changedData?.projectDetails?.projectAddress,
                state: item?.changedData?.projectDetails?.state,
                city: item?.changedData?.projectDetails?.city,
                projectType: item?.changedData?.projectDetails?.projectType,
                zipCode: item?.changedData?.projectDetails?.zipCode,
                projectStatus: item?.status,
                key: index,
                latitude: item?.changedData?.projectDetails?.latitude,
                longitude: item?.changedData?.projectDetails?.longitude,
                buidlingHeight:
                  item?.changedData?.projectDetails?.buidlingHeight,
                propertySize: item?.changedData?.projectDetails?.propertySize,
                floorPlan: item?.changedData?.projectDetails?.floorPlan,
                residentialUnits:
                  item?.changedData?.projectDetails?.residentialUnits,
                maximumCapacity:
                  item?.changedData?.projectDetails?.maximumCapacity,
                projectId: item?.changedData?.id,
                images: item?.changedData?.projectImages,
                cardDetails: item?.changedData?.cardDetails,
                requestType: item?.requestType,
                defaultCardDetails: item?.changedData?.defaultCardDetails,
              };
            }
          );
        } else {
          state.projectReview = [];
        }
      }
    });
    builder.addCase(getMyProject.rejected, (state, { payload }) => {
      state.loading = false;
    });

    builder.addCase(createProject.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createProject.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload?.status == false) {
        toaster("error", payload.message);
      } else {
      }
    });
    builder.addCase(createProject.rejected, (state, { payload }) => {
      state.loading = false;
    });

    builder.addCase(updateProject.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateProject.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload?.status == false) {
        toaster("error", payload.message);
      } else {
      }
    });
    builder.addCase(updateProject.rejected, (state, { payload }) => {
      state.loading = false;
    });
  },
});
export const projectActions = projectSlice.actions;
export default projectSlice.reducer;
