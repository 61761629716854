import { Col, Form, Row } from "antd";
import Heading from "./Heading";
import SideNav from "./SideNav";
import Profile from "./Profile";
import { useSelector } from "react-redux";
import Appearance from "./Appearance";

import "./styles.scss";
import Password from "./Password";
import DeleteAccount from "./DeleteAccount";
import NotificationSetting from "./Notification";

const CurrentPage = ({ form }) => {
  const { selectedOption } = useSelector((state) => state.setting);

  return (
    <div>
      {selectedOption === "Profile" && <Profile form={form} />}
      {/* {selectedOption === "Appearance" && <Appearance form={form} />} */}
      {selectedOption === "Notification" && <NotificationSetting form={form} />}
      {selectedOption === "Password" && <Password form={form} />}
      {selectedOption === "Delete Account" && <DeleteAccount form={form} />}
    </div>
  );
};

const Setting = () => {
  const [form] = Form.useForm();
  return (
    <div className="dashboard-container">
      <Heading form={form} />
      <Row md>
        <Col md={4}>
          <SideNav />
        </Col>
        <Col md={1} />
        <Col md={19}>
          <CurrentPage form={form} />
        </Col>
      </Row>
    </div>
  );
};
export default Setting;
