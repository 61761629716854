import "./styles.scss";
import Heading from "./Heading";
import { Modal, Row, Table } from "antd";
import { IconList } from "../../constants/imageList";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getUserList,
  suspendUser,
  unSuspendUser,
} from "../../appConfig/redux/reducer/userManagement.reducer";
import {
  EyeOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons/lib/icons";
import { DeleteOutlined } from "@ant-design/icons";
import { userManagementActions } from "../../appConfig/redux/slice/userManagement.slice";
import { useNavigate } from "react-router-dom";
import { SCREEN_PATHS } from "../../constants";
import moment from "moment";
const UserManagement = () => {
  const navigate = useNavigate();
  const [isPopup, setIsPopup] = useState(false);
  const [user, setUser] = useState();
  const [isSuspended, setIsSuspended] = useState();
  const columns = [
    {
      title: "User name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      },
      render: (text) => {
        return <p>{text}</p>;
      },
    },

    {
      title: "Email address",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => {
        return a.email.localeCompare(b.email);
      },
      render: (text) => {
        return <p className="email">{text}</p>;
      },
    },
    {
      title: "User type",
      dataIndex: "userType",
      key: "userType",
      filters: [
        {
          text: "Developer",
          value: "developer",
        },
        {
          text: "Neighbour",
          value: "neighbour",
        },
        {
          text: "All",
          value: "all",
        },
      ],
      onFilter: (value, record) => {
        if (value === "all") {
          return true;
        } else {
          return record.userType === value ? true : false;
        }
      },
      render: (text) => {
        return (
          <p className="address">
            {text?.length > 80 ? `${text.slice(0, 80)}...` : text}
          </p>
        );
      },
    },
    {
      title: "Joined date",
      dataIndex: "createdOn",
      key: "createdOn",
      sorter: (a, b) => {
        return moment(a.createdOn).unix() - moment(b.createdOn).unix();
      },
      render: (text) => {
        return <p>{text}</p>;
      },
    },
    {
      title: "Actions",
      dataIndex: "userId",
      key: "userId",
      render: (text) => {
        return (
          <Row justify={"space-around"}>
            <EyeOutlined
              style={{ fontSize: 20 }}
              onClick={() => {
                const user = userList.find((item) => item.userId == text);
                dispatch(userManagementActions.setSelectedUser(user));
                navigate(SCREEN_PATHS.userDetail);
              }}
            />
            {userList.find((item) => item.userId == text).isApproved ===
            "suspended" ? (
              <UserAddOutlined
                style={{ fontSize: 20 }}
                onClick={() => {
                  const user = userList.find((item) => item.userId == text);
                  console.log(user);
                  setUser(user);
                  setIsSuspended(true);
                  setIsPopup(true);
                }}
              />
            ) : (
              <UserDeleteOutlined
                style={{ fontSize: 20 }}
                onClick={() => {
                  const user = userList.find((item) => item.userId == text);
                  console.log(user);
                  setUser(user);
                  setIsSuspended(false);
                  setIsPopup(true);
                }}
              />
            )}
            {/* <DeleteOutlined style={{ fontSize: 20 }} /> */}
          </Row>
        );
      },
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // dispatch(requestListActions.setSelectedRequest(selectedRows));
    },
    type: "checkbox",
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getUserList({
        params: {
          type: "All",
        },
      })
    );
  }, []);
  const { userList } = useSelector((state) => state.userManagement);
  return (
    <div className="user-management-container">
      <Heading />
      <div className="table">
        <Table
          size="middle"
          // rowSelection={rowSelection}
          columns={columns}
          dataSource={userList ?? []}
        />
      </div>
      <Modal
        open={isPopup}
        onCancel={() => setIsPopup(false)}
        onOk={() => {
          isSuspended
            ? dispatch(
                unSuspendUser({
                  value: {
                    email: user.email,
                    userType:
                      user.userType === "Developer"
                        ? "re_developer"
                        : "neighbours",
                  },
                  callback: () => {
                    setIsPopup(false);
                    dispatch(
                      getUserList({
                        params: {
                          type: "All",
                        },
                      })
                    );
                  },
                })
              )
            : dispatch(
                suspendUser({
                  value: {
                    email: user.email,
                    userType:
                      user.userType === "Developer"
                        ? "re_developer"
                        : "neighbours",
                  },
                  callback: () => {
                    setIsPopup(false);
                    dispatch(
                      getUserList({
                        params: {
                          type: "All",
                        },
                      })
                    );
                  },
                })
              );
        }}
      >
        <p>
          Do you want to {isSuspended ? "approve" : "temporarily suspend"} the
          user?
        </p>
      </Modal>
    </div>
  );
};
export default UserManagement;
