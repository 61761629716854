import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_END_POINT, privateApi } from "../../../api/apiConfig";
import { toaster } from "../../../utils/toast";

export const getNotification = createAsyncThunk(
  "get-notification",
  async () => {
    try {
      const response = await privateApi.get(
        API_END_POINT.GET_NOTIFICATION_LIST
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);
export const setIsViewed = createAsyncThunk(
  "set-is-viewed",
  async ({ value, callback }) => {
    try {
      const response = await privateApi.post(
        API_END_POINT.SET_IS_VIEWED,
        value
      );
      callback();
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getProjectWithComments = createAsyncThunk(
  "get-project-with-comments",
  async () => {
    try {
      const response = await privateApi.get(
        API_END_POINT.GET_PROJECT_WITH_COMMENTS
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getCardWithComments = createAsyncThunk(
  "get-card-with-comments",
  async ({ params }) => {
    try {
      const response = await privateApi.get(
        API_END_POINT.GET_CARD_WITH_COMMENTS,
        { params }
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const showBeHeardComments = createAsyncThunk(
  "show-be-heard-comments",
  async ({ value }) => {
    try {
      const response = await privateApi.post(
        API_END_POINT.SHOW_BE_HEARD_COMMENT,
        value
      );
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const replyBeHeardComments = createAsyncThunk(
  "reply-be-heard-comments",
  async ({ value, callback }) => {
    try {
      const response = await privateApi.post(
        API_END_POINT.REPLY_BE_HEARD_COMMENT,
        value
      );
      callback();
      if (response.data.status) {
        toaster("success", "Reply sent successfully!");
      } else {
        toaster("error", "Something went wrong!");
      }
      return response;
    } catch (err) {
      return err;
    }
  }
);
