import React from "react";
import { ROUTES } from "./RouteConstants";
import { Route, Switch, Router, Routes, BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import DefaultLayout from "../../layout/DefaultLayout";
export const DefaultRoute = () => {
  return (
    <div>
      <BrowserRouter>
      <ToastContainer />
        <Routes>
          {ROUTES?.map((route) => (
            <Route
              key={route?.key}
              exact={route?.exact}
              path={route?.path}
              element={<route.component />}
            />
          ))}
          <Route path="*" element={<DefaultLayout />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};
