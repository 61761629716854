import React from "react";
import "./styles.scss";
import { Button, Form } from "antd";
import { useSelector } from "react-redux";

const Heading = ({ form }) => {
  const { selectedOption } = useSelector((state) => state.setting);
  return (
    <div className="heading-container">
      <h3>Account Settings</h3>
      <div className="options-container">
        {/* <Button>
          <span>
            <p>Cancel</p>
          </span>
        </Button> */}
        {(selectedOption === "Profile" || selectedOption === "Password") && (
          <Form form={form} name="basic">
            <Form.Item>
              <Button type="primary" htmlType="submit" className="primary">
                <span>
                  <p className="white">Save details</p>
                </span>
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
};

export default Heading;
