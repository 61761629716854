import { Button, Form, Row, Upload } from "antd";
import { IconList, ImageList } from "../../../constants/imageList";
import { DeleteOutlined } from "@ant-design/icons";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { projectActions } from "../../../appConfig/redux/slice/project.slice";
import { NEW_PROJECT_STEPS } from "../../../constants/enum";
import { useEffect, useState } from "react";
import { toaster } from "../../../utils/toast";
const Page2 = ({ form }) => {
  const [images, setImages] = useState([]);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const { projectDetail, step, step2Data } = useSelector(
    (state) => state.project
  );
  useEffect(() => {
    setImages(projectDetail?.images);
  }, [projectDetail]);

  useEffect(() => {
    if (step2Data?.length > 0) setImages(step2Data);
  }, []);
  async function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  }
  const onFinish = async (values) => {
    let arr = [];
    for (let i = 0; i < values?.images?.fileList.length; i++) {
      await getBase64(values?.images?.fileList[i].originFileObj) // `file` your img file
        .then((res) => {
          arr.push(res);
          console.log(res);
        }) // `res` base64 of img file
        .catch((err) => console.log(err));
    }
    console.log(arr);
    if (images.length + arr.length > 0) {
      dispatch(projectActions.setNewProjectStep(NEW_PROJECT_STEPS.step3));
      dispatch(projectActions.setStep2Data([...images, ...arr]));
    } else {
      setIsError(true);
    }
  };

  const onFinishFailed = (values) => {};
  return (
    <div className="page-2-container">
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        name="basic"
      >
        <div className="upload-container">
          <Form.Item name="images">
            <Upload
              accept=".jpg,.jpeg,.png,.svg,.mp4,.avi"
              maxCount={10}
              multiple={true}
              action={async (value) => {
                setIsError(false);
              }}
              showUploadList={true}
            >
              <div className="inner">
                <img src={IconList.upload} />
                <h3>
                  Drop your file here, or <a>Click to upload</a>
                </h3>

                <p>
                  File should be less than 5MB and only png, jpg, svg and jpeg
                  files formats are supported.
                </p>
              </div>
            </Upload>
          </Form.Item>
        </div>

        <div className="uploaded-container">
          {images.map((img, index) => (
            <div className="image">
              <img src={img} key={index} alt="" />
              <div className="delete">
                <DeleteOutlined
                  style={{ color: "#fff" }}
                  onClick={() => {
                    setImages([...images.filter((item, ind) => ind !== index)]);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
        {isError ? (
          <>
            <p style={{ color: "red" }}>Please select atleast one image.</p>
          </>
        ) : (
          <></>
        )}
        <h4 />
        <Row className="">
          <Button
            onClick={() => {
              dispatch(
                projectActions.setNewProjectStep(NEW_PROJECT_STEPS.step1)
              );
            }}
          >
            Back
          </Button>

          <Form.Item>
            <Button
              type={"primary"}
              className={"primary margin"}
              htmlType="submit"
            >
              {step === NEW_PROJECT_STEPS.step3 ? "Save details" : "Continue"}
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};
export default Page2;
