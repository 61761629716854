import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { SCREEN_PATHS } from "../../constants";
import "./styles.scss";
import { PreLoginHeader } from "../Common/Headers/PreLoginHeader";
import { Button, Col, Form, Input, Row } from "antd";
import { ImageList } from "../../constants/imageList";
import { useDispatch } from "react-redux";
import {
  postConfirmOtp,
  postSignup,
  resendOtp,
} from "../../appConfig/redux/reducer/auth.reducer";
import {
  emailPattern,
  namePattern,
  passwordPattern,
  phonePattern,
} from "../../utils/formValidations";

const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isOtpSend, setIsOtpSend] = useState();
  const [user, setUser] = useState({});
  const onResendOtp = () => {
    dispatch(resendOtp({ values: { email: user.email }, callback: () => {} }));
  };
  const onFinish = (values) => {
    setUser(values);
    dispatch(
      postSignup({
        values,
        callback: () => {
          setIsOtpSend(true);
        },
      })
    );
  };
  const onFinishFailed = (values) => {};

  const onFinishOtp = (values) => {
    setUser({ ...values, email: user.email });
    dispatch(
      postConfirmOtp({
        values: { otp: values.otp, email: user.email },
        callback: () => {
          navigate(SCREEN_PATHS.login);
        },
      })
    );
  };
  const onFinishFailedOtp = (values) => {};

  return (
    <div className="signup-container">
      <PreLoginHeader className="login" />
      <Row md className="main-container">
        <Col md={12} className="left-container">
          <img src={ImageList.signupImage} />
        </Col>
        {isOtpSend ? (
          <Col md={12} className="right-container">
            <h1>OTP Verification</h1>
            <h5>We've sent you a OTP to your email</h5>
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinishOtp}
              onFinishFailed={onFinishFailedOtp}
              className="form-container"
              autoComplete="off"
            >
              <p>OTP</p>
              <Form.Item
                name="otp"
                className="Field"
                rules={[
                  {
                    required: true,
                    message: "Please enter your OTP!",
                  },
                ]}
              >
                <Input placeholder="Enter your OTP here..." type="text" />
              </Form.Item>
              <p className="resend" onClick={onResendOtp}>
                Didn't received your mail? <a>Click to resend</a>
              </p>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginTop: 10 }}
                >
                  Verify OTP
                </Button>
              </Form.Item>
            </Form>
            <p className="sign-up">
              back to <a href={SCREEN_PATHS.login}>Login</a>
            </p>
          </Col>
        ) : (
          <Col md={12} className="right-container">
            <h1>Welcome to YIMBY</h1>
            <h5>Let’s get you signed up, just a few simple details first</h5>
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="form-container"
              autoComplete="off"
            >
              <Row md>
                <Col md={11}>
                  <p>First Name</p>
                  <Form.Item
                    name="firstName"
                    className="Field"
                    rules={[
                      {
                        required: true,
                        pattern: namePattern.value,
                        message: namePattern.message,
                      },
                    ]}
                  >
                    <Input placeholder="Enter first name" type="text" />
                  </Form.Item>
                </Col>
                <Col md={2}></Col>
                <Col md={11}>
                  <p>Last Name</p>
                  <Form.Item
                    name="surName"
                    className="Field"
                    rules={[
                      {
                        required: true,
                        pattern: namePattern.value,
                        message: namePattern.message,
                      },
                    ]}
                  >
                    <Input placeholder="Enter last name" type="text" />
                  </Form.Item>
                </Col>
              </Row>
              <p>Email</p>
              <Form.Item
                name="email"
                className="Field"
                rules={[
                  {
                    required: true,
                    pattern: emailPattern.value,
                    message: emailPattern.message,
                  },
                ]}
              >
                <Input placeholder="Enter email address here..." type="text" />
              </Form.Item>
              <p>Organization Name</p>
              <Form.Item
                name="organizationName"
                className="Field"
                rules={[
                  {
                    required: true,
                    message: "Please enter your organization name!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter organization name here..."
                  type="text"
                />
              </Form.Item>
              <p>Mobile no.</p>
              <Form.Item
                name="mobile"
                className="Field"
                rules={[
                  {
                    required: true,
                    pattern: phonePattern.value,
                    message: phonePattern.message,
                  },
                ]}
              >
                <Input placeholder="Enter mobile no. here..." type="text" />
              </Form.Item>
              <p>Password</p>
              <Form.Item
                name="password"
                className="Field"
                rules={[
                  {
                    required: true,
                    pattern: passwordPattern.value,
                    message: passwordPattern.message,
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter password here..."
                  type="password"
                />
              </Form.Item>
              <p>Confirm Password</p>
              <Form.Item
                name="confirmPassword"
                className="Field"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The new password that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Enter confirm password here..."
                  type="password"
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Sign up
                </Button>
              </Form.Item>
            </Form>
            <p className="sign-up">
              Back to <a href={SCREEN_PATHS.login}>Login</a>
            </p>
          </Col>
        )}
      </Row>
    </div>
  );
};
export default Signup;
