import { Col, Form, Row, Spin, Table } from "antd";
import "./styles.scss";
import React, { useState } from "react";
import SideNav from "../Common/SideNav";
import PostLoginHeader from "../Common/Headers/PostLoginHeader";
import { useNavigate } from "react-router-dom";
import Heading from "./Heading";
import SelectedStep from "./SelectedStep";
import Page1 from "./Page1/index";
import { NEW_PROJECT_STEPS } from "../../constants/enum";
import Page2 from "./Page2";
import Page3 from "./Page3";
import { useSelector } from "react-redux";
import { ImageList } from "../../constants/imageList";

const NewProject = () => {
  const [form] = Form.useForm();
  const { step, loading } = useSelector((state) => state.project);
  return (
    <div className="new-project-container">
      {loading ? (
        <Row justify={"center"}>
          <div>
            <img
              src={ImageList.loader}
              alt="Computer man"
              style={{ marginTop: 20 }}
            />
          </div>
        </Row>
      ) : (
        <>
          <Heading form={form} />
          <SelectedStep />
          {step == NEW_PROJECT_STEPS.step1 ? (
            <Page1 form={form} />
          ) : step === NEW_PROJECT_STEPS.step2 ? (
            <Page2 form={form} />
          ) : (
            <Page3 form={form} />
          )}
        </>
      )}
    </div>
  );
};

export default NewProject;
