export const emailPattern = {
  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i,
  message: "Please enter a valid email Id",
};

export const phonePattern = {
  value:
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
  message: "Please enter a valid mobile number",
};
export const namePattern = {
  value: /^[A-Za-z]\w*$/,
  message: "Please enter a valid name",
};

export const positiveNumberPattern = {
  value: /^[0-9]{1,6}$/,
  message: "Please enter a valid number",
};

export const passwordPattern = {
  value:
    /((?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\-_"=+{}; :,<.>?])).{8,}/,
  message:
    "Password must contain at least eight characters, including uppercase, lowercase, numbers and special character",
};

export const otpPattern = {
  value: /^[0-9]{6,6}$/,
  message: "Password should be of 6 digits",
};
