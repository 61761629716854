import { Button, Row } from "antd";
import "./styles.scss";
import { IconList } from "../../../../constants/imageList";
import { useNavigate } from "react-router-dom";
import { SCREEN_PATHS } from "../../../../constants";

const Heading = () => {
  const navigate = useNavigate();
  return (
    <Row justify={"space-between"} className="request-heading" align={"middle"}>
      <Row>
        <img
          src={IconList.chevronLeft}
          onClick={() => {
            navigate(-1);
          }}
        />
        <h3>User Profile</h3>
      </Row>
      <Row>
        <div className="options-container">
          {/* <Button>
            <span>
           
              <p>Block User</p>
            </span>
          </Button>
          <Button type={"primary"} className={"primary"}>
            <span>
           
              <p className="white">Delete User</p>
            </span>
          </Button> */}
        </div>
      </Row>
    </Row>
  );
};
export default Heading;
