import { Col, Empty, Row, Table, Tooltip } from "antd";
import "./styles.scss";
import React, { useEffect, useState } from "react";
import SideNav from "../Common/SideNav";
import PostLoginHeader from "../Common/Headers/PostLoginHeader";
import Heading from "./Heading";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import GridView from "./GridView";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SCREEN_PATHS } from "../../constants";
import { getMyProject } from "../../appConfig/redux/reducer/project.reducer";
import { projectActions } from "../../appConfig/redux/slice/project.slice";
import { NEW_PROJECT_STEPS } from "../../constants/enum";

const Project = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMyProject({ params: {} }));
  }, []);
  const { isListView, projectList, isShowingReview, projectReview } =
    useSelector((state) => state.project);

  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.auth);
  const columns = [
    {
      title: "Project Name",
      sorter: (a, b) => {
        return a.projectName.localeCompare(b.projectName);
      },
      dataIndex: "projectName",
      key: "projectName",

      render: (text) => {
        return <p>{text}</p>;
      },
    },

    {
      title: "Project Type",
      dataIndex: "projectType",
      key: "projectType",
      filters: [
        {
          text: "Residential",
          value: "residential",
        },
        {
          text: "Commercial",
          value: "commercial",
        },
        {
          text: "All",
          value: "all",
        },
      ],
      onFilter: (value, record) => {
        if (value === "all") {
          return true;
        } else {
          return record.projectType === value ? true : false;
        }
      },
      render: (text) => {
        return <p>{text}</p>;
      },
    },

    {
      title: "Type",
      dataIndex: "requestType",
      key: "requestType",
      onFilter: (value, record) => {
        if (value === "all") {
          return true;
        } else {
          return record.projectType === value ? true : false;
        }
      },
      render: (text) => {
        return <p>{text}</p>;
      },
    },

    {
      title: "Project Address",
      dataIndex: "address",
      key: "address",
      render: (text) => {
        return (
          <Tooltip title={text}>
            <p className="address">
              {text?.length > 20 ? `${text.slice(0, 20)}...` : text}
            </p>
          </Tooltip>
        );
      },
    },
    {
      title: "Project Status",
      dataIndex: "projectStatus",
      key: "projectStatus",
      filters: [
        {
          text: "Active",
          value: "active",
        },
        {
          text: "In Review",
          value: "draft",
        },
        {
          text: "All",
          value: "all",
        },
      ],
      onFilter: (value, record) => {
        if (value === "all") {
          return true;
        } else {
          return record.projectStatus === value ? true : false;
        }
      },
      render: (text) => {
        return (
          <p
            className={
              text == "draft" || text == "pending"
                ? "draft"
                : text == "active"
                ? "active"
                : text == "rejected"
                ? "reject"
                : "active"
            }
          >
            {text === "draft" ? "In Review" : text}
          </p>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "projectId",
      key: "projectId",
      render: (text) => {
        return (
          <Row justify={"space-around"}>
            <EyeOutlined
              style={{ fontSize: 20 }}
              onClick={() => {
                const project = isShowingReview
                  ? projectReview.find((item) => item.projectId == text)
                  : projectList.find((item) => item.projectId == text);
                navigate(SCREEN_PATHS.projectDetail);

                dispatch(projectActions.setProjectDetail(project));
              }}
            />
            {!isShowingReview &&
              projectList.find((item) => item.projectId == text)
                ?.projectStatus === "active" &&
              userData.userType === "re_developer" && (
                <EditOutlined
                  style={{ fontSize: 20 }}
                  onClick={() => {
                    const project = projectList.find(
                      (item) => item.projectId == text
                    );
                    navigate(SCREEN_PATHS.editProject);
                    dispatch(
                      projectActions.setNewProjectStep(NEW_PROJECT_STEPS.step1)
                    );
                    dispatch(projectActions.setProjectDetail(project));
                  }}
                />
              )}
          </Row>
        );
      },
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      dispatch(projectActions.setSelectedProject(selectedRows));
    },
    type: "checkbox",
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };
  console.log(projectReview);
  return (
    <div className="dashboard-container">
      <Heading />
      {isListView ? (
        <div className="table">
          {(isShowingReview && projectReview.length === 0) ||
          (!isShowingReview && projectList.length === 0) ? (
            <Empty description={<span>No Projects</span>} />
          ) : (
            <Table
              size="middle"
              rowSelection={rowSelection}
              columns={columns}
              dataSource={
                isShowingReview ? projectReview ?? [] : projectList ?? []
              }
            />
          )}
        </div>
      ) : (
        <GridView />
      )}
    </div>
  );
};

export default Project;
