import { createSlice } from "@reduxjs/toolkit";
import {
  getReportForAdmin,
  getReportForDev,
  getReportList,
} from "../reducer/report.reducer";

function toDataUrl(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
}

const initialState = { reportList: [], loading: false, pdf: null };

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    // setNewProjectStep: (state, { payload }) => {
    //   state.step = payload;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(getReportList.rejected, (state, { payload }) => {
      state.loading = false;
    });
    builder.addCase(getReportList.pending, (state, { payload }) => {
      state.loading = true;
    });
    builder.addCase(getReportList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.reportList = payload?.data?.data || [];
    });

    builder.addCase(getReportForDev.rejected, (state, { payload }) => {
      state.loading = false;
    });
    builder.addCase(getReportForDev.pending, (state, { payload }) => {
      state.loading = true;
    });
    builder.addCase(getReportForDev.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload?.data?.details) {
        state.pdf = payload?.data?.details;
      } else {
        state.pdf = {};
      }
    });
    builder.addCase(getReportForAdmin.rejected, (state, { payload }) => {
      state.loading = false;
    });
    builder.addCase(getReportForAdmin.pending, (state, { payload }) => {
      state.loading = true;
    });
    builder.addCase(getReportForAdmin.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload?.data?.details) {
        state.pdf = payload?.data?.details;
      } else {
        state.pdf = {};
      }
    });
  },
});
export const reportActions = reportSlice.actions;
export default reportSlice.reducer;
