import { createSlice } from "@reduxjs/toolkit";
import { getUserProfile } from "../reducer/setting.reducer";
import { getAllRequest, getRequestList } from "../reducer/requestList.reducer";
import moment from "moment/moment";
const initialState = {
  loading: false,
  requestList: [],
  isAllRequest: false,
  selectedRequest: [],
  allRequests: [],
};

const requestListSlice = createSlice({
  name: "request-list",
  initialState,
  reducers: {
    setSelectedRequest: (state, { payload }) => {
      state.selectedRequest = payload;
    },
    setIsAllRequest: (state, { payload }) => {
      state.isAllRequest = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRequestList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRequestList.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getRequestList.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.data?.details?.data) {
        state.requestList = payload.data?.details?.data.map((item, index) => {
          return {
            ...item,
            name: `${item?.firstName} ${item?.surName}`,
            createdAt: moment(item?.requestTime).format("MMM DD, YYYY HH:mm"),
          };
        });
      } else {
        state.requestList = [];
      }
    });

    builder.addCase(getAllRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getAllRequest.fulfilled, (state, { payload }) => {
      state.loading = false;
      console.log(payload.data);
      if (payload.data?.requests) {
        state.allRequests = payload.data?.requests.map((item, index) => {
          return {
            ...item,
            name: `${item?.firstName} ${item?.surName}`,
            createdAt: moment(item?.requestTime).format("MMM DD, YYYY HH:mm"),
          };
        });
      } else {
        state.allRequests = [];
      }
    });
  },
});
export const requestListActions = requestListSlice.actions;
export default requestListSlice.reducer;
