import { Button, Form, Input, Modal, Row, Switch, Upload } from "antd";
import {
  createCard,
  getMyCards,
} from "../../../appConfig/redux/reducer/card.reducer";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import "./styles.scss";
import { DeleteOutlined } from "@ant-design/icons";
import { toaster } from "../../../utils/toast";

const CreateCard = ({ setIsCreate, isCreate }) => {
  const [form] = Form.useForm();

  const [cardIcon, setCardIcon] = useState("");
  useEffect(() => {
    setCardIcon("");
    form.resetFields();
  }, []);

  const dispatch = useDispatch();
  const onCreate = (value) => {
    dispatch(
      createCard({
        values: {
          ...value,
          cardIcon,
        },
        callback: () => {
          form.resetFields();
          setIsCreate(false);
          setCardIcon("");
          dispatch(getMyCards({ params: {} }));
        },
      })
    );
  };

  return (
    <Modal
      open={isCreate}
      onCancel={() => {
        form.resetFields();
        setIsCreate(false);
        setCardIcon("");
      }}
      title="Create new card"
      footer={[
        <Form form={form} onFinish={onCreate}>
          <Row justify={"end"}>
            <Form.Item>
              <Button
                onClick={() => {
                  form.resetFields();
                  setIsCreate(false);
                  setCardIcon("");
                }}
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="primary margin"
              >
                Create
              </Button>
            </Form.Item>
          </Row>
        </Form>,
      ]}
    >
      <Form form={form} onFinish={onCreate}>
        <h4>Question</h4>
        <Form.Item
          name={"cardTitle"}
          rules={[{ required: true, message: "Enter Question" }]}
        >
          <Input placeholder="Enter question here" />
        </Form.Item>
        <h4>Description</h4>
        <Form.Item
          name={"cardDescription"}
          rules={[{ required: true, message: "Enter Description" }]}
        >
          <Input placeholder="Enter description here" />
        </Form.Item>
        <h4>Icon</h4>
        {cardIcon === "" ? (
          <Upload
            accept=".jpg,.jpeg,.png,.svg,.mp4,.avi"
            action={(value) => {
              if (value.size < 5000000) {
                let reader = new FileReader();
                reader.readAsDataURL(value);
                reader.onload = () => {
                  setCardIcon(reader.result);
                };
              } else {
                toaster("error", "File too large");
              }
            }}
            showUploadList={false}
          >
            <div className="upload">Upload Icon</div>
          </Upload>
        ) : (
          <div className="icon-container">
            <img src={cardIcon} className="icon" />
            <DeleteOutlined
              className="delete"
              style={{ color: "white" }}
              onClick={() => {
                setCardIcon("");
              }}
            />
          </div>
        )}
        <h4 />
        <h4 />
        <Row align={"start"} justify={"start"}>
          Required
          <Form.Item
            name={"isRequired"}
            initialValue={false}
            valuePropName="checked"
            rules={[{ required: true, message: "Enter Question" }]}
          >
            <Switch className="switch" />
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};
export default CreateCard;
