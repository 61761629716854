import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_END_POINT, privateApi } from "../../../api/apiConfig";
import { toaster } from "../../../utils/toast";

export const getUserProfile = createAsyncThunk("get-user-profile", async () => {
  try {
    const response = await privateApi.get(API_END_POINT.GET_USER_PROFILE);
    return response;
  } catch (err) {
    return err;
  }
});

export const editUserProfile = createAsyncThunk(
  "edit-user-profile",
  async ({ values, callback }) => {
    try {
      const response = await privateApi.post(
        API_END_POINT.EDIT_USER_PROFILE,
        values
      );

      if (response.data?.status) {
        callback();
      } else {
        toaster("error", response.data?.message);
      }
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const updateTheme = createAsyncThunk(
  "update-theme",
  async ({ values, callback }) => {
    try {
      const response = await privateApi.post(
        API_END_POINT.UPDATE_THEME,
        values
      );

      if (response.data?.status) {
        callback();
      } else {
        toaster("error", response.data?.message);
      }
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getState = createAsyncThunk("get-state", async () => {
  try {
    const response = await privateApi.get(API_END_POINT.GET_STATES);

    return response;
  } catch (err) {
    return err;
  }
});

export const getCities = createAsyncThunk("get-cities", async ({ values }) => {
  try {
    const response = await privateApi.post(API_END_POINT.GET_CITIES, values);
    return response;
  } catch (err) {
    return err;
  }
});

export const updateNotification = createAsyncThunk(
  "update-notification",
  async ({ values, callback }) => {
    try {
      const response = await privateApi.post(
        API_END_POINT.UPDATE_NOTIFICATION,
        values
      );

      if (response.data?.status) {
        callback();
      } else {
        toaster("error", response.data?.message);
      }
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const updatePassword = createAsyncThunk(
  "update-password",
  async ({ values, callback }) => {
    try {
      const response = await privateApi.post(
        API_END_POINT.UPDATE_PASSWORD,
        values
      );
      if (response.data?.status) {
        callback();
      } else {
        toaster("error", response.data?.message);
      }
      return response;
    } catch (err) {
      return err;
    }
  }
);

export const deleteAccount = createAsyncThunk(
  "update-password",
  async ({ callback }) => {
    try {
      const response = await privateApi.delete(API_END_POINT.DELETE_ACCOUNT);
      if (response.data?.status) {
        callback();
      } else {
        toaster("error", response.data?.message);
      }
      return response;
    } catch (err) {
      return err;
    }
  }
);
