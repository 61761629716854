import { useSelector } from "react-redux";
import "./styles.scss";
import { NEW_PROJECT_STEPS } from "../../../constants/enum";
const SelectedStep = () => {
  const step = useSelector((state) => state.project.step);
  return (
    <div className="selected-step-container">
      <div className="step">
        <p className="selected">1</p>
        <h5 className="selected-heading">Project Information</h5>
      </div>
      <div className="straight-line" />
      <div className="step">
        <p
          className={
            step === NEW_PROJECT_STEPS.step2 || step === NEW_PROJECT_STEPS.step3
              ? "selected"
              : "unselected"
          }
        >
          2
        </p>
        <h5
          className={
            step === NEW_PROJECT_STEPS.step2 || step === NEW_PROJECT_STEPS.step3
              ? "selected-heading"
              : "unselected-heading"
          }
        >
          Upload media
        </h5>
      </div>
      <div className="straight-line" />
      <div className="step">
        <p
          className={
            step === NEW_PROJECT_STEPS.step3 ? "selected" : "unselected"
          }
        >
          3
        </p>
        <h5
          className={
            step === NEW_PROJECT_STEPS.step3
              ? "selected-heading"
              : "unselected-heading"
          }
        >
          Add card
        </h5>
      </div>
    </div>
  );
};

export default SelectedStep;
