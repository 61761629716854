import { Col, Row } from "antd";
import "./styles.scss";
import Chart from "react-apexcharts";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboard,
  getProjectDashboard,
  getRequestDashboard,
  getUserDashboard,
} from "../../appConfig/redux/reducer/dashboard.reducer";
import { ImageList } from "../../constants/imageList";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.auth);
  const [projectDuration, setProjectDuration] = useState("All Time");
  const [userDuration, setUserDuration] = useState("All Time");
  const [requestDuration, setRequestDuration] = useState("All Time");
  const { dashboard, isLoading, project, request, user } = useSelector(
    (state) => state.dashboard
  );
  const filter = ["All Time", "1 year", "6 Months", "3 Months"];

  useEffect(() => {
    dispatch(getDashboard());
    dispatch(getProjectDashboard({ params: { duration: "All Time" } }));
    dispatch(getUserDashboard({ params: { duration: "All Time" } }));
    dispatch(getRequestDashboard({ params: { duration: "All Time" } }));
  }, []);
  if (isLoading) {
    return (
      <div>
        <img
          src={ImageList.loader}
          alt="Computer man"
          style={{ marginTop: 20 }}
        />
      </div>
    );
  }
  return (
    <div className="dashboard-container">
      {dashboard.active >= 0 && (
        <>
          <h2>Overview</h2>
          <Row style={{ textAlign: "center" }}>
            <Col md={7} className="primary box">
              <h3 className="white">
                Total{" "}
                {userData?.userType === "re_developer" ? "Projects" : "Users"}
              </h3>
              <h1 className="white">{dashboard?.total}</h1>
            </Col>
            <Col md={1} />
            <Col md={7} className="box">
              <h3 className="purple">
                Active{" "}
                {userData?.userType === "re_developer" ? "Projects" : "Users"}
              </h3>
              <h1 className="purple">{dashboard?.active}</h1>
            </Col>
            <Col md={1} />
            <Col md={7} className="box">
              <h3 className="purple">
                Pending{" "}
                {userData?.userType === "re_developer" ? "Projects" : "Users"}
              </h3>
              <h1 className="purple">{dashboard?.pending}</h1>
            </Col>
          </Row>
          <Row>
            <div
              className={userData.userType === "admin" ? "bar-graph" : "full"}
            >
              <Row justify={"space-between"}>
                <h3>Projects</h3>
                <div>
                  <Row className="filters">
                    {filter.map((item, key) => (
                      <Col
                        className={`filter ${
                          item === projectDuration ? "selected" : ""
                        }`}
                        onClick={() => {
                          setProjectDuration(item);
                          dispatch(
                            getProjectDashboard({ params: { duration: item } })
                          );
                        }}
                      >
                        <p>{item}</p>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Row>
              <Chart
                type="bar"
                height={250}
                series={[
                  {
                    name: "Projects Created",
                    data: project?.map((item) => item?.data),
                    color: "#662d91",
                  },
                ]}
                options={{
                  // colors: ["#662d91"],
                  fill: { colors: ["#662d91"] },
                  stroke: {
                    curve: "smooth",
                  },
                  xaxis: {
                    categories: project?.map((item) => item?.month),
                  },
                }}
              />
            </div>
            {userData?.userType === "admin" && (
              <div className="bar-graph">
                <Row justify={"space-between"}>
                  <h3>Developers</h3>
                  <div>
                    <Row className="filters">
                      {filter.map((item, key) => (
                        <Col
                          className={`filter ${
                            item === userDuration ? "selected" : ""
                          }`}
                          onClick={() => {
                            setUserDuration(item);
                            dispatch(
                              getUserDashboard({ params: { duration: item } })
                            );
                          }}
                        >
                          <p>{item}</p>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Row>
                <Chart
                  type="bar"
                  height={250}
                  series={[
                    {
                      name: "Users Gained",
                      data: user?.map((item) => item?.data),
                    },
                  ]}
                  options={{
                    colors: ["#662d91"],

                    stroke: {
                      curve: "smooth",
                    },
                    xaxis: {
                      categories: user?.map((item) => item?.month),
                    },
                  }}
                />
                {/* <Bar
                  data={{
                    labels: dashboard?.devGraph?.map((item) => item?.month),
                    datasets: [
                      {
                        label: "User Gained",
                        data: dashboard?.devGraph?.map(
                          (item) => item?.userCount
                        ),
                        backgroundColor: ["#662d91"],
                        borderRadius: 8,
                      },
                    ],
                  }}
                /> */}
              </div>
            )}
          </Row>
          <div className="line-graph">
            <Row justify={"space-between"}>
              <h3>
                Requests {userData.userType === "admin" ? " Recieved" : " Send"}
              </h3>
              <div>
                <Row className="filters">
                  {filter.map((item, key) => (
                    <Col
                      className={`filter ${
                        item === requestDuration ? "selected" : ""
                      }`}
                      onClick={() => {
                        setRequestDuration(item);
                        dispatch(
                          getRequestDashboard({ params: { duration: item } })
                        );
                      }}
                    >
                      <p>{item}</p>
                    </Col>
                  ))}
                </Row>
              </div>
            </Row>
            <Chart
              type="area"
              height={250}
              series={[
                {
                  name: "Requests Created",
                  data: request?.map((item) => item?.data),
                },
              ]}
              options={{
                colors: ["#662D91"],
                stroke: {
                  curve: "smooth",
                },
                fill: {
                  type: "gradient",
                  gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [0, 90, 100],
                  },
                },
                xaxis: {
                  categories: request?.map((item) => item?.month),
                },
              }}
            />
            {/* <Line
          data={{
            labels: [2016, 2017, 2018, 2019],

            datasets: [
              {
                label: "Projects Generated",
                data: [12, 13, 32, 12],
                backgroundColor: ["#662D91"],
                borderColor: "#662D91",
                borderRadius: 8,
              },
            ],
          }}
        /> */}
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
