import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_END_POINT, privateApi } from "../../../api/apiConfig";

export const getRequestList = createAsyncThunk("get-request-list", async () => {
  try {
    const response = await privateApi.get(API_END_POINT.GET_REQUEST_LIST);
    return response;
  } catch (err) {
    return err;
  }
});

export const getAllRequest = createAsyncThunk("get-all-request", async () => {
  try {
    const response = await privateApi.get(API_END_POINT.GET_ALL_REQUEST);
    return response;
  } catch (err) {
    return err;
  }
});
export const approveRequest = createAsyncThunk(
  "approve-request",
  async ({ value, callback }) => {
    try {
      const response = await privateApi.post(
        API_END_POINT.APPROVE_REQUEST,
        value
      );
      if (response?.data?.status) {
        callback();
      }
      return response;
    } catch (err) {
      return err;
    }
  }
);
export const rejectRequest = createAsyncThunk(
  "reject-request",
  async ({ value, callback }) => {
    try {
      const response = await privateApi.post(
        API_END_POINT.REJECT_REQUEST,
        value
      );
      if (response?.data?.status) {
        callback();
      }
      return response;
    } catch (err) {
      return err;
    }
  }
);
