import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";
import { settingActions } from "../../../appConfig/redux/slice/setting.slice";
const SideNav = () => {
  const dispatch = useDispatch();
  const { selectedOption } = useSelector((state) => state.setting);
  const list = [
    "Profile",
    // "Appearance",
    "Notification",
    "Password",
    "Delete Account",
  ];
  return (
    <div className="setting-side-nav-container">
      {list.map((item, key) => (
        <p
          onClick={() => dispatch(settingActions.setSelectedOption(item))}
          className={
            selectedOption === item
              ? "selected"
              : "Delete Account" === item
              ? "delete-account"
              : "unselected"
          }
          key={key}
        >
          {item}
        </p>
      ))}
    </div>
  );
};
export default SideNav;
