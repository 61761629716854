import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import { PreLoginHeader } from "../Common/Headers/PreLoginHeader";
import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import { ImageList } from "../../constants/imageList";
import { SCREEN_PATHS } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { passwordPattern } from "../../utils/formValidations";
import { postForgotPassword2 } from "../../appConfig/redux/reducer/auth.reducer";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const forgotPasswordData = useSelector(
    (state) => state.auth.forgotPasswordData
  );
  const onFinish = (values) => {
    dispatch(
      postForgotPassword2({
        values: {
          ...values,
          otp: forgotPasswordData?.otp,
          token: forgotPasswordData?.tempToken,
        },
        callback: () => {
          navigate(SCREEN_PATHS.login);
        },
      })
    );
  };
  const onFinishFailed = (values) => {};

  return (
    <div className="reset-container">
      <PreLoginHeader className="login" />
      <Row md className="main-container">
        <Col md={12} className="left-container">
          <img src={ImageList.resetPasswordImage} />
        </Col>
        <Col md={12} className="right-container">
          <h1>Set new password</h1>
          <h5>
            New password must be different from your previous used password.
          </h5>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="form-container"
            autoComplete="off"
          >
            <p>New password</p>
            <Form.Item
              name="password"
              className="Field"
              rules={[
                {
                  required: true,
                  pattern: passwordPattern.value,
                  message: passwordPattern.message,
                },
              ]}
            >
              <Input.Password
                placeholder="Enter new password here..."
                type="password"
              />
            </Form.Item>
            <p> Confirm password</p>
            <Form.Item
              name="confirmPassword"
              className="Field"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Enter new password here..."
                type="password"
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Reset password
              </Button>
            </Form.Item>
          </Form>
          <p className="sign-up">
            back to <a href={SCREEN_PATHS.login}>Login</a>
          </p>
        </Col>
      </Row>
    </div>
  );
};
export default ResetPassword;
