import { createSlice } from "@reduxjs/toolkit";
import { toaster } from "../../../utils/toast";
import {
  getDashboard,
  getProjectDashboard,
  getRequestDashboard,
  getUserDashboard,
} from "../reducer/dashboard.reducer";
import {
  getCardWithComments,
  getNotification,
  getProjectWithComments,
  showBeHeardComments,
} from "../reducer/notification.reducer";

const initialState = {
  isLoading: false,
  projectWithComments: [],
  cardWithComments: [],
  comments: [],
  replies: [],
  notification: [],
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNotification.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getNotification.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload.status === false) {
        toaster("error", payload.message);
        state.notification = [];
      } else {
        state.notification = payload?.data?.details?.data ?? [];
      }
    });

    builder.addCase(getProjectWithComments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProjectWithComments.fulfilled, (state, { payload }) => {
      state.isLoading = false;

      if (payload.status === false) {
        toaster("error", payload.message);
      } else {
        state.projectWithComments = payload?.data?.result;
        state.cardWithComments = [];
        state.comments = [];
      }
    });

    builder.addCase(getCardWithComments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCardWithComments.fulfilled, (state, { payload }) => {
      state.isLoading = false;

      if (payload.status === false) {
        toaster("error", payload.message);
      } else {
        state.cardWithComments = payload?.data?.result;
        //
        // state.notification = payload?.data?.details?.data;
      }
    });

    builder.addCase(showBeHeardComments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(showBeHeardComments.fulfilled, (state, { payload }) => {
      state.isLoading = false;

      if (payload.status === false) {
        toaster("error", payload.message);
      } else {
        state.comments = payload?.data?.cardComments;
        state.replies = payload?.data?.commentReplies;
      }
    });
  },
});
export const notificationActions = notificationSlice.actions;
export default notificationSlice.reducer;
