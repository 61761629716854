import { createSlice } from "@reduxjs/toolkit";
import { toaster } from "../../../utils/toast";
import {
  getDashboard,
  getProjectDashboard,
  getRequestDashboard,
  getUserDashboard,
} from "../reducer/dashboard.reducer";

const initialState = {
  isLoading: false,
  dashboard: {},
  project: [],
  request: [],
  user: [],
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    logout: (state) => {
      state.dashboard = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDashboard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDashboard.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getDashboard.fulfilled, (state, { payload }) => {
      state.isLoading = false;

      if (payload.status === false) {
        toaster("error", payload.message);
      } else {
        state.dashboard = payload?.data;
      }
    });

    builder.addCase(getProjectDashboard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProjectDashboard.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getProjectDashboard.fulfilled, (state, { payload }) => {
      state.isLoading = false;

      if (payload.status === false) {
        toaster("error", payload.message);
      } else {
        state.project = payload?.data?.projects;
      }
    });

    builder.addCase(getRequestDashboard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getRequestDashboard.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getRequestDashboard.fulfilled, (state, { payload }) => {
      state.isLoading = false;

      if (payload.status === false) {
        toaster("error", payload.message);
      } else {
        state.request = payload.data.request;
      }
    });

    builder.addCase(getUserDashboard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserDashboard.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getUserDashboard.fulfilled, (state, { payload }) => {
      state.isLoading = false;

      if (payload.status === false) {
        toaster("error", payload.message);
      } else {
        state.user = payload?.data?.users;
      }
    });
  },
});
export const dashboardActions = dashboardSlice.actions;
export default dashboardSlice.reducer;
