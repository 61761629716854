import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";
import { Button, Row, Col, Form } from "antd";
import { projectActions } from "../../../appConfig/redux/slice/project.slice";
import { updateProject } from "../../../appConfig/redux/reducer/project.reducer";
import { useNavigate } from "react-router-dom";
import { SCREEN_PATHS } from "../../../constants";
import { useEffect, useState } from "react";
import {
  getDefaultCards,
  getMyCards,
  getProjectCards,
} from "../../../appConfig/redux/reducer/card.reducer";
import CreateCard from "../../Common/CreateCard";
import { NEW_PROJECT_STEPS } from "../../../constants/enum";
import { toaster } from "../../../utils/toast";
const Page3 = ({ form }) => {
  const [isCreate, setIsCreate] = useState(false);
  const [allDefault, setAllDefault] = useState([]);
  const [selectedDefault, setSelectedDefault] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getMyCards({ params: {} }));
    dispatch(getDefaultCards({ params: {} }));
    dispatch(getProjectCards(projectDetail?.projectId));
  }, []);
  const { step1Data, step2Data, step, projectDetail } = useSelector(
    (state) => state.project
  );
  const { cardData, projectCards, defaultCards } = useSelector(
    (state) => state.card
  );
  useEffect(() => {
    let remainingCards = [];
    let remainingDefaultCards = [];
    // remainingCards = cardData;
    if (
      projectCards ||
      projectCards.length > 0 ||
      cardData ||
      cardData.length > 0 ||
      defaultCards ||
      defaultCards.length > 0
    ) {
      let flag = false;
      for (let j = 0; j < cardData?.length; j++) {
        for (let i = 0; i < projectCards?.length; i++) {
          if (cardData[j]?.id == projectCards[i]?.id) {
            flag = true;
          }
        }
        if (!flag) {
          remainingCards.push(cardData[j]);
        } else {
          flag = false;
        }
      }
      flag = false;
      for (let j = 0; j < defaultCards?.length; j++) {
        for (let i = 0; i < projectCards?.length; i++) {
          if (defaultCards[j]?.staticCardId == projectCards[i]?.staticCardId) {
            flag = true;
          }
        }
        if (!flag) {
          remainingDefaultCards.push(defaultCards[j]);
        } else {
          flag = false;
        }
      }
      setAll(remainingCards);
      setAllDefault(remainingDefaultCards);
      setSelected(projectCards.filter((item) => item?.id));
      setSelectedDefault(projectCards.filter((item) => item?.staticCardId));
    } else {
      setAll(cardData);
      setAllDefault(defaultCards);
    }
  }, [cardData, projectCards, defaultCards]);
  const onFinish = (values) => {
    dispatch(projectActions.setStep3Data(values));
    dispatch(
      updateProject({
        values: {
          status: "active",
          id: projectDetail?.projectId,
          projectDetails: step1Data,
          projectImages: step2Data ?? [],
          cardDetails: selected ?? [],
          defaultCardDetails: selectedDefault ?? [],
        },
        callback: () => {
          toaster("success", "Project submitted for review");
          navigate(SCREEN_PATHS.project);
        },
      })
    );
  };

  const onFinishFailed = (values) => {};

  const [selected, setSelected] = useState([]);
  const [all, setAll] = useState([]);

  return (
    <div className="page-3-container">
      {(selected?.length > 0 || selectedDefault?.length > 0) && (
        <h4>Selected survey card</h4>
      )}
      <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Row wrap="wrap">
          {selected?.map((card, index) => (
            <Col md={6} key={index}>
              <div className="card">
                <p>{card.createdOn}</p>
                <h3>{card.cardTitle}</h3>
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    setAll([...all, card]);
                    setSelected([
                      ...selected.filter((item, intIndex) => index != intIndex),
                    ]);
                  }}
                >
                  Remove
                </Button>
              </div>
            </Col>
          ))}
          {selectedDefault?.map((card, index) => (
            <Col md={6} key={index}>
              <div className="card">
                <p>{card.createdOn}</p>
                <h3>{card.cardTitle}</h3>
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    setAllDefault([...allDefault, card]);
                    setSelectedDefault([
                      ...selectedDefault.filter(
                        (item, intIndex) => index != intIndex
                      ),
                    ]);
                  }}
                >
                  Remove
                </Button>
              </div>
            </Col>
          ))}
        </Row>
        <Row justify={"space-between"}>
          {all?.length > 0 && <h4>All survey card</h4>}
          {/* <Button
            type="primary"
            className="primary"
            onClick={() => {
              setIsCreate(true);
            }}
          >
            Create
          </Button> */}
        </Row>
        <Row wrap="wrap">
          {all?.map((card, index) => (
            <Col md={6} key={index}>
              <div className="card">
                <p>{card.createdOn}</p>
                <h3>{card.cardTitle}</h3>

                <Button
                  type="primary"
                  className="primary"
                  onClick={() => {
                    setSelected([...selected, card]);
                    setAll([...all.filter((item, ind) => ind !== index)]);
                  }}
                >
                  Add card
                </Button>
              </div>
            </Col>
          ))}
        </Row>
        {allDefault?.length > 0 && (
          <Row justify={"space-between"}>
            {<h4>All default card</h4>}
            <Button
              type="primary"
              className="primary"
              onClick={() => {
                setIsCreate(true);
              }}
            >
              Create
            </Button>
          </Row>
        )}
        <Row wrap="wrap">
          {allDefault?.map((card, index) => (
            <Col md={6} key={index}>
              <div className="card">
                <p>{card.createdOn}</p>
                <h3>{card.cardTitle}</h3>
                <Button
                  type="primary"
                  className="primary"
                  onClick={() => {
                    setSelectedDefault([...selectedDefault, card]);
                    setAllDefault([
                      ...allDefault.filter((item, ind) => ind !== index),
                    ]);
                  }}
                >
                  Add card
                </Button>
              </div>
            </Col>
          ))}
        </Row>

        <Row>
          <Button
            onClick={() => {
              dispatch(
                projectActions.setNewProjectStep(NEW_PROJECT_STEPS.step2)
              );
            }}
          >
            Back
          </Button>

          <Form.Item>
            <Button
              type={"primary"}
              className={"primary margin"}
              htmlType="submit"
              disabled={selected.length === 0 && selectedDefault.length === 0}
            >
              {step === NEW_PROJECT_STEPS.step3 ? "Save details" : "Continue"}
            </Button>
          </Form.Item>
        </Row>
      </Form>
      <CreateCard setIsCreate={setIsCreate} isCreate={isCreate} />
    </div>
  );
};
export default Page3;
