import { createTheme } from "@mui/material/styles";

export const DEFAULT_THEME = createTheme({
  palette: {
    primary: {
      //blue-----------
      main: "#3369FF",
      dark: "#1F3F99",
      mid: "#85A5FF",
      light: "#C2D2FF",
    },
    secondary: {
      //green-----------
      main: "#03BFB0",
      dark: "#009085",
      mid: "#66E2D8",
      light: "#CCF5F2",
    },
    white: {
      main: "#FFFFFF",
    },
    black: {
      main: "#000000",
    },
    positive: {
      //green----------
      dark: "#83C372",
      light: "#91D97F",
    },
    causion: {
      //orange---------
      dark: "#E5994E",
      light: "#FFAA57",
    },
    danger: {
      //red-------------
      dark: "#E54E4E",
      light: "#FF6666",
    },
  },
  typography: {
    fontFamily: "Nexa",
  },
  textColor: {
    primary: {
      black: "#141414",
      white: "#FFFFFF",
    },
    secondary: {
      //grey------------
      darker: "#272829",
      dark: "#616366",
      base: "#9B9EA3",
      mid: "#D2D5D9",
      light: "#EDEFF2",
      lighter: "#FAFBFC",
    },
  },
  typography: {
    fontFamily: "Josefin Sans",
  },
  font: "Josefin Sans",
  fontSize: {
    fh1: "44px",
    fh2: "34px",
    fh3: "28px",
    fh4: "24px",
    fh5: "20px",
    fh6: "18px",
  },
  lineHeight: {
    lh1: "66px",
    lh2: "51px",
    lh3: "36px",
    lh4: "33px",
    lh5: "28px",
    lh6: "21px",
  },
  fontWeight: {
    bolder: 800,
    bold: 600,
    normal: 400,
    light: 300,
  },
  button: {
    height: "55px",
  },
  shadow: {
    lighter: "0px 2px 8px rgba(39, 40, 41, 0.12)",
    light: "0px 4px 16px rgba(39, 40, 41, 0.12)",
    mid: "0px 6px 24px rgba(39, 40, 41, 0.12)",
    dark: "0px 8px 32px rgba(39, 40, 41, 0.12)",
    darker: "0px 12px 40px rgba(39, 40, 41, 0.12)",
  },
  border: {
    px1: "1px",
    px2: "2px",
    px3: "3px",
    px4: "4px",
    px5: "5px",
    px6: "6px",
  },
  winDimention: {
    height: "100vh",
    width: "100vw",
  },
});
