import { EditOutlined, EyeFilled, EyeOutlined } from "@ant-design/icons";
import { Row, Spin, Table } from "antd";
import Heading from "./Heading";
import "./styles.scss";
import { IconList, ImageList } from "../../constants/imageList";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  approveRequest,
  getAllRequest,
  getRequestList,
  rejectRequest,
} from "../../appConfig/redux/reducer/requestList.reducer";
import ConfirmationPopup from "./ConfirmationPopup";
import { requestListActions } from "../../appConfig/redux/slice/requestList.slice";
import ViewRequest from "./ViewRequest";
const RequestList = () => {
  const { loading, requestList, isAllRequest, allRequests } = useSelector(
    (state) => state.requestList
  );

  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState("");
  const [requestType, setRequestType] = useState("");
  const [projectId, setProjectId] = useState("");
  const [viewData, setViewData] = useState({});
  const [isView, setIsView] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRequestList());
    dispatch(getAllRequest());
  }, []);
  const onApprove = () => {
    dispatch(
      approveRequest({
        value: { requestId: projectId, requestType },
        callback: () => {
          setIsOpen(false);
          dispatch(getRequestList());
        },
      })
    );
  };
  const onReject = () => {
    dispatch(
      rejectRequest({
        value: { requestId: projectId, requestType },
        callback: () => {
          setIsOpen(false);
          dispatch(getRequestList());
        },
      })
    );
  };
  const columns = isAllRequest
    ? [
        {
          title: "Request From",
          dataIndex: "name",
          key: "name",
          sorter: (a, b) => {
            return a.name.localeCompare(b.name);
          },
          render: (text) => {
            return <p>{text}</p>;
          },
        },

        {
          title: "Request Type",
          dataIndex: "requestType",
          key: "requestType",
          filters: [
            {
              text: "New Project",
              value: "New Project",
            },
            {
              text: "New Developer",
              value: "New Developer",
            },
            {
              text: "Updated Project",
              value: "Updated Project",
            },
            {
              text: "All",
              value: "all",
            },
          ],
          onFilter: (value, record) => {
            if (value === "all") {
              return true;
            } else {
              return record.requestType === value ? true : false;
            }
          },
          render: (text) => {
            return <p>{text}</p>;
          },
        },
        {
          title: "Request Date",
          dataIndex: "createdAt",
          key: "createdAt",
          sorter: (a, b) => {
            return a.createdAt.localeCompare(b.createdAt);
          },
          render: (text) => {
            return <p>{text}</p>;
          },
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          sorter: (a, b) => {
            return a.status.localeCompare(b.status);
          },
          render: (text) => {
            return <p className={text}>{text}</p>;
          },
        },
        {
          title: "Actions",
          dataIndex: "requestId",
          key: "requestId",
          render: (text) => {
            return (
              <Row justify={"space-around"}>
                {isAllRequest ? (
                  <></>
                ) : (
                  <img
                    src={IconList.thumbsDownColor}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      const request = requestList.find(
                        (item) => item.requestId == text
                      );
                      setRequestType(request?.requestType);
                      setType("reject");
                      setProjectId(text);
                      setIsOpen(true);
                    }}
                  />
                )}
                <EyeOutlined
                  style={{ fontSize: 26, color: "#662d91" }}
                  onClick={() => {
                    const request = isAllRequest
                      ? allRequests.find((item) => item.requestId == text)
                      : requestList.find((item) => item.requestId == text);
                    setRequestType(request?.requestType);
                    setViewData(request?.changedData);
                    setIsView(true);
                  }}
                />
                {isAllRequest ? (
                  <></>
                ) : (
                  <img
                    src={IconList.thumbsUpColor}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      const request = requestList.find(
                        (item) => item.requestId == text
                      );
                      setRequestType(request?.requestType);

                      setType("approve");
                      setProjectId(text);
                      setIsOpen(true);
                    }}
                  />
                )}
              </Row>
            );
          },
        },
      ]
    : [
        {
          title: "Request From",
          dataIndex: "name",
          key: "name",
          sorter: (a, b) => {
            return a.name.localeCompare(b.name);
          },
          render: (text) => {
            return <p>{text}</p>;
          },
        },

        {
          title: "Request Type",
          dataIndex: "requestType",
          key: "requestType",
          filters: [
            {
              text: "New Project",
              value: "New Project",
            },
            {
              text: "New Developer",
              value: "New Developer",
            },
            {
              text: "Updated Project",
              value: "Updated Project",
            },
            {
              text: "All",
              value: "all",
            },
          ],
          onFilter: (value, record) => {
            if (value === "all") {
              return true;
            } else {
              return record.requestType === value ? true : false;
            }
          },
          render: (text) => {
            return <p>{text}</p>;
          },
        },
        {
          title: "Request Date",
          dataIndex: "createdAt",
          key: "createdAt",
          sorter: (a, b) => {
            return a.createdAt.localeCompare(b.createdAt);
          },
          render: (text) => {
            return <p>{text}</p>;
          },
        },
        {
          title: "Actions",
          dataIndex: "requestId",
          key: "requestId",
          render: (text) => {
            return (
              <Row justify={"space-around"}>
                {isAllRequest ? (
                  <></>
                ) : (
                  <img
                    src={IconList.thumbsDownColor}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      const request = requestList.find(
                        (item) => item.requestId == text
                      );
                      setRequestType(request?.requestType);
                      setType("reject");
                      setProjectId(text);
                      setIsOpen(true);
                    }}
                  />
                )}
                <EyeOutlined
                  style={{ fontSize: 26, color: "#662d91" }}
                  onClick={() => {
                    const request = isAllRequest
                      ? allRequests.find((item) => item.requestId == text)
                      : requestList.find((item) => item.requestId == text);
                    setRequestType(request?.requestType);
                    setViewData(request?.changedData);
                    setIsView(true);
                  }}
                />
                {isAllRequest ? (
                  <></>
                ) : (
                  <img
                    src={IconList.thumbsUpColor}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      const request = requestList.find(
                        (item) => item.requestId == text
                      );
                      setRequestType(request?.requestType);

                      setType("approve");
                      setProjectId(text);
                      setIsOpen(true);
                    }}
                  />
                )}
              </Row>
            );
          },
        },
      ];

  return (
    <div className="request-list-container">
      <Heading />
      {loading ? (
        <div>
          <img
            src={ImageList.loader}
            alt="Computer man"
            style={{ marginTop: 20 }}
          />
        </div>
      ) : (
        <div className="table">
          <Table
            size="middle"
            // rowSelection={rowSelection}
            columns={columns}
            dataSource={isAllRequest ? allRequests ?? [] : requestList ?? []}
          />
        </div>
      )}
      <ConfirmationPopup
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        type={type}
        onClick={type === "approve" ? onApprove : onReject}
      />
      <ViewRequest
        open={isView}
        requestType={requestType}
        data={viewData}
        onCancel={() => {
          setIsView(false);
        }}
      />
    </div>
  );
};
export default RequestList;
