import React from "react";
import "./styles.scss";
import { ImageList } from "../../../constants/imageList";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { SCREEN_PATHS } from "../../../constants";
import { useSelector } from "react-redux";

const GridView = () => {
  const navigate = useNavigate();
  const { isDefaultCard, defaultCards, cardData } = useSelector(
    (state) => state.card
  );

  return (
    <Row md className="card-view-container">
      {isDefaultCard
        ? defaultCards &&
          defaultCards?.map((item) => (
            <Col
              md={6}
              className="card-tile"
              // onClick={() => navigate(SCREEN_PATHS.projectDetail)}
            >
              <div className="card">
                <span>{item.createdOn}</span>
                <h4>{item.cardTitle}</h4>
                <Row justify={"center"}>
                  <p className="type">
                    {item?.isRequired === true ? "Mandatory" : "Optional"}
                  </p>
                  {/* <p className="option">{item.option}</p> */}
                </Row>
              </div>
            </Col>
          ))
        : cardData &&
          cardData?.map((item) => (
            <Col
              md={6}
              className="card-tile"
              // onClick={() => navigate(SCREEN_PATHS.projectDetail)}
            >
              <div className="card">
                <span>{item.createdOn}</span>
                <h4>{item.cardTitle}</h4>
                <Row justify={"center"}>
                  <p className="type">
                    {item.isRequired === 0 ? "Optional" : "Mandatory"}
                  </p>
                  {/* <p className="option">{item.option}</p> */}
                </Row>
              </div>
            </Col>
          ))}
    </Row>
  );
};
export default GridView;
