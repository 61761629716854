import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import { PDFList } from "../../constants/imageList";
import { styles } from "./styles";
import moment from "moment/moment";
const PDF = ({ pdf, userData }) => {
  return (
    <>
      {pdf && userData && (
        <Document>
          <Page size={"A4"} style={{ position: "relative" }}>
            <View
              style={{
                bottom: 2,
                left: 2,
                position: "absolute",
                display: "flex",
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Image
                src={PDFList.PDFyimbyGrey}
                style={{
                  ...styles.headerImage,
                  bottom: 2,
                  left: 2,
                  height: 20,
                  width: 40,
                }}
              />
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",

                  marginRight: 10,
                }}
              >
                <Text style={{ color: "#aaa", fontSize: 10, marginRight: 5 }}>
                  {pdf?.projectDetail?.projectName}
                </Text>
                <Text style={{ color: "#aaa", fontSize: 10 }}>
                  {pdf?.projectDetail?.projectAddress}
                </Text>
              </View>
            </View>

            <View style={styles.header}>
              <Image src={PDFList.PDFHeaderLogo} style={styles.headerImage} />
              <Text style={styles.headerHeading}>
                {userData?.userType == "re_developer"
                  ? "Developer Report"
                  : "Admin Report"}
              </Text>
            </View>
            <View style={styles.heading}>
              <Text style={styles.h1Heading}>
                What the neighbors think about the project
              </Text>
              <Image src={PDFList.PDFyimby} style={styles.headingImage} />
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Image
                src={PDFList.PDFHouse}
                style={{
                  height: 70,
                  width: 360,
                  marginLeft: 30,
                  marginTop: -15,
                  zIndex: -1,
                }}
              />
              <Image
                src={PDFList.PDFShadow}
                style={{
                  height: 100,
                  width: 100,
                  marginLeft: 8,
                }}
              />
            </View>
            <View style={styles.projectInfo}>
              <View style={styles.projectItem}>
                <Text style={styles.projectHeading}>Project Name :</Text>
                <Text style={styles.projectAnswer}>
                  {pdf?.projectDetail?.projectName}
                </Text>
                <Text style={styles.projectPara}>
                  {pdf?.projectDetail?.projectAddress}
                </Text>
              </View>
              <View style={styles.projectItem}>
                <Text style={styles.projectHeading}>Project Date :</Text>
                <Text style={styles.projectAnswer}>
                  {moment(pdf?.projectDetail?.createdAt).format("MM.DD.YY")}
                </Text>
              </View>
            </View>
            <View style={styles.neighborsAgree}>
              <Text style={styles.neighborsPercent}>
                {((pdf?.yimbyCount / pdf?.totalCount) * 100).toFixed(0)}%
              </Text>
              <Text style={styles.neighborsText}>
                Neighbors agree with this project
              </Text>
            </View>
            <View style={styles.yimbyType}>
              <View>
                {/* <Image
                  style={styles.map}
                  src={`https://maps.googleapis.com/maps/api/staticmap?center=${pdf?.projectDetail?.projectAddress}&zoom=15&size=375x360&maptype=roadmap
&markers=color:blue%7Clabel:PROJECT%7C${pdf?.projectDetail?.latitude},${pdf?.projectDetail?.longitude}&markers=color:green%7Clabel:G%7C40.711614,-74.012318
&markers=color:red%7Clabel:C%7C40.718217,-73.998284
&key=${process.env.REACT_APP_LOCATION_SERVICE}`}
                /> */}
                <Image
                  style={styles.map}
                  src={`https://maps.googleapis.com/maps/api/staticmap?center=${pdf?.projectDetail?.projectAddress}&zoom=15&size=375x360&maptype=roadmap
&markers=icon:https://yimby-cloud.s3.amazonaws.com/yimby-dev/yimbyLogo.png|${pdf?.projectDetail?.latitude},${pdf?.projectDetail?.longitude}&markers=color:green%7Clabel:G%7C40.711614,-74.012318
&markers=color:red%7Clabel:C%7C40.718217,-73.998284
&key=${process.env.REACT_APP_LOCATION_SERVICE}`}
                />
              </View>
              <View>
                <View style={styles.type}>
                  <Image
                    alt="yimby"
                    style={styles.typeImage}
                    src={PDFList.PDFyimbyGreen}
                  />
                  <Text style={styles.yimbyNumber}> {pdf?.yimbyCount} </Text>
                  <View>
                    <Text>identify as</Text>
                    <Text style={styles.yimby}>YIMBY</Text>
                  </View>
                </View>

                <View style={styles.type}>
                  <Image
                    alt="mimby"
                    style={styles.typeImage}
                    src={PDFList.PDFmimbyGreen}
                  />
                  <Text style={styles.mimbyNumber}> {pdf?.mimbyCount} </Text>
                  <View>
                    <Text>identify as</Text>
                    <Text style={styles.mimby}>MIMBY</Text>
                  </View>
                </View>
                <View style={styles.type}>
                  <Image
                    alt="mimby"
                    style={styles.typeImage}
                    src={PDFList.PDFnimbyGreen}
                  />
                  <Text style={styles.nimbyNumber}> {pdf?.nimbyCount} </Text>
                  <View>
                    <Text>identify as</Text>
                    <Text style={styles.nimby}>NIMBY</Text>
                  </View>
                </View>
                <View style={styles.type2}>
                  <Image style={styles.typeImage} src={PDFList.PDFlive} />
                  <View style={{ alignItems: "center" }}>
                    <Text style={styles.percent}>
                      {((pdf?.liveInArea / pdf?.totalCount) * 100).toFixed(0)}%
                    </Text>
                    <Text>Live in the area</Text>
                  </View>
                </View>
                <View style={styles.type3}>
                  <Image style={styles.typeImage} src={PDFList.PDFwork} />
                  <View style={{ alignItems: "center" }}>
                    <Text style={styles.percent}>
                      {((pdf?.workInArea / pdf?.totalCount) * 100).toFixed(0)}%
                    </Text>
                    <Text>Work in the area</Text>
                  </View>
                </View>
                <View style={styles.type4}>
                  <Image style={styles.typeImage} src={PDFList.PDFplay} />
                  <View style={{ alignItems: "center" }}>
                    <Text style={styles.percent}>
                      {((pdf?.playInArea / pdf?.totalCount) * 100).toFixed(0)}%
                    </Text>
                    <Text>Play in the area</Text>
                  </View>
                </View>
              </View>
            </View>
          </Page>
          <Page size={"A4"} style={{ position: "relative" }}>
            <View
              style={{
                position: "absolute",
                bottom: 35,
                left: 15,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {userData?.userType === "re_developer" ? (
                <>
                  <View
                    style={{
                      height: 20,
                      width: 20,
                      backgroundColor: "#1AA79C",
                    }}
                  />
                  <Text style={{ fontSize: 12, marginLeft: 5 }}>
                    Included a support letter
                  </Text>
                </>
              ) : (
                <>
                  <Image
                    src={PDFList.PDFsupport}
                    style={{ height: 15, width: 12, marginLeft: 5 }}
                  />
                  <Text style={{ fontSize: 12, marginLeft: 5 }}>Supporter</Text>
                  <Image
                    src={PDFList.PDFvolunteer}
                    style={{ height: 15, width: 12, marginLeft: 5 }}
                  />
                  <Text style={{ fontSize: 12, marginLeft: 5 }}>Volunteer</Text>
                  <Image
                    src={PDFList.PDFcontact}
                    style={{ height: 15, width: 12, marginLeft: 5 }}
                  />
                  <Text style={{ fontSize: 12, marginLeft: 5 }}>
                    OK to contact
                  </Text>
                  <Image
                    src={PDFList.PDFnoContact}
                    style={{ height: 15, width: 12, marginLeft: 5 }}
                  />
                  <Text style={{ fontSize: 12, marginLeft: 5 }}>
                    Not OK to contact
                  </Text>
                </>
              )}
            </View>
            <View
              style={{
                bottom: 2,
                left: 2,
                position: "absolute",
                display: "flex",
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Image
                src={PDFList.PDFyimbyGrey}
                style={{
                  ...styles.headerImage,
                  bottom: 2,
                  left: 2,
                  height: 20,
                  width: 40,
                }}
              />

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",

                  marginRight: 10,
                }}
              >
                <Text style={{ color: "#aaa", fontSize: 10, marginRight: 5 }}>
                  {pdf?.projectDetail?.projectName}
                </Text>
                <Text style={{ color: "#aaa", fontSize: 10 }}>
                  {pdf?.projectDetail?.projectAddress}
                </Text>
              </View>
            </View>
            <View style={styles.header}>
              <Image src={PDFList.PDFHeaderLogo} style={styles.headerImage} />
              <Text style={styles.headerHeading}>
                {userData?.userType == "re_developer"
                  ? "Developer Report"
                  : "Admin Report"}
              </Text>
            </View>
            <View style={styles.heading}>
              <Text style={styles.h1Heading}>
                Who are the neighbors of the project
              </Text>
              <Image src={PDFList.PDFyimby} style={styles.headingImage} />
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Image
                src={PDFList.PDFHouse}
                style={{
                  height: 70,
                  width: 360,
                  marginLeft: 30,
                  marginTop: -15,
                  zIndex: -1,
                }}
              />
              <Image
                src={PDFList.PDFShadow}
                style={{
                  height: 100,
                  width: 100,
                  marginLeft: 8,
                }}
              />
            </View>
            <View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  // marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    fontWeight: 700,
                    borderColor: "#555",

                    borderWidth: 1,
                    marginLeft: 15,
                    backgroundColor: "#662d91",
                    color: "white",
                    fontSize: 10,
                    textAlign: "center",
                    width: 140,
                  }}
                >
                  Name
                </Text>
                <Text
                  style={{
                    fontWeight: 700,
                    borderColor: "#555",
                    borderWidth: 1,
                    backgroundColor: "#662d91",
                    color: "white",
                    fontSize: 10,
                    textAlign: "center",
                    width: 240,
                  }}
                >
                  Address
                </Text>
                <Text
                  style={{
                    fontWeight: 700,
                    borderColor: "#555",
                    borderWidth: 1,
                    backgroundColor: "#662d91",
                    color: "white",
                    fontSize: 10,
                    textAlign: "center",
                    width: 140,
                  }}
                >
                  Email
                </Text>
                <Text
                  style={{
                    fontWeight: 700,
                    borderColor: "#555",
                    borderWidth: 1,
                    fontSize: 10,
                    backgroundColor: "#662d91",
                    color: "white",
                    textAlign: "center",
                    width: 140,
                  }}
                >
                  Distance
                </Text>
                {userData?.userType === "admin" && (
                  <Text
                    style={{
                      fontWeight: 700,
                      borderColor: "#555",
                      borderWidth: 1,
                      backgroundColor: "#662d91",
                      color: "white",
                      fontSize: 10,
                      textAlign: "center",
                      width: 140,
                    }}
                  >
                    Status
                  </Text>
                )}

                {userData?.userType == "admin" && (
                  <Text
                    style={{
                      fontWeight: 700,
                      borderColor: "#555",
                      borderWidth: 1,
                      backgroundColor: "#662d91",
                      color: "white",
                      fontSize: 10,
                      textAlign: "center",
                      width: 140,
                    }}
                  >
                    {"User type"}
                  </Text>
                )}
                <Text
                  style={{
                    fontWeight: 700,
                    borderColor: "#555",
                    borderWidth: 1,
                    backgroundColor: "#662d91",
                    color: "white",
                    fontSize: 10,
                    marginRight: 15,
                    textAlign: "center",
                    width: 140,
                  }}
                >
                  {"Relationship with neighborhood"}
                </Text>
              </View>
              {pdf?.data?.map((item) => (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      borderColor: "#555",
                      borderWidth: 1,
                      paddingVertical: 2,
                      marginLeft: 15,
                      width: 140,
                      textOverflow: "ellipsis",
                      fontSize: 8,
                      textAlign: "center",
                      color:
                        item?.isSupporter && userData.userType !== "admin"
                          ? "white"
                          : "black",
                      backgroundColor:
                        item?.isSupporter && userData.userType !== "admin"
                          ? "#1AA79C"
                          : "#fff",
                    }}
                  >
                    {item?.firstName} {item?.surName}
                  </Text>
                  <Text
                    style={{
                      borderColor: "#555",
                      borderWidth: 1,
                      paddingVertical: 2,
                      color:
                        item?.isSupporter && userData.userType !== "admin"
                          ? "white"
                          : "black",
                      backgroundColor:
                        item?.isSupporter && userData.userType !== "admin"
                          ? "#1AA79C"
                          : "#fff",
                      width: 240,
                      fontSize: 8,
                      textAlign: "center",
                    }}
                  >
                    {item.address}
                  </Text>
                  <Text
                    style={{
                      borderColor: "#555",
                      borderWidth: 1,
                      paddingVertical: 2,
                      color:
                        item?.isSupporter && userData.userType !== "admin"
                          ? "white"
                          : "black",
                      backgroundColor:
                        item?.isSupporter && userData.userType !== "admin"
                          ? "#1AA79C"
                          : "#fff",
                      width: 140,
                      fontSize: 8,
                      textAlign: "center",
                    }}
                  >
                    {item.email}
                  </Text>
                  <Text
                    style={{
                      borderColor: "#555",
                      borderWidth: 1,
                      paddingVertical: 2,
                      color:
                        item?.isSupporter && userData.userType !== "admin"
                          ? "white"
                          : "black",
                      backgroundColor:
                        item?.isSupporter && userData.userType !== "admin"
                          ? "#1AA79C"
                          : "#fff",
                      width: 140,
                      fontSize: 8,
                      textAlign: "center",
                    }}
                  >
                    {item.distance}
                  </Text>
                  {userData.userType === "admin" && (
                    <View
                      style={{
                        borderColor: "#555",
                        borderWidth: 1,
                        flexDirection: "row",
                        paddingVertical: 2,

                        color:
                          item?.isSupporter && userData.userType !== "admin"
                            ? "white"
                            : "black",
                        backgroundColor:
                          item?.isSupporter && userData.userType !== "admin"
                            ? "#1AA79C"
                            : "#fff",
                        width: 140,
                        fontSize: 8,
                        textAlign: "right",
                      }}
                    >
                      {item?.isSupporter ? (
                        <Image
                          src={PDFList.PDFsupport}
                          style={{ height: 15, width: 12 }}
                        />
                      ) : (
                        <></>
                      )}
                      {item?.isVolunteer ? (
                        <Image
                          src={PDFList.PDFvolunteer}
                          style={{ height: 15, width: 12 }}
                        />
                      ) : (
                        <></>
                      )}
                      {item?.isOk && item.NeighbourType !== "Yimby" && (
                        <Image
                          src={PDFList.PDFcontact}
                          style={{ height: 15, width: 12 }}
                        />
                      )}
                      {!item?.isOk && item.NeighbourType !== "Yimby" && (
                        <Image
                          src={PDFList.PDFnoContact}
                          style={{ height: 15, width: 12 }}
                        />
                      )}
                    </View>
                  )}
                  {userData.userType === "admin" && (
                    <Text
                      style={{
                        borderColor: "#555",
                        borderWidth: 1,
                        paddingVertical: 2,

                        color:
                          item?.isSupporter && userData.userType !== "admin"
                            ? "white"
                            : item.NeighbourType === "Yimby"
                            ? "#1aa79c"
                            : item.NeighbourType === "Mimby"
                            ? "#F26421"
                            : item.NeighbourType === "Nimby"
                            ? "#EE1D23"
                            : "black",
                        backgroundColor:
                          item?.isSupporter && userData.userType !== "admin"
                            ? "#1AA79C"
                            : "#fff",
                        fontSize: 10,
                        textAlign: "center",
                        width: 140,
                      }}
                    >
                      {item.NeighbourType}
                    </Text>
                  )}
                  <Text
                    style={{
                      borderColor: "#555",
                      borderWidth: 1,
                      paddingVertical: 2,
                      marginRight: 15,
                      color:
                        item?.isSupporter && userData.userType !== "admin"
                          ? "white"
                          : "black",
                      backgroundColor:
                        item?.isSupporter && userData.userType !== "admin"
                          ? "#1AA79C"
                          : "#fff",
                      fontSize: 8,

                      textAlign: "center",
                      width: 140,
                    }}
                  >
                    {item.relationship}
                  </Text>
                </View>
              ))}
            </View>
          </Page>
          <Page size={"A4"} style={{ position: "relative" }}>
            <View
              style={{
                bottom: 2,
                left: 2,
                position: "absolute",
                display: "flex",
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Image
                src={PDFList.PDFyimbyGrey}
                style={{
                  ...styles.headerImage,
                  bottom: 2,
                  left: 2,
                  height: 20,
                  width: 40,
                }}
              />
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",

                  marginRight: 10,
                }}
              >
                <Text style={{ color: "#aaa", fontSize: 10, marginRight: 5 }}>
                  {pdf?.projectDetail?.projectName}
                </Text>
                <Text style={{ color: "#aaa", fontSize: 10 }}>
                  {pdf?.projectDetail?.projectAddress}
                </Text>
              </View>
            </View>
            <View style={styles.header}>
              <Image src={PDFList.PDFHeaderLogo} style={styles.headerImage} />
              <Text style={styles.headerHeading}>
                {userData?.userType == "re_developer"
                  ? "Developer Report"
                  : "Admin Report"}
              </Text>
            </View>
            <View style={styles.heading}>
              <Text style={styles.h1Heading}>
                Why the neighbors like this project
              </Text>
              <Image src={PDFList.PDFyimby} style={styles.headingImage} />
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Image
                src={PDFList.PDFHouse}
                style={{
                  height: 70,
                  width: 360,
                  marginLeft: 30,
                  marginTop: -15,
                  zIndex: -1,
                }}
              />
              <Image
                src={PDFList.PDFShadow}
                style={{
                  height: 100,
                  width: 100,
                  marginLeft: 8,
                }}
              />
            </View>
            <View style={styles.cardDetail}>
              {pdf?.cardDetail?.map((item) => (
                <View style={styles.card}>
                  <Text style={styles.cardQuestion}>{item.title}</Text>
                  <Image source={{ uri: item?.icon }} style={styles.cardImg} />
                  <View>
                    <Text style={{ fontSize: 12 }}>
                      <Text style={styles.cardAgree}>
                        {item.total == 0
                          ? 0
                          : ((item.agree / item.total) * 100 ?? 0).toFixed(0) ??
                            0}
                        %
                      </Text>{" "}
                      Agree
                    </Text>
                    <Text style={{ marginTop: 3, fontSize: 12 }}>
                      <Text style={styles.cardDisagree}>
                        {item.total == 0
                          ? 0
                          : ((item.disagree / item.total) * 100 ?? 0).toFixed(
                              0
                            ) ?? 0}
                        %
                      </Text>{" "}
                      Disagree
                    </Text>
                    <Text style={{ marginTop: 3, fontSize: 12 }}>
                      <Text style={styles.cardNoAnswer}>
                        {item.total == 0
                          ? 0
                          : (item?.skip / item?.total) * 100 ??
                            (0).toFixed(0) ??
                            0}
                        %
                      </Text>{" "}
                      Did Not Answer
                    </Text>
                    {/* {{!-- <p><Text class="card-no-answer">{{percentage item.agree item.total}}% </Text>Did Not Answer</p> --}} */}
                  </View>
                </View>
              ))}
            </View>
          </Page>
          <Page size={"A4"} style={{ position: "relative" }}>
            <View
              style={{
                bottom: 2,
                left: 2,
                position: "absolute",
                display: "flex",
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Image
                src={PDFList.PDFyimbyGrey}
                style={{
                  ...styles.headerImage,
                  bottom: 2,
                  left: 2,
                  height: 20,
                  width: 40,
                }}
              />
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",

                  marginRight: 10,
                }}
              >
                <Text style={{ color: "#aaa", fontSize: 10, marginRight: 5 }}>
                  {pdf?.projectDetail?.projectName}
                </Text>
                <Text style={{ color: "#aaa", fontSize: 10 }}>
                  {pdf?.projectDetail?.projectAddress}
                </Text>
              </View>
            </View>
            <View style={styles.header}>
              <Image src={PDFList.PDFHeaderLogo} style={styles.headerImage} />
              <Text style={styles.headerHeading}>
                {userData?.userType == "re_developer"
                  ? "Developer Report"
                  : "Admin Report"}
              </Text>
            </View>
            <View style={styles.heading}>
              <Text style={styles.h1Heading}>Neighbor Comments</Text>
              <Image src={PDFList.PDFyimby} style={styles.headingImage} />
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Image
                src={PDFList.PDFHouse}
                style={{
                  height: 70,
                  width: 360,
                  marginLeft: 30,
                  marginTop: -15,
                  zIndex: -1,
                }}
              />
              <Image
                src={PDFList.PDFShadow}
                style={{
                  height: 100,
                  width: 100,
                  marginLeft: 8,
                }}
              />
            </View>
            <View style={styles.commentDetail}>
              {/* {{#each comments as |item|}} */}
              {pdf?.comments?.map((item) => (
                <View style={styles.comment}>
                  <View
                    style={{
                      position: "absolute",
                      height: 20,
                      width: 20,
                      left: 13,
                      bottom: 13,
                      backgroundColor: "#1AA79C",
                    }}
                  />
                  <Text style={styles.commentName}>{item.firstName}</Text>
                  <Text style={styles.commentComment}>{item.comment}</Text>
                </View>
              ))}
            </View>
          </Page>
        </Document>
      )}
    </>
  );
};
export default PDF;
