import React, { useState } from "react";
import "./styles.scss";
import { Button, Row, Switch } from "antd";
import { IconList } from "../../../constants/imageList";
import { useDispatch, useSelector } from "react-redux";
import { projectActions } from "../../../appConfig/redux/slice/project.slice";
import { useNavigate } from "react-router-dom";
import { SCREEN_PATHS } from "../../../constants";
import { NEW_PROJECT_STEPS } from "../../../constants/enum";
import {
  deleteProject,
  getMyProject,
} from "../../../appConfig/redux/reducer/project.reducer";
import DeletePopup from "../../Common/DeletePopup";

const Heading = () => {
  const { isListView, selectedProject, projectReview, isShowingReview } =
    useSelector((state) => state.project);
  const [isDelete, setIsDelete] = useState(false);
  const { userData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onClick = () => {
    selectedProject.forEach((element) => {
      dispatch(
        deleteProject({
          values: { projectId: element.projectId },
          callback: () => {
            setIsDelete(false);
            dispatch(projectActions.setSelectedProject([]));
            dispatch(getMyProject({ params: {} }));
          },
        })
      );
    });
  };
  const deleteProjects = () => {
    if (selectedProject.length > 0) {
      setIsDelete(true);
    }
  };
  return (
    <div className="heading-container">
      <h2>Projects List</h2>
      <Row className="pending">
        <p>Active Projects</p>
        <Switch
          checked={isShowingReview}
          onChange={(checked) => {
            dispatch(projectActions.setIsShowingReview(checked));
            console.log(checked);
          }}
        />
        <p>Pending Projects</p>
      </Row>
      <div className="options-container">
        {userData.userType === "re_developer" && (
          <>
            {!isShowingReview && (
              <Button
                onClick={deleteProjects}
                disabled={selectedProject.length === 0}
              >
                <span>
                  <img src={IconList.deleteIcon} />
                  <p>Delete</p>
                </span>
              </Button>
            )}
            <Button
              type="primary"
              onClick={() => {
                dispatch(
                  projectActions.setNewProjectStep(NEW_PROJECT_STEPS.step1)
                );
                navigate(SCREEN_PATHS.newProject);
              }}
            >
              <span>
                <img src={IconList.create} />
                <p className="white">Create</p>
              </span>
            </Button>
          </>
        )}

        <i />
        <Button
          type={isListView ? "primary" : "default"}
          className={isListView ? "primary" : ""}
          onClick={() => {
            dispatch(projectActions.setIsListView(true));
          }}
        >
          <span className="default">
            <img
              src={isListView ? IconList.listViewSelected : IconList.listView}
            />
          </span>
        </Button>
        <Button
          type={!isListView ? "primary" : "default"}
          className={!isListView ? "primary" : ""}
          onClick={() => {
            dispatch(projectActions.setIsListView(false));
          }}
        >
          <span className="default">
            <img
              src={!isListView ? IconList.gridViewSelected : IconList.gridView}
            />
          </span>
        </Button>
      </div>
      <DeletePopup
        open={isDelete}
        onClick={onClick}
        onCancel={() => {
          setIsDelete(false);
        }}
      />
    </div>
  );
};

export default Heading;
