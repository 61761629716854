import React from "react";
import "./styles.scss";
import { ImageList } from "../../../constants/imageList";
import { Checkbox, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { SCREEN_PATHS } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { projectActions } from "../../../appConfig/redux/slice/project.slice";

const GridView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projectList, isShowingReview, projectReview, selectedProject } =
    useSelector((state) => state.project);
  return (
    <Row md className="grid-view-container">
      {projectList?.length === 0 && <p className="no">No Projects Available</p>}
      {isShowingReview ? (
        <>
          {projectReview?.map((item) => (
            <Col
              md={6}
              className="project-tile"
              onClick={() => {
                navigate(SCREEN_PATHS.projectDetail);
                dispatch(projectActions.setProjectDetail(item));
              }}
            >
              {/* <div className="checkbox" /> */}
              <div className="card">
                {item?.images?.length > 0 ? (
                  <img src={item?.images[0]} alt="" />
                ) : (
                  <></>
                )}
                <Row justify={"space-between"}>
                  <h4>{item.projectName}</h4>
                  <span>{item?.projectType}</span>
                </Row>
                <p>{item.address}</p>
                <div className="status">
                  <p>{item?.projectStatus}</p>
                </div>
              </div>
            </Col>
          ))}
        </>
      ) : (
        <>
          {projectList?.map((item, key) => (
            <Col key={key} md={6} className="project-tile">
              <Checkbox
                className="checkbox"
                checked={
                  selectedProject?.length > 0 &&
                  selectedProject?.filter(
                    (pro) => pro.projectId === item.projectId
                  )?.length > 0
                }
                onClick={() => {
                  console.log(item);
                  console.log(selectedProject, item);
                  const exist =
                    selectedProject?.filter(
                      (pro) => pro.projectId === item.projectId
                    ).length > 0;
                  if (exist) {
                    dispatch(
                      projectActions.setSelectedProject([
                        ...selectedProject?.filter(
                          (pro) => pro.projectId !== item.projectId
                        ),
                      ])
                    );
                  } else {
                    if (selectedProject.length > 0) {
                      dispatch(
                        projectActions.setSelectedProject([
                          ...selectedProject,
                          item,
                        ])
                      );
                    } else {
                      dispatch(projectActions.setSelectedProject([item]));
                    }
                  }
                }}
              />

              <div
                className="card"
                onClick={() => {
                  navigate(SCREEN_PATHS.projectDetail);
                  dispatch(projectActions.setProjectDetail(item));
                }}
              >
                <img src={item?.images[0]} alt="" />
                <Row justify={"space-between"}>
                  <h4>{item.projectName}</h4>
                  <span>{item?.projectType}</span>
                </Row>
                <p>{item.address}</p>
                <div className="status">
                  <p>{item?.projectStatus}</p>
                </div>
              </div>
            </Col>
          ))}
        </>
      )}
    </Row>
  );
};
export default GridView;
