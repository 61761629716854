import React from "react";
import "./styles.scss";

import { IconList } from "../../../constants/imageList";
import { useDispatch } from "react-redux";
import { projectActions } from "../../../appConfig/redux/slice/project.slice";
import { useNavigate } from "react-router-dom";
import { SCREEN_PATHS } from "../../../constants";

const Heading = ({ form }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div className="heading-container">
      <div className="heading">
        <img
          src={IconList.chevronLeft}
          onClick={() => {
            navigate(SCREEN_PATHS.project);
            dispatch(projectActions.setNewProjectStep());
          }}
        />
        <h3>Create new project</h3>
      </div>
    </div>
  );
};

export default Heading;
