import axios from "axios";
import {
  decryptResponse,
  encryptGetRequest,
  encryptRequest,
} from "../utils/encryptionDecryption";

const api = axios.create({
  // baseURL: "http://localhost:4000/dev/",
  baseURL: "https://www.yimbyapp.com/dev/",
  timeout: 200000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
  },
});

api.interceptors.request.use((req) => {
  req.data = encryptRequest(req.data);
  return req;
});
api.interceptors.response.use((res) => {
  res.data = decryptResponse(res.data.responseObject);
  return res;
});

const privateApi = axios.create({
  // baseURL: "http://localhost:4000/dev/",
  baseURL: "https://www.yimbyapp.com/dev/",
  timeout: 200000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
  },
});

privateApi.interceptors.request.use((req) => {
  const { token } = JSON.parse(localStorage.getItem("userData") ?? "{}");
  req.headers["Authorization"] = `Bearer ${token}`;
  if (req.method == "get") {
    if (req.params) {
      req.params = encryptGetRequest(req.params);
    }
  } else {
    req.data = encryptRequest(req?.data);
  }
  return req;
});
privateApi.interceptors.response.use(
  (res) => {
    res.data = decryptResponse(res?.data.responseObject);
    return res;
  },
  (err) => {
    const error = decryptResponse(err?.response?.data.responseObject);
    console.log(error);
    if (error?.details?.isTokenInvalid || error?.details?.tokenExpired) {
      localStorage.removeItem("userData");
    }
    return { data: error };
  }
);
const API_END_POINT = {
  LOGIN: "login",
  SET_DEVICE_TOKEN: "setDeviceToken",
  LOG_OUT: "logout",
  RESEND_OTP: "resendOtp",
  SIGNUP: "real-estate-developer-signup",
  CONFIRM_OTP: "confirm-otp",
  FORGOT_PASSWORD_1: "forgotPasswordStep1",
  FORGOT_PASSWORD_2: "forgotPasswordStep2",
  DELETE_ACCOUNT: "deleteAccount",
  GET_MY_PROJECT: "getMyProject",
  GET_REPORT_LIST: "reportList",
  GET_REPORT_FOR_DEV: "neighboursForDev",
  GET_REPORT_FOR_ADMIN: "neighboursForAdmin",
  DELETE_PROJECT: "deleteProject",
  CREATE_PROJECT: "create_new_project",
  UPDATE_PROJECT: "updateProject",
  CREATE_CARD: "createNewCard",
  UPDATE_CARD: "updateCard",
  GET_MY_CARD: "getAllCards",
  GET_DEFAULT_CARD: "defaultCards",
  GET_PROJECT_CARD: "getProjectCards/",
  GET_DASHBOARD: "dashboard",
  GET_DASHBOARD_PROJECT: "projectsForDashboard",
  GET_DASHBOARD_USER: "usersForDashboard",
  GET_DASHBOARD_REQUEST: "requestForDashboard",
  DELETE_CARD: "deleteProjectCard/",
  GET_USER_PROFILE: "getUserProfile",
  GET_REQUEST_LIST: "requests",
  EDIT_USER_PROFILE: "editUserProfile",
  UPDATE_THEME: "updateTheme",
  GET_STATES: "getStates",
  GET_CITIES: "getCities",
  UPDATE_NOTIFICATION: "updateNotification",
  UPDATE_PASSWORD: "updateMyPassword",
  DELETE_ACCOUNT: "deleteAccount",
  APPROVE_REQUEST: "approveRequest",
  REJECT_REQUEST: "rejectRequest",
  GET_USERS: "getUsers",
  GET_USER_DETAIL: "getUserDetail",
  GET_PROJECT_WITH_COMMENTS: "getProjectWithComments",
  GET_CARD_WITH_COMMENTS: "getCardWithComments",
  GET_NOTIFICATION_LIST: "getNotificationList",
  SET_IS_VIEWED: "setIsViewed",
  SHOW_BE_HEARD_COMMENT: "showBeHeardComments",
  REPLY_BE_HEARD_COMMENT: "replyBeHeardComments",
  SUSPEND_USER: "suspendUser",
  UNSUSPEND_USER: "unSuspendUser",
  GET_ALL_REQUEST: "getAllRequests",
};
export { API_END_POINT, api, privateApi };
