import { createSlice } from "@reduxjs/toolkit";
import moment from "moment/moment";
import { getUserDetail, getUserList } from "../reducer/userManagement.reducer";
const initialState = {
  loading: false,
  userList: [],
  selectedUser: {},
  userDetail: {},
  otherDetail: [],
};

const userManagementSlice = createSlice({
  name: "request-list",
  initialState,
  reducers: {
    setSelectedUser: (state, { payload }) => {
      state.selectedUser = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserList.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getUserList.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.data?.details?.data) {
        state.userList = payload.data?.details?.data.map((item) => {
          return {
            ...item,
            name: `${item.firstName} ${item.surName}`,
            createdOn: moment(item?.createdOn).format("MMM DD, YYYY HH:mm"),
          };
        });
      } else {
        state.userList = [];
      }
    });

    builder.addCase(getUserDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserDetail.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getUserDetail.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (state.selectedUser?.userType === "Developer") {
        state.userDetail = payload?.data?.userDetail;
        state.otherDetail = payload?.data?.otherDetails;
      } else {
        state.userDetail = payload?.data?.userDetail;
        if (payload?.data?.otherDetails?.length > 0) {
          state.otherDetail = payload?.data?.otherDetails?.map((item) => {
            if (item?.cardsReviewedCount > 0) {
              const percent =
                ((item?.agreeCardIds?.length ?? 0) * 100) /
                (item?.cardsReviewedCount ?? 0);
              return {
                ...item,
                reviewStatus:
                  percent <= 40
                    ? "NIMBY"
                    : percent > 40 && percent < 60
                    ? "MIMBY"
                    : "YIMBY",
              };
            } else {
              return { ...item, reviewStatus: "No Response" };
            }
          });
        } else {
          state.otherDetail = [];
        }
      }
    });
  },
});
export const userManagementActions = userManagementSlice.actions;
export default userManagementSlice.reducer;
