import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_END_POINT, privateApi } from "../../../api/apiConfig";
import { toaster } from "../../../utils/toast";

export const getReportList = createAsyncThunk(
  "get-report-list",
  async ({ params }) => {
    try {
      const response = await privateApi.get(API_END_POINT.GET_REPORT_LIST, {
        params,
      });

      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getReportForDev = createAsyncThunk(
  "get-report-for-dev",
  async ({ params }) => {
    try {
      const response = await privateApi.get(API_END_POINT.GET_REPORT_FOR_DEV, {
        params,
      });

      return response;
    } catch (err) {
      return err;
    }
  }
);

export const getReportForAdmin = createAsyncThunk(
  "get-report-for-admin",
  async ({ params }) => {
    try {
      const response = await privateApi.get(
        API_END_POINT.GET_REPORT_FOR_ADMIN,
        {
          params,
        }
      );

      return response;
    } catch (err) {
      return err;
    }
  }
);
