import { Button, Row } from "antd";
import "./styles.scss";
import { IconList } from "../../../constants/imageList";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  approveRequest,
  getRequestList,
  rejectRequest,
} from "../../../appConfig/redux/reducer/requestList.reducer";
import ConfirmationPopup from "../ConfirmationPopup";
import { requestListActions } from "../../../appConfig/redux/slice/requestList.slice";
const Heading = () => {
  const { selectedRequest, isAllRequest } = useSelector(
    (state) => state.requestList
  );
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState("");
  const onApprove = () => {
    if (selectedRequest?.length > 0) {
      selectedRequest.forEach((element) => {
        dispatch(
          approveRequest({
            value: { projectId: element.projectId },
            callback: () => {
              setIsOpen(false);
              dispatch(getRequestList());
            },
          })
        );
      });
    }
  };
  const onReject = () => {
    if (selectedRequest?.length > 0) {
      selectedRequest.forEach((element) => {
        dispatch(
          rejectRequest({
            value: { projectId: element.projectId },
            callback: () => {
              setIsOpen(false);
              dispatch(getRequestList());
            },
          })
        );
      });
    }
  };
  return (
    <Row justify={"space-between"} className="request-heading" align={"middle"}>
      <h3> Request List</h3>
      <Row>
        {/* <Button
          type={"primary"}
          className={"reject"}
          onClick={() => {
            setType("reject");
            setIsOpen(true);
          }}
        >
          <Row>
            <img src={IconList.thumbsDown} />
            <p>Reject</p>
          </Row>
        </Button>
        <Button
          type={"primary"}
          className={"approve"}
          onClick={() => {
            setType("approve");
            setIsOpen(true);
          }}
        >
          <Row>
            <img src={IconList.thumbsUp} />
            <p className="white">Approve</p>
          </Row>
        </Button> */}
        {/* <i /> */}
        {/* <Button className="request">
          <Row>
            <p className="white">All request</p>
            <img src={IconList.chevronDown} />
          </Row>
        </Button> */}
        {/* <Button>
          <Row>
            <img src={IconList.filter1} />
            <img src={IconList.chevronDown} />
          </Row>
        </Button>
        <Button>
          <Row>
            <img src={IconList.filter2} />
          </Row>
        </Button> */}
        <Button
          onClick={() => {
            dispatch(requestListActions.setIsAllRequest(!isAllRequest));
          }}
        >
          {isAllRequest ? "Pending Requests" : "History"}
        </Button>
      </Row>
      <ConfirmationPopup
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        type={type}
        onClick={type === "approve" ? onApprove : onReject}
      />
    </Row>
  );
};
export default Heading;
