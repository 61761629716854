import { Checkbox, Col, Row, Switch } from "antd";
import "./styles.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateNotification } from "../../../appConfig/redux/reducer/setting.reducer";
import { settingActions } from "../../../appConfig/redux/slice/setting.slice";
const NotificationSetting = () => {
  const [isDeveloper, setIsDeveloper] = useState(false);
  const { userData } = useSelector((state) => state.auth);
  const {
    isDesktopNotification,
    isEmailNotification,
    isNewDeveloperAdded,
    isProjectUpdated,
  } = useSelector((state) => state.setting);
  useEffect(() => {
    if (userData.userType === "re_developer") {
      setIsDeveloper(true);
    } else {
      setIsDeveloper(false);
    }
  }, []);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateNotification({
        values: {
          isDesktopNotification,
          isEmailNotification,
          isNewDeveloperAdded,
          isProjectUpdated,
        },
        callback: () => {},
      })
    );
  }, [
    isDesktopNotification,
    isEmailNotification,
    isNewDeveloperAdded,
    isProjectUpdated,
  ]);
  const notifyList = isDeveloper
    ? [
        {
          title: "Project status update received",
          status: isProjectUpdated,
          onChange: (value) => {
            dispatch(settingActions.setIsProjectUpdated(value));
          },
        },
      ]
    : [
        {
          title: "New project request received",
          status: isProjectUpdated,
          onChange: (value) => {
            dispatch(settingActions.setIsProjectUpdated(value));
          },
        },
        {
          title: "New Developer added",
          status: isNewDeveloperAdded,
          onChange: (value) => {
            dispatch(settingActions.setIsNewDeveloperAdded(value));
          },
        },
      ];
  const notificationList = [
    {
      title: "Desktop notification",
      description:
        "Receive notification whenever your organization required your attentions",
      isSelected: isDesktopNotification,
      onChange: (value) => {
        dispatch(settingActions.setIsDesktopNotification(value));
      },
    },
    {
      title: "Email notification",
      description:
        "Receive notification whenever your organization required your attentions",
      isSelected: isEmailNotification,
      onChange: (value) => {
        dispatch(settingActions.setIsEmailNotification(value));
      },
    },
  ];
  return (
    <div className="notification-setting-container">
      <h2>Notifications</h2>
      <p className="grey">
        Manage, which communications you’d like to receive and how?
      </p>
      <h4>Notify me when</h4>
      {notifyList.map((item, key) => (
        <Row key={key}>
          <Checkbox
            checked={item.status}
            onChange={(value) => {
              item.onChange(value.target.checked);
            }}
          />
          <p className="notify">{item.title}</p>
        </Row>
      ))}
      {notificationList.map((item, key) => (
        <Row align={"middle"} key={key}>
          <Col md={19} className="notification-item">
            <p>{item.title}</p>
            <span className="grey">{item.description}</span>
          </Col>
          <Col md={2} />
          <Col>
            <Switch
              checked={item.isSelected}
              onChange={(value) => {
                item.onChange(value);
              }}
            />
          </Col>
        </Row>
      ))}
    </div>
  );
};
export default NotificationSetting;
