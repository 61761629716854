import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { authActions } from "../redux/slice/auth.slice";
import { useEffect } from "react";
// import { appState } from '../store';

const ProtectedRoute = ({ children }) => {
  const auth = useSelector((state) => state.auth.userData);
  const local = JSON.parse(localStorage.getItem("userData") ?? "{}");
  const dispatch = useDispatch();
  if (local && local.token && !auth.token) {
    dispatch(authActions.setUserData(local));
    return children;
  } else if (auth && auth.token) {
    return children;
  } else {
    return <Navigate to="/" replace />;
  }
};

export default ProtectedRoute;
