import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";
import { useEffect } from "react";
import {
  getNotification,
  setIsViewed,
} from "../../appConfig/redux/reducer/notification.reducer";
import { Row } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
const Notifications = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const { notification } = useSelector((state) => state.notification);
  useEffect(() => {
    dispatch(getNotification());
  }, []);
  return (
    <>
      {isOpen && (
        <div className="notification-container">
          <Row justify={"space-between"} align={"middle"} className="heading">
            <h3>Notifications</h3>
            <a
              onClick={() => {
                dispatch(
                  setIsViewed({
                    value: { viewAll: true },
                    callback: () => {
                      dispatch(getNotification());
                    },
                  })
                );
              }}
            >
              Mark all as read
            </a>
            <CloseOutlined onClick={onClose} />
          </Row>
          <div className="height">
            {notification?.length > 0 &&
              notification.map((item, index) => (
                <div
                  key={index}
                  className="notification"
                  onClick={() => {
                    dispatch(
                      setIsViewed({
                        value: { Id: item.id },
                        callback: () => {
                          dispatch(getNotification());
                        },
                      })
                    );
                  }}
                >
                  {!item.isViewed ? (
                    <div className="new-notification">New</div>
                  ) : (
                    <></>
                  )}
                  <p>{JSON.parse(item?.message ?? "{}")?.notification?.body}</p>
                  <p className="date">
                    {moment(new Date(item?.createdOn).toLocaleString()).format(
                      "DD MMM'YY hh:mm a"
                    )}
                  </p>
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};
export default Notifications;
