import React, { useState } from "react";
import "./styles.scss";
import { Button, Row, Switch } from "antd";
import { IconList } from "../../../constants/imageList";
import { useDispatch, useSelector } from "react-redux";
import { cardActions } from "../../../appConfig/redux/slice/card.slice";
import Filter from "../Filter";
import CreateCard from "../../Common/CreateCard";
import {
  deleteCards,
  getMyCards,
} from "../../../appConfig/redux/reducer/card.reducer";
import DeletePopup from "../../Common/DeletePopup";

const Heading = () => {
  const { isListView, isDefaultCard, selectedCards } = useSelector(
    (state) => state.card
  );
  const { userData } = useSelector((state) => state.auth);
  const [isCreate, setIsCreate] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const onClick = () => {
    selectedCards.forEach((element) => {
      dispatch(
        deleteCards({
          values: { cardId: element.id },
          callback: () => {
            setIsDelete(false);
            dispatch(getMyCards({ params: {} }));
          },
        })
      );
    });
  };
  const dispatch = useDispatch();
  const deleteCard = () => {
    if (selectedCards.length > 0) {
      setIsDelete(true);
    }
  };
  return (
    <div className="heading-container">
      <h3>Cards List</h3>
      <Row className="pending">
        <p>Created Cards</p>
        <Switch
          checked={isDefaultCard}
          onChange={(checked) => {
            dispatch(cardActions.setIsDefaultCard(checked));

            console.log(checked);
          }}
        />
        <p>Default Cards</p>
      </Row>

      <div className="options-container">
        {userData.userType === "re_developer" && (
          <>
            {!isDefaultCard && (
              <>
                <Button
                  onClick={deleteCard}
                  disabled={selectedCards.length === 0}
                >
                  <span>
                    <img src={IconList.deleteIcon} />
                    <p>Delete</p>
                  </span>
                </Button>
                <Button
                  type="primary"
                  className="primary"
                  onClick={() => {
                    setIsCreate(true);
                  }}
                >
                  <span>
                    <img src={IconList.create} />
                    <p className="white">Create</p>
                  </span>
                </Button>
              </>
            )}
          </>
        )}

        <i />
        <Button
          type={isListView ? "primary" : "default"}
          className={isListView ? "primary" : ""}
          onClick={() => {
            dispatch(cardActions.setIsListView(true));
          }}
        >
          <span className="default">
            <img
              src={isListView ? IconList.listViewSelected : IconList.listView}
            />
          </span>
        </Button>
        <Button
          type={!isListView ? "primary" : "default"}
          className={!isListView ? "primary" : ""}
          onClick={() => {
            dispatch(cardActions.setIsListView(false));
          }}
        >
          <span className="default">
            <img
              src={!isListView ? IconList.gridViewSelected : IconList.gridView}
            />
          </span>
        </Button>
      </div>
      <CreateCard isCreate={isCreate} setIsCreate={setIsCreate} />
      <DeletePopup
        open={isDelete}
        onCancel={() => setIsDelete(false)}
        onClick={onClick}
      />
    </div>
  );
};

export default Heading;
