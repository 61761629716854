import { Form, Input } from "antd";
import "./styles.scss";
import { passwordPattern } from "../../../utils/formValidations";
import { useDispatch } from "react-redux";
import { updatePassword } from "../../../appConfig/redux/reducer/setting.reducer";
import { toaster } from "../../../utils/toast";

const Password = ({ form }) => {
  const dispatch = useDispatch();
  const onFinish = (values) => {
    dispatch(
      updatePassword({
        values,
        callback: () => {
          toaster("success", "Password changed successfully");
          form.resetFields();
        },
      })
    );
  };
  return (
    <div className="password-container">
      <h2>Change password</h2>
      <Form form={form} name="change-password" onFinish={onFinish}>
        <p>Current pasword</p>
        <Form.Item
          name="currentPassword"
          rules={[
            {
              required: true,
              pattern: passwordPattern.value,
              message: passwordPattern.message,
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <p>New pasword</p>
        <Form.Item
          name="newPassword"
          rules={[
            {
              required: true,
              pattern: passwordPattern.value,
              message: passwordPattern.message,
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <p>Confirm pasword</p>
        <Form.Item
          name="confirmPassword"
          rules={[
            {
              required: true,
              pattern: passwordPattern.value,
              message: passwordPattern.message,
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
      <h4>Password requirements:</h4>
      <p className="grey margin">Ensure that these requirements are met.</p>
      <p className="requirement">Minimum 8 characters</p>
      <p className="requirement">At least one lowercase characters</p>
      <p className="requirement">At least one uppercase characters</p>
      <p className="requirement">
        At least one number and special symbol characters
      </p>
    </div>
  );
};
export default Password;
