import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../slice/auth.slice";
import projectReducer from "../slice/project.slice";
import cardReducer from "../slice/card.slice";
import settingReducer from "../slice/setting.slice";
import requestListReducer from "../slice/requestList.slice";
import userManagementReducer from "../slice/userManagement.slice";
import reportReducer from "../slice/report.slice";
import dashboardReducer from "../slice/dashboard.slice";
import notificationReducer from "../slice/notification.slice";

export default configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["your/action/type"],
        // Ignore these field paths in all actions
        ignoredActionPaths: ["meta.arg", "payload.timestamp"],
        // Ignore these paths in the state
        ignoredPaths: ["items.dates"],
      },
    }),
  reducer: {
    auth: authReducer,
    project: projectReducer,
    card: cardReducer,
    setting: settingReducer,
    requestList: requestListReducer,
    userManagement: userManagementReducer,
    report: reportReducer,
    dashboard: dashboardReducer,
    notification: notificationReducer,
  },
});
