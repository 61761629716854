import CryptoJS from "crypto-js";

export const encryptRequest = (req) => {
  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(req),
    process.env.REACT_APP_CRYPTOSECRET_KEY
  ).toString();
  return {
    bodyRequest: encryptedData,
  };
};

export const encryptGetRequest = (req) => {
  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(req),
    process.env.REACT_APP_CRYPTOSECRET_KEY
  ).toString();
  return {
    queryRequest: encryptedData,
  };
};

export const decryptResponse = (res) => {
  const decryptedData = CryptoJS.AES.decrypt(
    res,
    process.env.REACT_APP_CRYPTOSECRET_KEY
  ).toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedData);
};
