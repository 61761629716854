import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";
import { Button, Row, Col, Form, Modal, Input, Switch } from "antd";
import { projectActions } from "../../../appConfig/redux/slice/project.slice";
import { createProject } from "../../../appConfig/redux/reducer/project.reducer";
import { useNavigate } from "react-router-dom";
import { SCREEN_PATHS } from "../../../constants";
import { useEffect, useState } from "react";
import {
  createCard,
  getDefaultCards,
  getMyCards,
} from "../../../appConfig/redux/reducer/card.reducer";
import CreateCard from "../../Common/CreateCard";
import { NEW_PROJECT_STEPS } from "../../../constants/enum";
import { toaster } from "../../../utils/toast";
const Page3 = ({ form }) => {
  const [isCreate, setIsCreate] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getMyCards({ params: {} }));
    dispatch(getDefaultCards({ params: {} }));
  }, []);
  const { step1Data, step2Data, step3Data, step } = useSelector(
    (state) => state.project
  );
  const { cardData, defaultCards } = useSelector((state) => state.card);
  useEffect(() => {
    setAll(cardData);
    setAllDefault(defaultCards);
  }, [cardData, defaultCards]);
  const onFinish = (values) => {
    dispatch(projectActions.setStep3Data(values));
    dispatch(
      createProject({
        values: {
          status: "active",
          projectDetails: step1Data,
          projectImages: step2Data ?? [],
          cardDetails: selected ?? [],
          defaultCardDetails: selectedDefault ?? [],
        },
        callback: () => {
          toaster("success", "Project submitted for review");
          navigate(SCREEN_PATHS.project);
        },
      })
    );
  };

  const onFinishFailed = (values) => {};
  const [allDefault, setAllDefault] = useState([]);
  const [selectedDefault, setSelectedDefault] = useState([]);

  const [selected, setSelected] = useState([]);
  const [all, setAll] = useState([]);

  return (
    <div className="page-3-container">
      {(selected.length > 0 || selectedDefault.length > 0) && (
        <h4>Selected survey card</h4>
      )}
      <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Row wrap="wrap">
          {selectedDefault?.map((card, index) => (
            <Col md={6} key={index}>
              <div className="card">
                <p>{card.createdOn}</p>
                <h3>{card.cardTitle}</h3>
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    setAllDefault([...allDefault, card]);
                    setSelectedDefault([
                      ...selectedDefault.filter(
                        (item, intIndex) => index != intIndex
                      ),
                    ]);
                  }}
                >
                  Remove
                </Button>
              </div>
            </Col>
          ))}
          {selected?.map((card, index) => (
            <Col md={6} key={index}>
              <div className="card">
                <p>{card.createdOn}</p>
                <h3>{card.cardTitle}</h3>
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    setAll([...all, card]);
                    setSelected([
                      ...selected.filter((item, intIndex) => index != intIndex),
                    ]);
                  }}
                >
                  Remove
                </Button>
              </div>
            </Col>
          ))}
        </Row>
        {all.length > 0 && (
          <Row justify={"space-between"}>
            <h4>All survey card</h4>
            {/* <Button
              type="primary"
              className="primary"
              onClick={() => {
                setIsCreate(true);
              }}
            >
              Create
            </Button> */}
          </Row>
        )}
        <Row wrap="wrap">
          {all?.map((card, index) => (
            <Col md={6} key={index}>
              <div className="card">
                <p>{card.createdOn}</p>
                <h3>{card.cardTitle}</h3>
                <Button
                  type="primary"
                  className="primary"
                  onClick={() => {
                    setSelected([...selected, card]);
                    setAll([...all.filter((item, ind) => ind !== index)]);
                  }}
                >
                  Add card
                </Button>
              </div>
            </Col>
          ))}
        </Row>

        {allDefault.length > 0 && (
          <Row justify={"space-between"}>
            <h4>All default card</h4>
            <Button
              type="primary"
              className="primary"
              onClick={() => {
                setIsCreate(true);
              }}
            >
              Create
            </Button>
          </Row>
        )}
        <Row wrap="wrap">
          {allDefault?.map((card, index) => (
            <Col md={6} key={index}>
              <div className="card">
                <p>{card.createdOn}</p>
                <h3>{card.cardTitle}</h3>
                <Button
                  type="primary"
                  className="primary"
                  onClick={() => {
                    setSelectedDefault([...selectedDefault, card]);
                    setAllDefault([
                      ...allDefault.filter((item, ind) => ind !== index),
                    ]);
                  }}
                >
                  Add card
                </Button>
              </div>
            </Col>
          ))}
        </Row>
        <Row>
          <Button
            onClick={() => {
              dispatch(
                projectActions.setNewProjectStep(NEW_PROJECT_STEPS.step2)
              );
            }}
          >
            Back
          </Button>

          <Form.Item>
            <Button
              type={"primary"}
              className={" margin"}
              htmlType="submit"
              disabled={selected.length === 0 && selectedDefault.length === 0}
            >
              {step === NEW_PROJECT_STEPS.step3 ? "Save details" : "Continue"}
            </Button>
          </Form.Item>
        </Row>
      </Form>
      <CreateCard setIsCreate={setIsCreate} isCreate={isCreate} />
    </div>
  );
};
export default Page3;
