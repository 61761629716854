import { Button, Col, Form, Input, Row, Select, Spin, Upload } from "antd";
import "./styles.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editUserProfile,
  getCities,
  getState,
  getUserProfile,
} from "../../../appConfig/redux/reducer/setting.reducer";
import { toaster } from "../../../utils/toast";
import { ImageList } from "../../../constants/imageList";
import { namePattern } from "../../../utils/formValidations";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import { settingActions } from "../../../appConfig/redux/slice/setting.slice";

const Profile = ({ form }) => {
  const libraries = ["places", "maps", "marker"];
  const [image, setImage] = useState("");
  const dispatch = useDispatch();
  const { userProfile, loading } = useSelector((state) => state.setting);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_LOCATION_SERVICE,
    libraries,
    region: "US",
  });
  const [map, setMap] = useState();
  const [address, setAddress] = useState("");
  const [searchResult, setSearchResult] = useState();
  const onLoad = (autocomplete) => {
    setSearchResult(autocomplete);
  };
  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place = searchResult.getPlace();

      const formattedAddress = place.formatted_address;
      const location = JSON.parse(JSON.stringify(place.geometry.location));
      setMap(location);
      setAddress(formattedAddress);
      dispatch(settingActions.setAddress(formattedAddress));
      dispatch(settingActions.setLocation(location));
    }
  };

  useEffect(() => {
    dispatch(getState());
    setImage(userProfile?.profilePhoto);
    dispatch(getUserProfile());
  }, []);
  useEffect(() => {
    setAddress(userProfile?.address);
  }, [userProfile]);
  const onFinishFailed = (values) => {};
  const onFinish = (value) => {
    dispatch(
      editUserProfile({
        values: { ...value, address, profilePhoto: image },
        callback: () => {
          toaster("success", "Profile Updated Successfully");
          dispatch(getUserProfile());
        },
      })
    );
  };
  if (!isLoaded) {
    return <></>;
  }
  return (
    <div className="profile-container">
      <h2>Profile</h2>
      <p className="grey">Upload your photo and personal details here.</p>
      {loading === true ? (
        <Spin />
      ) : (
        <>
          <Row align={"middle"}>
            <img
              src={
                !image || image === "" || image.includes("undefined")
                  ? ImageList.defaultUser
                  : image
              }
            />
            <Upload
              accept=".jpg,.jpeg,.png,.svg,.mp4,.avi"
              action={(value) => {
                if (value.size < 5000000) {
                  let reader = new FileReader();
                  reader.readAsDataURL(value);
                  reader.onload = () => {
                    setImage(reader.result);
                  };
                } else {
                  toaster("error", "File too large");
                }
              }}
              showUploadList={false}
            >
              <div className="upload">Upload photo</div>
            </Upload>
            <Button
              danger
              disabled={!image}
              onClick={() => {
                setImage("");
              }}
            >
              Remove Photo
            </Button>
          </Row>
          <Form
            name="profile"
            form={form}
            key={userProfile}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="form-container"
          >
            <Row>
              <Col md={11}>
                <p>First name</p>
                <Form.Item
                  name="firstName"
                  key={userProfile}
                  rules={[
                    {
                      required: true,
                      pattern: namePattern.value,
                      message: namePattern.message,
                    },
                  ]}
                  initialValue={userProfile?.firstName}
                >
                  <Input placeholder="Enter your first name here" />
                </Form.Item>
              </Col>
              <Col md={1}></Col>
              <Col md={11}>
                <p>Last name</p>
                <Form.Item
                  name="surName"
                  rules={[
                    {
                      required: true,
                      pattern: namePattern.value,
                      message: namePattern.message,
                    },
                  ]}
                  initialValue={userProfile?.surName}
                >
                  <Input placeholder="Enter your last name here" />
                </Form.Item>
              </Col>
              <Col md={1}></Col>
            </Row>
            <Row>
              <Col md={23}>
                <p>Email address</p>
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: "Email is required" }]}
                  initialValue={userProfile?.email}
                >
                  <Input placeholder="Enter your email here" disabled={true} />
                </Form.Item>
              </Col>
              <Col md={1}></Col>
            </Row>
            <Row>
              <Col md={23}>
                <p>Address</p>
                <Form.Item
                  name="address"
                  initialValue={userProfile?.address ?? ""}
                >
                  <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                    <Input
                      placeholder="Enter project address here..."
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Autocomplete>
                </Form.Item>
              </Col>
              <Col md={1}></Col>
            </Row>
          </Form>
        </>
      )}
    </div>
  );
};
export default Profile;
